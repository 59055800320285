/* MileageTracker.css */

/* MODAL BACKDROP */
.mileage-modal-backdrop {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(4px);
  padding: 0.5rem; /* Reduced from 1rem to save vertical space */
}

/* MODAL WRAPPER */
.mileage-modal-wrapper {
  background: #ffffff;
  border-radius: 16px;
  width: 100%;
  max-width: 800px;
  position: relative;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  max-height: 90vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
}

/* MODAL HEADER */
.mileage-modal-header {
  padding: 1rem 1rem 0.5rem; /* Reduced from 1.5rem */
  border-bottom: 1px solid #e5e7eb;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f8fafc;
}

.mileage-modal-title {
  font-size: 1.3rem; /* Slightly smaller than 1.5rem */
  font-weight: 600;
  color: #1e293b;
  display: flex;
  align-items: center;
  gap: 0.5rem; /* Slightly less than 0.75rem */
}

.mileage-modal-title i {
  color: #0033a0;
}

.mileage-modal-close {
  background: transparent;
  border: none;
  color: #64748b;
  font-size: 1.25rem;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 9999px;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
}

.mileage-modal-close:hover {
  background: #f1f5f9;
  color: #0f172a;
}

/* MODAL CONTENT */
.mileage-modal-content {
  padding: 1rem; /* Reduced from 1.3rem */
  overflow-y: auto;
}

/* SETTINGS CARD */
.mileage-settings-card {
  background: #f8fafc;
  border: 1px solid #e2e8f0;
  border-radius: 12px;
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.mileage-settings-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.mileage-settings-title {
  font-weight: 600;
  color: #0f172a;
  font-size: 0.95rem;
}

.mileage-settings-edit {
  display: inline-flex;
  align-items: center;
  gap: 0.4rem;
  padding: 0.45rem 0.8rem;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 0.85rem;
  color: #0033a0;
  transition: all 0.2s ease;
  cursor: pointer;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.mileage-settings-edit:hover {
  background: #f8fafc;
  border-color: #0033a0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.mileage-settings-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.75rem; /* Reduced from 1rem */
}

@media (max-width: 640px) {
  .mileage-settings-grid {
    grid-template-columns: 1fr;
  }
}

.mileage-settings-item {
  background: white;
  padding: 0.7rem 1rem;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03);
}

.mileage-settings-label {
  font-size: 0.75rem;
  color: #64748b;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  margin-bottom: 0;
}

.mileage-settings-value {
  font-size: 1rem;
  color: #0f172a;
  font-weight: 500;
  margin-left: 0.5rem;
}

/* ERROR / ALERT */
.mileage-alert {
  background: #fee2e2;
  border: 1px solid #fecaca;
  color: #991b1b;
  padding: 0.75rem; /* Reduced from 1rem */
  border-radius: 8px;
  margin-bottom: 1rem;
  display: flex;
  align-items: flex-start;
  gap: 0.5rem; /* Reduced from 0.75rem */
}

.mileage-alert i {
  margin-top: 0.125rem;
}

.mileage-alert-link {
  display: block;
  margin-top: 0.25rem; /* Reduced from 0.5rem */
  color: #0033a0;
  text-decoration: underline;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
}

/* FORM */
.mileage-form {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  padding: 0.5rem;
}

.mileage-form-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.25rem;
}

@media (max-width: 640px) {
  .mileage-form-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .mileage-form {
    gap: 1rem;
  }
}

.mileage-form-group {
  display: flex;
  flex-direction: column;
  gap: 0.3rem; /* Reduced from 0.375rem */
}

.mileage-label {
  font-size: 0.85rem; /* Slightly smaller */
  font-weight: 500;
  color: #0f172a;
}

/* INPUT & TEXTAREA */
.mileage-input {
  padding: 0.6rem 0.8rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 0.85rem;
  transition: all 0.2s;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05) inset;
}

.mileage-input:focus {
  outline: none;
  border-color: #0033a0;
  box-shadow: 0 0 0 3px rgba(0, 51, 160, 0.1);
}

.mileage-distance-input {
  display: flex;
  gap: 0.5rem; /* Reduced from 0.75rem */
}

.mileage-distance-input .mileage-input {
  flex: 1;
}

.mileage-textarea {
  padding: 0.6rem 0.8rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  font-size: 0.85rem;
  transition: all 0.2s;
  resize: vertical;
  min-height: 40px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05) inset;
}

.mileage-textarea:focus {
  outline: none;
  border-color: #0033a0;
  box-shadow: 0 0 0 3px rgba(0, 51, 160, 0.1);
}

/* MAP LINK (IF USED) */
.mileage-map-link {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  color: #0033a0;
  font-size: 0.85rem;
  text-decoration: none;
  padding: 0.4rem 0.6rem;
  border-radius: 6px;
  transition: all 0.2s;
}

.mileage-map-link:hover {
  background: #f8fafc;
}

/* ACTIONS FOOTER */
.mileage-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  border-top: 1px solid #e5e7eb;
  padding-top: 1.25rem;
  margin-top: 0.75rem;
}

@media (max-width: 640px) {
  .mileage-actions {
    flex-direction: column-reverse;
    gap: 0.75rem;
    padding-top: 1rem;
  }

  .mileage-actions button {
    width: 100%;
  }
}

/* BUTTONS */
.mileage-button {
  border-radius: 8px;
  font-weight: 500;
  transition: all 0.2s ease;
  padding: 0.65rem 1.2rem;
  font-size: 0.9rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.mileage-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.mileage-button-secondary {
  background: white;
  border: 1px solid #e2e8f0;
  color: #64748b;
}

.mileage-button-secondary:hover:not(:disabled) {
  background: #f8fafc;
  border-color: #cbd5e1;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.mileage-button-primary {
  background: #0033a0;
  color: white;
  border: none;
}

.mileage-button-primary:hover:not(:disabled) {
  background: #002c91;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

/* LOADING ANIMATION */
.mileage-button-loading {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}

.mileage-button-loading i {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* ALERT CONTENT */
.mileage-alert-content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem; /* Reduced from 0.75rem */
}

.mileage-alert-link {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  color: #0033a0;
  background: white;
  border: 1px solid currentColor;
  padding: 0.4rem 0.75rem;
  border-radius: 4px;
  font-size: 0.85rem;
  cursor: pointer;
  transition: all 0.2s;
}

.mileage-alert-link:hover {
  background: #f8fafc;
  text-decoration: none;
}

/* CALCULATE DISTANCE CTA */
.calculate-cta {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
  padding: 0.5rem;
}

.calculate-cta-button {
  font-size: 0.95rem;
  font-weight: 600;
  width: 100%;
  max-width: 280px;
  padding: 0.8rem 1.2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.calculate-cta-button:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

/* OPTIONAL CTA TEXT */
.calculate-cta-text {
  margin-bottom: 0.4rem;
  text-align: center;
  color: #334155;
  font-size: 0.8rem;
}

.mileage-notes-container {
  position: relative;
  display: flex;
  align-items: flex-end;
}

.mileage-textarea {
  flex: 1;
  padding-right: 50px; /* Make room for the voice button */
}

.voice-note-button {
  position: absolute;
  right: 8px;
  bottom: 8px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: none;
  background-color: #f0f0f0;
  color: #555;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.voice-note-button:hover {
  background-color: #e0e0e0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.voice-note-button.recording {
  background-color: #ff4a4a;
  color: white;
  animation: pulse 1.5s infinite;
}

.voice-note-button.transcribing {
  background-color: #4a9cff;
  color: white;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 74, 74, 0.7);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(255, 74, 74, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 74, 74, 0);
  }
}
/* Add these styles to your MileageTracker.css file */

.mileage-rate-input {
  position: relative;
}

.mileage-currency-symbol {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #555;
}

.mileage-rate-input input {
  padding-left: 26px;
}
