/* Delete Confirmation Modal Styles */
.eb-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.eb-modal-content {
  background-color: white;
  border-radius: 8px;
  width: 90%;
  max-width: 550px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}

.eb-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  border-bottom: 1px solid #eaeaea;
}

.eb-modal-header h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.eb-modal-close {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
}

.eb-modal-body {
  padding: 20px;
}

.eb-modal-question {
  font-size: 16px;
  margin-bottom: 24px;
  color: #333;
}

.eb-modal-info {
  background-color: #f8f9fa;
  border-radius: 6px;
  padding: 16px;
}

.eb-info-header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  color: #555;
}

.eb-info-header i {
  margin-right: 10px;
  color: #0056b3;
}

.eb-option-cards {
  display: flex;
  gap: 16px;
  margin-top: 16px;
}

@media (max-width: 600px) {
  .eb-option-cards {
    flex-direction: column;
  }
}

.eb-option-card {
  flex: 1;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 16px;
  cursor: pointer;
  transition: all 0.2s ease;
  background-color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 160px; /* Ensure cards have equal minimum height */
}

.eb-option-card:hover {
  border-color: #aaa;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.eb-option-card h4 {
  margin-top: 0;
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 600;
  height: auto; /* Allow headings to take natural height */
}

.eb-option-card p {
  margin: 0 0 16px 0;
  font-size: 13px;
  color: #666;
  line-height: 1.4;
  flex-grow: 1; /* Allow paragraph to grow and push button to bottom */
}

.eb-button-container {
  margin-top: auto; /* Push buttons to bottom of the card */
  width: 100%;
}

.eb-modal-footer {
  display: flex;
  justify-content: flex-start;
  padding: 16px 20px;
  border-top: 1px solid #eaeaea;
}

.eb-modal-cancel {
  background-color: #f2f2f2;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  font-weight: 500;
  color: #333;
}

.eb-modal-cancel:hover {
  background-color: #e6e6e6;
}

.eb-modal-delete,
.eb-modal-delete-revert {
  width: 100%; /* Full width buttons */
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  font-weight: 500;
  height: 40px; /* Fixed height for both buttons */
  display: flex;
  align-items: center;
  justify-content: center;
}

.eb-modal-delete {
  background-color: #dc3545;
  color: white;
}

.eb-modal-delete:hover {
  background-color: #c82333;
}

.eb-modal-delete-revert {
  background-color: #0056b3;
  color: white;
}

.eb-modal-delete-revert:hover {
  background-color: #004494;
}

/* Additional responsive fixes for smaller screens */
@media (max-width: 480px) {
  .eb-modal-content {
    width: 95%;
    max-width: 95%;
    margin: 0 10px;
  }

  .eb-option-cards {
    flex-direction: column;
  }

  .eb-option-card {
    margin-bottom: 16px;
    min-height: auto; /* Allow natural height on mobile */
  }

  .eb-option-card h4 {
    font-size: 15px;
  }

  .eb-option-card p {
    font-size: 13px;
  }

  .eb-modal-delete,
  .eb-modal-delete-revert,
  .eb-modal-cancel {
    padding: 12px 16px;
    font-size: 14px;
  }

  .eb-modal-footer {
    padding: 16px;
  }
}

/* Fix word wrap and overflow */
.eb-option-card p {
  padding-right: 8px;
  word-break: break-word;
}

.eb-modal-question,
.eb-info-header span {
  word-break: break-word;
}
