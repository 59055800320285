.upload-progress-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  width: 300px;
  z-index: 1000;
}

.progress-label {
  font-size: 16px;
  margin-bottom: 10px;
  text-align: center;
}

.progress-bar-container {
  height: 10px;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 5px;
}

.progress-bar-fill {
  height: 100%;
  background-color: #4682b4;
  transition: width 0.3s ease;
}

.progress-percentage {
  text-align: right;
  font-size: 14px;
  color: #666;
}
