/* Form and Input Improvements */
.form-group {
  margin-bottom: 1.5rem;
}

.form-label {
  display: block;
  margin-bottom: 0.5rem;
  color: #333;
  font-weight: 500;
  position: relative;
}

/* Info Tooltip */
.info-tooltip1 {
  display: none;
  position: absolute;
  background: #f8f9fa;
  border: 1px solid #dee2e6;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 0.875rem;
  color: #666;
  width: 200px;
  top: 100%;
  left: 0;
  margin-top: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.form-label:hover .info-tooltip1 {
  display: block;
}

/* Input Fields */
input[type="email"],
input[type="text"],
input[type="number"],
.date-picker {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  font-size: 1rem;
  transition: border-color 0.2s;
}

input:focus {
  outline: none;
  border-color: #0033a0;
  box-shadow: 0 0 0 2px rgba(0, 51, 160, 0.1);
}

/* License Count Editing */
.license-edit {
  display: flex;
  gap: 8px;
  align-items: center;
}

.license-edit-input {
  width: 120px;
  padding: 4px 8px;
  border: 1px solid #dee2e6;
  border-radius: 4px;
}

.editable-field {
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.editable-field:hover {
  background: #f8f9fa;
}

.edit-icon {
  color: #6c757d;
  font-size: 0.875rem;
}

/* React DatePicker Customization */
.react-datepicker-wrapper {
  width: auto;
}

.date-picker {
  font-size: 0.875rem;
  padding: 4px 8px;
  width: 110px;
}

/* Buttons */
.submit-button,
.save-button,
.action-button1 {
  background-color: #0033a0;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s;
}

.submit-button:hover,
.save-button:hover,
.action-button1:hover {
  background-color: #002380;
}

.submit-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.danger-button {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s;
}

.danger-button:hover {
  background-color: #c82333;
}

/* Action Buttons Container */
.action-buttons1 {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

/* Table Improvements */
.table-responsive {
  overflow-x: auto;
  margin: 1rem 0;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.enterprise-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background: white;
}

.enterprise-table th {
  background: #f8f9fa;
  padding: 1rem;
  font-weight: 600;
  text-align: left;
  color: #495057;
  border-bottom: 2px solid #dee2e6;
}

.enterprise-table td {
  padding: 1rem;
  border-bottom: 1px solid #dee2e6;
  vertical-align: middle;
}

/* Modal for Assigned Users */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  max-width: 600px;
  width: 90%;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
}

.modal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #6c757d;
}

.modal-close:hover {
  color: #343a40;
}

/* Assigned Users List */
.assigned-users-list {
  margin: 1rem 0;
  max-height: 400px;
  overflow-y: auto;
}

.assigned-user-item {
  display: flex;
  justify-content: space-between;
  padding: 0.75rem;
  border-bottom: 1px solid #dee2e6;
}

.assigned-user-item:last-child {
  border-bottom: none;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .enterprise-table {
    font-size: 0.875rem;
  }

  .enterprise-table th,
  .enterprise-table td {
    padding: 0.75rem;
  }

  /* Stack form elements */
  .form-group {
    margin-bottom: 1rem;
  }

  /* Make buttons full width on mobile */
  .submit-button,
  .save-button,
  .action-button1,
  .danger-button {
    width: 100%;
    margin-bottom: 0.5rem;
  }

  /* Adjust table for small screens */
  .table-responsive {
    margin: 0.5rem -1rem;
    padding: 0 1rem;
  }

  /* Hide less important columns on mobile */
  .enterprise-table td:nth-child(4),
  .enterprise-table th:nth-child(4) {
    display: none;
  }
}

/* Loading State */
.loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

/* Error Message */
.error-message {
  color: #dc3545;
  background: #f8d7da;
  padding: 0.75rem;
  border-radius: 4px;
  margin-top: 1rem;
  font-size: 0.875rem;
}
