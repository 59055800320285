body {
  background: #f5f5f5;
  font-family: "Roboto", sans-serif;
  margin: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.firsttime-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
.header {
  text-align: center;
  margin-bottom: 20px;
}

.main-header {
  font-size: 32px;
  font-weight: 700;
  color: #0033a0;
  margin-bottom: 10px;
}

.instructions {
  font-size: 18px;
  color: #555;
  margin-bottom: 20px;
}

.form-container {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
}

.image-container_ft {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.receipt-demo-image {
  max-width: 100%;
  height: auto;
  max-height: 200px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form-section {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-group label {
  font-size: 16px;
  font-weight: 600;
  color: #0033a0;
}

.currency-select,
input[type="text"] {
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
}

.currency-select:focus,
input[type="text"]:focus {
  outline: none;
  border-color: #0033a0;
  box-shadow: 0 0 3px rgba(0, 51, 160, 0.2);
}

.call-to-action {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.start-button {
  background-color: #0033a0;
  color: white;
  padding: 16px;
  font-size: 18px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
}

.start-button:hover {
  background-color: #002c91;
}

.error-text {
  color: red;
  font-size: 14px;
  margin-top: -10px;
  text-align: center;
}

.footerfirst {
  margin-top: auto;
  padding: 20px 0;
  text-align: center;
  color: #888;
  font-size: 14px;
}

.footer-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.footer-logo {
  height: 40px;
}

@media (max-width: 768px) {
  .form-container {
    padding: 20px;
  }

  .main-header {
    font-size: 24px;
  }

  .start-button {
    font-size: 16px;
    padding: 12px;
  }

  .receipt-demo-image {
    max-height: 150px;
  }
}
