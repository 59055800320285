/* src/styles/GmailProcessor.css */
.gmail-processor-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.gmail-processor-content {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  width: 90%;
  max-width: 700px;
  height: 90vh;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 16px 24px 12px 24px;
  overflow: hidden;
}

.close-x {
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  color: #666;
}

.gmail-processor-header {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  gap: 12px;
  flex-shrink: 0;
}

.gmail-icon {
  color: #4285f4;
}

.gmail-processor-body {
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1;
  overflow: hidden;
}

/* Spinner animation */
.spin-icon {
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Email List Styling */
.email-selection {
  display: flex;
  flex-direction: column;
  flex: 1; /* take available space */
  overflow: hidden;
}

.email-list-header {
  margin-bottom: 4px;
  flex-shrink: 0;
  padding: 0;
}

.email-list-header h3 {
  margin-bottom: 4px;
  font-size: 18px;
  color: #333;
}

.email-count {
  color: #666;
  font-size: 14px;
}

.email-list1 {
  flex: 1 1 auto; /* let it expand */
  overflow-y: auto;
  border: 1px solid #eee;
  border-radius: 8px;
  margin-bottom: 12px;
  /* Remove min-height if set */
}

.email-item {
  display: flex;
  padding: 14px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  transition: background-color 0.2s;
  position: relative;
}

.email-item:last-child {
  border-bottom: none;
}

.email-item:hover {
  background-color: #f9f9f9;
}

.email-item.selected {
  background-color: #e8f0fe;
}

.email-checkbox {
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 4px;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4285f4;
  flex-shrink: 0;
}

.email-item.selected .email-checkbox {
  border-color: #4285f4;
  background-color: #4285f4;
  color: white;
}

.email-details {
  flex: 1;
  min-width: 0;
  position: relative;
}

.email-sender {
  font-weight: 500;
  margin-bottom: 3px;
  color: #333;
}

.email-subject {
  color: #555;
  margin-bottom: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.email-date {
  font-size: 12px;
  color: #777;
}

.loading-emails {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
  color: #666;
}

.loading-emails p {
  margin-top: 12px;
}

.no-emails {
  display: flex;
  padding: 32px 16px;
  align-items: center;
  color: #666;
}

.refresh-button {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #f2f2f2;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 13px;
  cursor: pointer;
  color: #555;
}

.refresh-button:hover {
  background-color: #e6e6e6;
}

.action-buttons1 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: auto;
  padding-top: 12px;
  flex-shrink: 0;
  position: sticky;
  bottom: 0;
  background-color: white;
}

.back-button,
.process-button {
  flex-basis: 48%;
  padding: 10px;
  border-radius: 8px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.back-button {
  background-color: #f2f2f2;
  color: #333;
  border: none;
  font-weight: 500;
  cursor: pointer;
}

.process-button {
  background-color: #4285f4;
  color: white;
  border: none;
  font-weight: 500;
  cursor: pointer;
}

.back-button:hover {
  background-color: #e6e6e6;
}

.process-button:hover {
  background-color: #3b78e7;
}

.process-button:disabled,
.back-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.upload-progress-container {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.processing-status {
  font-size: 13px;
  color: #666;
  text-align: center;
  margin-top: 4px;
}

/* Tag selection styling */
.email-tag-container {
  position: relative;
  margin-top: 4px;
}

.email-tag-button {
  display: flex;
  align-items: center;
  gap: 6px;
  background-color: #f2f2f2;
  border: none;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 12px;
  color: #555;
  cursor: pointer;
}

.email-tag-button:hover {
  background-color: #e6e6e6;
}

.email-tag-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 200px;
  max-height: 200px;
  overflow-y: auto;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 100;
  margin-top: 4px;
}

.tag-option {
  padding: 8px 12px;
  font-size: 13px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.tag-option:hover {
  background-color: #f5f5f5;
}

.tag-option.selected {
  background-color: #e8f0fe;
  color: #1a73e8;
}

.tag-option.no-tag {
  border-bottom: 1px solid #eee;
  color: #888;
}

.tag-loading,
.no-tags-available {
  padding: 12px;
  font-size: 13px;
  color: #888;
  text-align: center;
}

/* Redirecting to auth styling */
.redirecting-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px 16px;
  gap: 24px;
}

.redirecting-container p {
  color: #555;
  font-size: 16px;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .gmail-processor-content {
    width: 95%;
    padding: 16px 16px 12px 16px;
    height: 95vh;
  }

  .gmail-processor-header h2 {
    font-size: 18px;
  }
}

/* Mobile-specific adjustments */
@media (max-width: 480px) {
  .email-item {
    padding: 12px 12px 34px 12px;
    position: relative;
  }

  .email-checkbox {
    width: 24px;
    height: 24px;
  }

  .email-tag-button {
    justify-content: flex-start;
    font-size: 11px;
    padding: 3px 6px;
  }

  .email-tag-dropdown {
    width: 180px;
    z-index: 200;
  }

  .tag-option {
    padding: 10px;
    font-size: 12px;
  }

  .email-sender,
  .email-subject,
  .email-date {
    margin-bottom: 2px;
  }

  .email-sender {
    font-size: 14px;
  }

  .email-subject {
    font-size: 13px;
  }

  .email-date {
    font-size: 11px;
  }
}

/* Add these styles to your existing GmailProcessor.css file */

/* Tag creation UI */
.create-tag-container {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.new-tag-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 13px;
}

.tag-actions {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.tag-cancel-btn,
.tag-save-btn {
  flex: 1;
  padding: 6px 0;
  border: none;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
}

.tag-cancel-btn {
  background-color: #f0f0f0;
  color: #333;
}

.tag-save-btn {
  background-color: #4285f4;
  color: white;
}

.tag-error {
  color: #d32f2f;
  font-size: 12px;
  margin-top: -5px;
}

.tag-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eee;
  font-size: 13px;
  color: #666;
}

.create-new-tag-btn {
  background: none;
  border: none;
  color: #4285f4;
  font-size: 12px;
  cursor: pointer;
  padding: 0;
}

.create-first-tag-btn {
  background-color: #4285f4;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 12px;
  margin-top: 8px;
  cursor: pointer;
  width: 100%;
}

/* Mobile adjustments */
@media (max-width: 480px) {
  .tag-header {
    padding: 8px;
    font-size: 12px;
  }

  .create-tag-container {
    padding: 8px;
    gap: 8px;
  }

  .new-tag-input,
  .tag-cancel-btn,
  .tag-save-btn {
    font-size: 11px;
    padding: 5px;
  }
}
/* Add these styles to your existing CSS */

/* Adjust the save button to accommodate loading spinner */
.tag-save-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
}

/* Add a small delay to the loading spinner animation */
.tag-loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tag-loading-spinner .spin-icon {
  animation: spin 1.5s linear infinite;
}

/* Style for disabled buttons */
.tag-cancel-btn:disabled,
.tag-save-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Keep the button size stable when switching from text to spinner */
.tag-save-btn .spin-icon {
  margin: 0 2px;
}

.instructions {
  line-height: 1.6;
  font-size: 15px;
  color: #555;
  margin-bottom: 20px;
}

.gmail-steps {
  margin: 12px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.icon-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #f1f1f1;
  margin: 0 4px;
}

.label-expensebot {
  color: #42d692;
  font-weight: 600;
  padding: 2px 6px;
  background-color: rgba(66, 214, 146, 0.1);
  border-radius: 4px;
  margin: 0 2px;
}

.label-processed {
  color: #8e63ce;
  font-weight: 600;
  padding: 2px 6px;
  background-color: rgba(142, 99, 206, 0.1);
  border-radius: 4px;
  margin: 0 2px;
}

.processed-info {
  margin-top: 12px;
}

.refresh-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin: 12px auto 0;
  padding: 10px 16px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: #555;
  font-weight: 500;
  transition: all 0.2s;
  cursor: pointer;
}

.refresh-button:hover {
  background-color: #e5e5e5;
}
/* ======================= */
/* REPLACE THESE STYLES FOR
   THE "NO EMAILS FOUND" SECTION */
/* ======================= */

/* Container for the 'no emails found' message */
.no-emails-container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 20px;
}

/* Main content box for instructions */
.no-emails-content {
  /* Switch to left alignment for better readability on mobile */
  text-align: left;
  max-width: 420px; /* Slightly larger than 400 if you want */
  background-color: #f9f9f9; /* Light gray background */
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Title styling */
.no-emails-title {
  font-size: 20px; /* or 22px if you want it bigger */
  color: #333;
  margin-bottom: 16px;
  text-align: center; /* Keep the main heading centered */
  font-weight: 600;
}

/* Intro text paragraph (optional class if you add .intro-text in your JSX) */
.intro-text {
  font-size: 14px;
  line-height: 1.5;
  color: #555;
  margin-bottom: 12px;
}

/* Subheading for labeling instructions (optional if you rename .how-to-label-title) */
.label-instructions-heading {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
  color: #333;
}

/* Bullet list for labeling steps */
.label-instructions-list {
  list-style: disc outside; /* Bullets outside for a standard look */
  margin: 0 0 16px 20px; /* Indent from the left */
  font-size: 14px;
  line-height: 1.4;
  color: #555;
}

.label-instructions-list li {
  margin-bottom: 6px;
}

/* Short note about Processed label */
.processed-note {
  font-size: 14px;
  color: #555;
  margin-bottom: 16px;
  line-height: 1.4;
}

/* Help link paragraph */
.help-link {
  font-size: 14px;
  color: #555;
  margin-bottom: 16px;
}

/* The "Refresh List" button (can remain the same if you already have it) */
.refresh-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin: 12px auto 0;
  padding: 10px 16px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  color: #555;
  font-weight: 500;
  transition: all 0.2s;
  cursor: pointer;
}

.refresh-button:hover {
  background-color: #e5e5e5;
}

/* Responsive tweak for mobile */
@media (max-width: 480px) {
  .no-emails-content {
    padding: 16px;
  }

  .no-emails-title {
    font-size: 18px;
  }

  .label-instructions-list {
    margin-left: 16px; /* slightly less indentation for smaller screens */
    margin-right: 16px;
  }
}
