.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.modal-content {
  background: white;
  padding: 1.5rem;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
  text-align: left;
}

.modal-footer {
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-aligned {
  text-align: left;
}

.close-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
}

.important-info {
  font-size: 14px;
}

.close-button:hover {
  background-color: #0056b3;
}

.checkbox-container {
  display: flex;
  align-items: center;
}

.checkbox-label {
  margin-left: 0.5rem;
}

/* Add this media query for mobile screens */
@media (max-width: 600px) {
  .modal-content {
    width: calc(100% - 10px); /* Add 5px margin on each side */
    margin: 0 5px;
  }
}

.step-text {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.step-number {
  font-weight: bold;
  margin-right: 8px;
  color: #007bff;
  background: rgba(0, 123, 255, 0.1);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.step-content {
  display: block;
  width: 100%;
}

.video-link {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  color: #007bff;
  text-decoration: none;
  font-size: 0.9rem;
  margin-left: 1.5rem;
  margin-top: 0.25rem;
}

.video-link:hover {
  color: #0056b3;
}
