.wave-modal-wrapper {
  position: relative;
}

/* Remove old modal styles that were relative */
.modal-overlay {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content {
  background-color: white;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 90%;
}

.business-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
}

.business-select-btn {
  width: 100%;
  padding: 0.75rem 1rem;
  text-align: left;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  transition: all 0.2s;
}

.business-select-btn:hover {
  background-color: #f3f4f6;
}

.wave-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 50;
}
.wave-icon {
  width: 16px; /* Adjust the size of the icon (small enough for buttons) */
  height: 16px; /* Maintain square aspect ratio */
  object-fit: contain; /* Ensures the logo scales proportionally */
  margin-right: 4px; /* Optional: Add spacing if needed between icon and text */
}

.wave-modal-content-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 51;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wave-modal-content {
  background-color: white;
  border-radius: 0.75rem;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  padding: 1.75rem;
  margin: 1rem;
  max-width: 425px;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.wave-modal-title {
  color: #111827;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.wave-modal-description {
  color: #4b5563;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 1.5rem;
}

.wave-modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid #f3f4f6;
}
/* Add this new class */
.wave-button-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: nowrap; /* Prevent wrapping */
}

/* Modify the existing share-to-wave-btn class */
.share-to-wave-btn {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.25rem 0.75rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  font-weight: 500;
  color: #4b5563;
  cursor: pointer;
  /* Remove max-width constraint */
  /* max-width: 250px; */
  white-space: nowrap; /* Prevent text wrapping */
  transition: all 0.2s ease-in-out;
  background-color: #f9fafb;
  border: 1px solid #e5e7eb;
  text-decoration: none;
}

.share-to-wave-btn:hover {
  background-color: #f3f4f6;
  border-color: #d1d5db;
  transform: scale(1.02);
}

/* Share state styles */
.share-to-wave-btn.share-state {
  color: #1f73b7;
  background-color: transparent;
  border: 2px solid transparent;
}

.share-to-wave-btn.share-state:hover {
  background-color: #f4f5f7;
  border: 2px solid #1f73b7;
}

.share-to-wave-btn.disabled {
  background-color: #e5e7eb;
  color: #9ca3af;
  border-color: transparent;
  cursor: not-allowed;
  transform: none;
}

.share-to-wave-btn:active {
  transform: scale(0.98);
}

.share-to-wave-btn i {
  margin-right: 0.5rem;
}

.submitted-status {
  font-size: 0.875rem; /* Smaller font size */
  padding: 0.25rem 0.5rem; /* Reduce padding for a label-like feel */
  background-color: #d4edda; /* Light green background for subtle effect */
  color: #155724; /* Dark green text for contrast */
  border: 1px solid #c3e6cb; /* Add a border for clarity */
  border-radius: 0.25rem;
  display: inline-block; /* Change from button-like to a tag/label */
}

.processing-icon {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
