/* ComplianceDocumentsModal.css */

.compliance-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 20px;
}

.compliance-modal-container {
  background-color: white;
  border-radius: 8px;
  max-width: 900px;
  width: 90%;
  max-height: 85vh;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.compliance-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  border-bottom: 1px solid #eaeaea;
  background-color: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: sticky;
  top: 0;
  z-index: 10;
}

.compliance-modal-header h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.compliance-modal-body {
  padding: 20px;
  overflow-y: auto;
  max-height: calc(85vh - 70px); /* 70px accounts for header */
  flex-grow: 1;
}

.compliance-close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
  padding: 0;
  margin: 0;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: background-color 0.2s;
}

.compliance-close-button:hover {
  background-color: #f0f0f0;
  color: #333;
}

/* Make sure the "View Summary" section stays within the modal */
.compliance-modal-body .report-card,
.compliance-modal-body .bg-gray-50 {
  max-width: 100%;
  overflow-x: hidden;
}

/* Fix for expandable content */
.compliance-modal-body .mt-4 .bg-gray-50 {
  max-height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
}

/* Responsive adjustment for mobile */
@media (max-width: 600px) {
  .compliance-modal-container {
    width: 95%;
    max-height: 90vh;
  }

  .compliance-modal-body {
    max-height: calc(90vh - 70px);
    padding: 16px;
  }

  .compliance-modal-header {
    padding: 12px 16px;
  }
}
