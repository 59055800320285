/* Base styles */
.modal-content.enhanced-camera {
  background: black;
  border-radius: 10px;
  padding: 20px 10px;
  width: 90%;
  max-width: 500px;
  height: 85vh;
  height: -webkit-fill-available;
  min-height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}

.enhanced-camera-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

/* Camera preview styles */
.enhanced-camera-preview {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  background-color: black;
  display: block;
}

video.enhanced-camera-preview {
  background-color: transparent;
  -webkit-playsinline: playsinline;
  playsinline: playsinline;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
}

/* Controls */
.capture-button {
  width: 72px;
  height: 72px;
  background: white;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 24px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease;
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}

.capture-button:active {
  transform: scale(0.95) translateX(-50%);
}

.preview-controls {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px;
  padding-bottom: calc(16px + env(safe-area-inset-bottom));
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  display: flex;
  justify-content: space-around;
  gap: 16px;
  z-index: 15;
  margin-bottom: 60px; /* Makes space for the collapsed panel */
}

.preview-button1 {
  flex: 1;
  padding: 14px;
  font-size: 16px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: transform 0.2s ease;
  background: rgba(255, 255, 255, 0.9);
}

.preview-button1:active {
  transform: scale(0.96);
}

.preview-button1.retake {
  background: rgba(255, 255, 255, 0.25);
  color: white;
}

.preview-button1:not(.retake) {
  background: white;
  color: #2196f3;
}

.close-xc {
  color: black;
  background: rgba(255, 255, 255, 0.9);
  border: none;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
  font-size: 28px;
  font-weight: bold;
  z-index: 20;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.close-xc:active {
  transform: scale(0.93);
}

.processing-status {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 16px 24px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 12px;
  z-index: 30;
}

.file-input-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Slide-up panel for tags and notes */
.tag-note-panel {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  border-radius: 16px 16px 0 0;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  height: 60px;
  transition: height 0.3s ease-out;
  z-index: 100;
  overflow: hidden;
  /* Add isolation to create a new stacking context */
  isolation: isolate;
}

.tag-note-panel.expanded {
  height: 70vh;
  max-height: 500px;
}

.panel-drag-handle {
  padding: 12px 16px;
  text-align: center;
  cursor: pointer;
  user-select: none;
}

.panel-drag-indicator {
  width: 36px;
  height: 4px;
  background-color: #e0e0e0;
  border-radius: 4px;
  margin: 0 auto 8px;
}

.panel-title {
  font-size: 14px;
  font-weight: 500;
  color: #606060;
}

.panel-content {
  padding: 0 16px 16px;
  height: calc(100% - 60px);
  overflow-y: auto;
  /* Add position relative to establish a new stacking context */
  position: relative;
  z-index: 1;
  background-color: white;
}

/* Tags section */
.tags-section {
  margin-bottom: 16px;
  position: relative;
  /* Add background to prevent content beneath from showing through */
  background-color: white;
}

.tags-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.tags-section h4,
.notes-section h4 {
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  color: #333;
}

.create-tag-toggle {
  background-color: rgba(0, 51, 160, 0.1);
  border: none;
  border-radius: 12px;
  color: #0033a0;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  padding: 4px 8px;
}

.create-tag-toggle:hover {
  background-color: rgba(0, 51, 160, 0.15);
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding-bottom: 8px;
  position: relative;
  z-index: 1;
}

.tags-container:empty::after {
  content: "No tags available";
  display: block;
  width: 100%;
  text-align: center;
  color: #757575;
  font-size: 14px;
  padding: 12px;
}

.tag-chip {
  display: inline-flex;
  align-items: center;
  padding: 6px 12px;
  background-color: #f0f0f0;
  border: 1px solid #e0e0e0;
  border-radius: 16px;
  font-size: 13px;
  font-weight: 500;
  color: #505050;
  cursor: pointer;
  transition: all 0.2s ease;
  /* Ensure min-width and hide overflow text */
  min-width: 40px;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tag-chip:hover {
  background-color: #e8e8e8;
}

.tag-chip.selected {
  background-color: #0033a0;
  color: white;
  border-color: #0033a0;
}

.loading-tags,
.no-tags {
  padding: 12px;
  text-align: center;
  color: #757575;
  font-size: 14px;
  background-color: #f8f8f8;
  border-radius: 8px;
}

.create-tag-container {
  position: relative;
  z-index: 2;
  background-color: white;
  padding: 10px 0;
  width: 100%;
  /* Add box shadow to ensure it visually sits on top */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.new-tag-input {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 14px;
  margin-bottom: 8px;
  background-color: white;
}

.new-tag-input:focus {
  outline: none;
  border-color: #0033a0;
  box-shadow: 0 0 0 2px rgba(0, 51, 160, 0.1);
}

.new-tag-hint {
  font-size: 12px;
  color: #757575;
  margin-bottom: 12px;
}

.create-first-tag-btn {
  background-color: #f0f0f0;
  border: none;
  border-radius: 16px;
  padding: 6px 12px;
  font-size: 13px;
  color: #0033a0;
  cursor: pointer;
  margin-top: 8px;
}

.create-first-tag-btn:hover {
  background-color: #e8e8e8;
}

/* Notes section */
.notes-section {
  position: relative;
}

.note-textarea {
  width: 100%;
  min-height: 100px;
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 14px;
  resize: none;
  line-height: 1.5;
  font-family: inherit;
}

.note-textarea:focus {
  outline: none;
  border-color: #0033a0;
  box-shadow: 0 0 0 2px rgba(0, 51, 160, 0.1);
}

/* Mobile styles */
@media (max-width: 768px) {
  .modal-content.enhanced-camera {
    width: 100%;
    height: 100vh;
    border-radius: 0;
    margin: 0;
    padding: env(safe-area-inset-top) env(safe-area-inset-right)
      env(safe-area-inset-bottom) env(safe-area-inset-left);
  }

  .enhanced-camera-preview {
    height: 100vh;
    max-height: none;
  }

  .close-xc {
    width: 64px;
    height: 64px;
    font-size: 32px;
    top: max(16px, env(safe-area-inset-top));
    right: max(16px, env(safe-area-inset-right));
    background: white;
  }

  .tag-note-panel.expanded {
    max-height: 60vh;
  }
}

@media (min-width: 768px) {
  .tag-note-panel.expanded {
    max-height: 400px;
  }
}

/* iOS viewport fixes */
@supports (-webkit-touch-callout: none) {
  .modal-content.enhanced-camera {
    height: -webkit-fill-available;
    min-height: -webkit-fill-available;
    max-height: -webkit-fill-available;
  }
}

/* Samsung browser specific fixes */
@supports (-webkit-appearance: none) and (not (overflow: -webkit-marquee)) and
  (not (-ms-ime-align: auto)) and (not (-moz-appearance: none)) {
  video.enhanced-camera-preview {
    background-color: transparent !important;
    opacity: 1 !important;
    visibility: visible !important;
  }
}

.internal-error {
  background-color: #ffebee;
  color: #d32f2f;
  padding: 8px 12px;
  border-radius: 4px;
  margin: 8px 0;
  font-size: 13px;
  text-align: center;
  animation: fadeInOut 3s forwards;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Voice Recording Styles */
.voice-recording-wrapper {
  position: relative;
  width: 100%;
  padding-top: 10px;
  height: 44px;
  margin-bottom: 10px;
}

.voice-note-button {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  color: #606060;
  transition: all 0.2s ease;
  z-index: 2;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.voice-note-button:active {
  transform: scale(0.95);
}

.voice-note-button:hover:not(:disabled) {
  background-color: #e0e0e0;
}

.voice-note-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.voice-note-button i {
  font-size: 18px;
}

.voice-note-button.recording {
  background-color: #ff4c4c;
  color: white;
  animation: pulse 1.5s infinite;
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.voice-note-button.processing {
  background-color: #1890ff;
  color: white;
}

.recording-indicator {
  position: absolute;
  right: 10px;
  bottom: 10px;
  display: flex;
  align-items: center;
  background-color: rgba(255, 76, 76, 0.1);
  border-radius: 22px;
  padding: 5px 15px 5px 5px;
  z-index: 1;
}

.recording-pulse {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ff4c4c;
  margin-right: 8px;
  animation: pulsate 1s infinite;
}

.recording-duration {
  font-size: 14px;
  font-weight: 500;
  color: #ff4c4c;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes pulsate {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
/* Safe area fixes */
.modal-content.enhanced-camera {
  padding-bottom: calc(20px + env(safe-area-inset-bottom));
  height: calc(var(--vh, 1vh) * 85);
  max-height: calc(var(--vh, 1vh) * 85);
}

/* Fix capture button positioning to account for bottom bar */
.capture-button {
  bottom: calc(40px + env(safe-area-inset-bottom));
}

/* Fix preview controls positioning */
.preview-controls {
  padding-bottom: calc(16px + env(safe-area-inset-bottom));
  margin-bottom: calc(60px + env(safe-area-inset-bottom));
}

/* Fix panel positioning */
.tag-note-panel {
  bottom: env(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  min-height: calc(60px + env(safe-area-inset-bottom));
}

/* Adjust expanded panel height to prevent overflow with bottom bar */
@media (max-width: 768px) {
  .modal-content.enhanced-camera {
    height: calc(var(--vh, 1vh) * 100 - env(safe-area-inset-bottom));
    max-height: calc(var(--vh, 1vh) * 100 - env(safe-area-inset-bottom));
  }

  .enhanced-camera-preview {
    height: calc(var(--vh, 1vh) * 100 - env(safe-area-inset-bottom));
    max-height: calc(var(--vh, 1vh) * 100 - env(safe-area-inset-bottom));
  }

  .tag-note-panel.expanded {
    max-height: calc(60vh - env(safe-area-inset-bottom));
  }
}

/* Additional iOS viewport height fixes */
@supports (-webkit-touch-callout: none) {
  .enhanced-camera-container {
    height: calc(100vh - env(safe-area-inset-bottom));
  }
}
