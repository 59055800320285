/* CameraSelector.css - Final Consolidated Version */
/* ================ BASE STYLING ================ */
.camera-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15px; /* Add some space at the top for the close button */
}

.camera-preview {
  width: 100%;
  height: calc(100% - 80px);
  object-fit: cover;
  max-height: calc(100vh - 120px);
}

.modal-content.camera-selector {
  background: white;
  border-radius: 10px;
  padding: 20px 10px;
  width: 90%;
  max-width: 500px;
  min-height: 500px;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}

.modal-content {
  max-height: 90vh;
  overflow-y: auto;
  padding: 20px;
  width: 100%;
  max-width: 550px;
  display: flex;
  flex-direction: column;
}

.close-xc {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 24px;
  color: #333;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 100; /* Ensure it's above other elements within the modal */
}

/* ================ FILE SELECT AREA ================ */
.file-select-area {
  width: 100%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  border-radius: 5px;
  position: relative;
}

.file-info {
  margin-top: 20px;
  font-size: 0.9rem;
  color: #666;
  text-align: center;
}

/* ================ BUTTONS ================ */
.file-upload-button {
  padding: 15px 30px;
  background-color: #0033a0;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: all 0.2s ease;
  z-index: 5;
}

.file-upload-button:hover {
  transform: translateY(-1px);
}

.select-more-button {
  background-color: #4caf50 !important;
  color: white !important;
  margin-bottom: 15px;
}

.continue-button {
  background-color: #0033a0 !important;
  color: white !important;
  padding: 12px 30px;
  margin-top: 20px;
  z-index: 5;
}

/* ================ FILE LIST ================ */
.selected-files {
  margin-top: 20px;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background: #f9f9f9;
  width: 100%;
}

.file-list li {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border-bottom: 1px solid #e0e0e0;
}

.file-entry-container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.file-details {
  display: flex;
  flex: 1;
  min-width: 0;
  margin-right: 8px;
}

/* ================ TAG SELECTOR FIXES ================ */
.file-tag-selector {
  margin: 0 8px;
  min-width: 100px;
  position: relative;
  z-index: 2;
}

.file-tag-selector-component.active-dropdown {
  z-index: 9999 !important;
}

.file-tag-selector .tag-selector-dropdown {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: 280px !important;
  max-width: 90% !important;
  max-height: 60vh !important;
  z-index: 2147483647 !important;
  background: white !important;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3) !important;
  border-radius: 8px !important;
  border: 1px solid #ddd !important;
}

.tag-dropdown-overlay {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  background-color: rgba(0, 0, 0, 0.4) !important;
  z-index: 2147483646 !important;
}

#tag-selector-portal {
  position: fixed;
  inset: 0;
  z-index: 2147483646;
  pointer-events: none;
}

#tag-selector-portal > * {
  pointer-events: all;
}

/* ================ RESPONSIVE ADJUSTMENTS ================ */
@media (max-width: 768px) {
  .modal-content.camera-selector {
    width: 100%;
    height: 100vh;
    padding: 10px;
    border-radius: 0;
  }

  .camera-preview {
    height: calc(100vh - 140px);
  }
}

@media (max-width: 480px) {
  .file-tag-selector .tag-selector-dropdown {
    max-height: 50vh !important;
    width: 90% !important;
  }

  .file-entry-container {
    flex-direction: column;
    align-items: flex-start;
  }
}
/* Add these rules at the end of CameraSelector.css */

/* Ensure portal container is above all modals */
#tag-selector-portal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999 !important; /* Higher than any other element */
  pointer-events: none;
}

/* Fix modal backdrop conflict */
.modal-backdrop {
  z-index: 1000 !important; /* Lower than tag portal */
}

/* Prevent parent modal from creating new stacking context */
.modal-content {
  position: static !important;
  transform: none !important;
  overflow: visible !important;
  z-index: auto !important;
}

/* Force dropdown positioning */
.file-tag-selector .tag-selector-dropdown {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  z-index: 9999999 !important; /* Higher than overlay */
}

.tag-dropdown-overlay {
  background-color: rgba(0, 0, 0, 0.4) !important;
  z-index: 999999 !important; /* Just below dropdown */
}

/* Add this to your CameraSelector.css */

/* Fix for remove file button positioning */
.file-entry-container {
  position: relative;
  padding-right: 30px; /* Make space for the X button */
}

.remove-file {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #888;
  font-size: 18px;
  cursor: pointer;
  padding: 5px 10px;
}

/* Fix for mobile screens */
@media (max-width: 480px) {
  .file-entry-container {
    padding-right: 30px; /* Keep space for the X button */
  }

  .remove-file {
    top: 10px; /* Position at top when in mobile view */
    transform: none;
  }
}
/* Add this to your CameraSelector.css */

/* Improved styling for processing status */
.processing-status {
  background-color: #333;
  color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.processing-status i {
  color: #4285f4; /* Blue spinner */
  margin-right: 10px;
}

.progress-bar-container {
  margin-top: 15px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  height: 8px;
  width: 100%;
  overflow: hidden;
}

.progress-bar-fill {
  background-color: #4285f4;
  height: 100%;
  transition: width 0.3s ease;
}
/* Add to CameraSelector.css - Makes sure the text color is explicitly white */

.processing-status span {
  color: white !important; /* Force white text */
  font-size: 14px;
}

.processing-status i {
  color: #4285f4 !important; /* Make sure spinner is blue */
}

/* Making the tag selector in compact mode more prominent */

/* Highlight the "Add Tag" button */
.file-tag-selector-component .tag-selector-button {
  background-color: #ffc107; /* Bright, eye-catching color */
  color: #212121;
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.file-tag-selector-component .tag-selector-button:hover {
  background-color: #ff9800;
  color: #fff;
}

/* End of making tag selector more prominent */
