/* Enhanced styles for ExpensePolicyStatus component */

/* Container styling */
.expense-policy-status {
  display: flex;
  align-items: center;
  margin-right: 16px;
  position: relative;
}

/* Loading state styling */
.policy-loading {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: #666;
  padding: 6px 12px;
  background-color: #f8f9fa;
  border-radius: 6px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

/* Policy available styling */
.policy-available {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Policy link styling */
.policy-link {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 14px;
  background-color: #f0f7ff;
  color: #0033a0;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  border: 1px solid #cce0ff;
  transition: all 0.2s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
}

.policy-link:hover {
  background-color: #e0f0ff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
  transform: translateY(-1px);
  text-decoration: none;
}

.policy-link i {
  color: #0033a0;
  font-size: 16px;
}

/* Manage button styling */
.policy-manage-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 14px;
  background-color: #f8f9fa;
  color: #495057;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.policy-manage-button:hover {
  background-color: #e9ecef;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
  transform: translateY(-1px);
}

.policy-manage-button i {
  color: #495057;
  font-size: 16px;
}

/* Upload button styling - call-to-action */
.policy-upload-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: #f0f7ff;
  color: #0033a0;
  border: 1px solid #cce0ff;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.policy-upload-button:hover {
  background-color: #e0f0ff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
  transform: translateY(-1px);
}

.policy-upload-button i {
  color: #0033a0;
  font-size: 16px;
}

/* Reports header styling */
.reports-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 16px;
  border-bottom: 1px solid #e9ecef;
  flex-wrap: wrap;
  gap: 16px;
}

.reports-title-section {
  flex: 1;
}

.expense-report-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #212529;
  margin: 0;
}

.filter-controls {
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
}

/* Status select dropdown styling */
.status-select {
  padding: 8px 12px;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  background-color: #fff;
  color: #495057;
  font-size: 14px;
  cursor: pointer;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 24 24' fill='none' stroke='%23495057' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 8px center;
  padding-right: 32px;
  transition: border-color 0.2s;
}

.status-select:hover {
  border-color: #adb5bd;
}

.status-select:focus {
  outline: none;
  border-color: #0033a0;
  box-shadow: 0 0 0 3px rgba(0, 51, 160, 0.1);
}

/* Responsive styles */
@media (max-width: 768px) {
  .reports-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  .filter-controls {
    width: 100%;
    justify-content: space-between;
  }

  .expense-policy-status {
    margin-right: 0;
    margin-bottom: 8px;
  }

  /* For very small screens, show icon-only versions */
  @media (max-width: 480px) {
    .policy-link span,
    .policy-manage-button span,
    .policy-upload-button span {
      display: none;
    }

    .policy-link,
    .policy-manage-button,
    .policy-upload-button {
      padding: 8px;
      justify-content: center;
    }

    .policy-link i,
    .policy-manage-button i,
    .policy-upload-button i {
      margin-right: 0;
    }

    .status-select {
      max-width: 120px;
    }
  }

  /* For slightly larger mobile screens, keep the text */
  @media (min-width: 481px) and (max-width: 768px) {
    .policy-link {
      max-width: none;
      width: auto;
    }

    .policy-available {
      justify-content: space-between;
      width: 100%;
    }
  }
}

/* Dark mode support (if your app supports it) */
@media (prefers-color-scheme: dark) {
  .policy-link {
    background-color: rgba(0, 51, 160, 0.1);
    color: #4d8bf8;
    border-color: rgba(77, 139, 248, 0.2);
  }

  .policy-link:hover {
    background-color: rgba(0, 51, 160, 0.15);
  }

  .policy-upload-button {
    background-color: rgba(0, 51, 160, 0.1);
    color: #4d8bf8;
    border-color: rgba(77, 139, 248, 0.2);
  }

  .policy-upload-button:hover {
    background-color: rgba(0, 51, 160, 0.15);
  }

  .policy-manage-button {
    background-color: rgba(73, 80, 87, 0.1);
    color: #adb5bd;
    border-color: rgba(173, 181, 189, 0.2);
  }

  .policy-manage-button:hover {
    background-color: rgba(73, 80, 87, 0.15);
  }
}
