/* src/styles/EmailManagement.css */

.email-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(2px);
}

.email-modal {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  width: 90%;
  max-width: 540px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.email-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  border-bottom: 1px solid #eaeaea;
}

.email-modal-header h3 {
  color: #0033a0;
  margin: 0;
  font-size: 1.25rem;
  font-weight: 600;
}

.close-button {
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #555;
}

.close-button:hover {
  color: #000;
}

.email-modal-content {
  padding: 20px;
  max-height: 60vh;
  overflow-y: auto;
}

.email-description {
  margin-bottom: 20px;
  color: #555;
}

.email-input-section {
  margin-bottom: 20px;
}

.email-input-container {
  display: flex;
  gap: 8px;
}

.email-input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.add-email-button {
  padding: 10px 16px;
  background-color: #0033a0;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
}

.add-email-button:hover {
  background-color: #002380;
}

.add-email-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.email-error {
  color: #d32f2f;
  margin-top: 8px;
  font-size: 14px;
}

.email-list-container {
  margin-top: 20px;
}

.email-list-container h4 {
  color: #333;
  margin-bottom: 10px;
  font-size: 1rem;
}

.email-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 200px;
  overflow-y: auto;
  padding-right: 8px;
}

.email-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.email-text {
  font-size: 14px;
  color: #333;
}

.remove-email-button {
  background: transparent;
  border: none;
  color: #888;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
}

.remove-email-button:hover {
  color: #d32f2f;
}

.no-emails-message {
  color: #777;
  font-style: italic;
  margin: 10px 0;
}

.email-modal-footer {
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  border-top: 1px solid #eaeaea;
}

.changes-summary {
  margin-bottom: 12px;
  font-size: 14px;
  color: #555;
}

.button-group {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.cancel-button {
  padding: 8px 16px;
  background-color: white;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-button:hover {
  background-color: #f5f5f5;
}

.save-button {
  padding: 8px 16px;
  background-color: #0033a0;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.save-button:hover {
  background-color: #002380;
}

.save-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
