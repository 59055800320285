/* AdminView.css - Simplified and organized */

/* ===== LAYOUT & CONTAINERS ===== */
.admin-view-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

.main-invite-section {
  background: white;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* ===== HEADER STYLES ===== */
.client-header {
  margin-bottom: 15px;
  border-bottom: 1px solid #d0d7de;
  padding-bottom: 8px;
}

.client-header h3,
.client-section-title {
  color: #0033a0;
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0;
}

/* ===== TAB NAVIGATION ===== */
.tab-container {
  margin-bottom: 20px;
  border-bottom: 1px solid #e5e7eb;
}

.tab-button {
  padding: 10px 20px;
  margin-right: 10px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 1rem;
  color: #6b7280;
}

.tab-button.active {
  color: #0033a0;
  border-bottom: 2px solid #0033a0;
}

/* ===== TABLE STYLES ===== */
.client-table-container {
  overflow-x: auto;
  margin-top: 20px;
  width: 100%;
}

.client-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  table-layout: fixed;
}

.client-table th,
.client-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.client-table th {
  background-color: #f8f9fa;
  font-weight: 600;
  color: #0033a0;
}

.client-table .th-name,
.client-table .td-name {
  width: 40%;
}

.client-table .th-email,
.client-table .td-email {
  width: 40%;
}

.client-table .th-status,
.client-table .td-status {
  width: 20%;
}

/* ===== EMAIL DISPLAY TOOLTIP ===== */
.name-with-email {
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
}

.email-icon {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  padding: 4px;
  border-radius: 50%;
}

.email-icon:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

/* This is the key part for the email tooltip */

.tooltip-content1 {
  position: fixed;
  z-index: 9999;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border: 3px solid #0033a0;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  text-align: center;
}

/* Mobile-specific styling */
@media (max-width: 768px) {
  .tooltip-content1 {
    left: 50%;

    width: auto; /* Let the content define the width */
    padding: 10px;
    border-width: 1px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9998;
}

.tooltip-header {
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.recipient {
  word-break: break-all;
}

/* ===== BUTTONS & CONTROLS ===== */
.view-button {
  display: flex;
  align-items: center;
  gap: 6px;
  background-color: #0033a0;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 13px;
  cursor: pointer;
}

.delete-button {
  color: #dc3545;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  padding: 4px;
}

.action-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.status-badge {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.875rem;
  font-weight: 500;
}

.status-badge.pending {
  background-color: #fff3cd;
  color: #856404;
}

.status-actions {
  display: flex;
  align-items: center;
  gap: 10px;
}

.text-button {
  background: none;
  border: none;
  color: #0033a0;
  padding: 4px 8px;
  cursor: pointer;
  font-size: 0.875rem;
}

.loading-button-content {
  display: flex;
  align-items: center;
  gap: 4px;
}

/* ===== EMPTY STATE ===== */
.no-clients-message {
  color: #666;
  padding: 20px 0;
}

/* ===== LOADING STATE ===== */
.loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

/* ===== MOBILE STYLES ===== */
@media (max-width: 768px) {
  /* Hide email column on mobile */
  .client-table .th-email,
  .client-table .td-email {
    display: none;
  }

  /* Adjust column widths */
  .client-table .th-name,
  .client-table .td-name {
    width: 60%;
  }

  .client-table .th-status,
  .client-table .td-status {
    width: 40%;
    text-align: right;
  }

  /* Simplify view button */
  .view-button {
    padding: 6px;
    min-width: auto;
  }

  .button-text2 {
    display: none;
  }

  /* Table styling */
  .client-table {
    font-size: 0.9em;
  }

  .client-table th,
  .client-table td {
    padding: 8px;
  }
}
