/* Date range selector modal */
.date-range-selector {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 400px;
  max-height: 90%; /* Ensure the modal doesn't exceed viewport height */
  overflow-y: auto; /* Allow scrolling if content overflows */
  z-index: 1000;
  animation: fadeIn 0.3s ease-out;
  box-sizing: border-box; /* Ensure padding is included in width */
}

/* Ensure the date picker menu is above the modal */
.rs-picker-menu {
  z-index: 1101 !important; /* Ensure this is higher than the modal */
  width: 100% !important; /* Make picker responsive */
  max-width: 100% !important; /* Restrict max width */
  left: 0 !important; /* Align picker to the left */
}

/* Popup styling */
.rs-picker-popup {
  z-index: 1001;
  max-width: 100% !important; /* Ensure picker doesn't exceed viewport width */
  overflow: hidden; /* Hide overflow */
}

/* Flexbox for buttons */
.date-range-selector .button-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  margin-top: 10px;
}

/* Button styling */
.date-range-selector button {
  flex: 1 1 auto; /* Allow buttons to grow and shrink */
  min-width: 100px; /* Ensure buttons are not too small */
  margin: 5px; /* Adjust margins for better spacing */
  padding: 10px 15px;
  border: none;
  border-radius: 8px;
  background-color: #0033a0;
  color: #ffd700;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

/* Button for canceling */
.date-range-selector button:last-child {
  background-color: #0033a0;
}

/* Button hover effects */
.date-range-selector button:hover {
  background-color: #b0c4de;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Button hover effects for cancel button */
.date-range-selector button:last-child:hover {
  background-color: #b0c4de;
}

/* Button focus state */
.date-range-selector button:focus {
  outline: none;
}

/* Ensure the calendar icon is visible by default */
.rs-picker-caret-icon.rs-icon {
  display: block !important; /* Ensure it's displayed */
}

/* Hide the "X" button initially */
.rs-picker-clean.rs-btn-close {
  display: none !important; /* Hide the "X" button initially */
}

/* Show "X" button only when a date range is selected */
.rs-picker-has-value .rs-picker-clean.rs-btn-close {
  display: block !important; /* Show the "X" button after a date is selected */
}

/* Responsive styles */
@media (max-width: 600px) {
  .date-range-selector h2 {
    font-size: 1.2em;
  }

  .date-range-selector button {
    font-size: 0.9em;
    padding: 8px 12px;
  }

  .date-range-selector {
    padding: 15px;
  }

  /* Adjust the width of the date picker menu */
  .rs-picker-menu {
    max-width: 100% !important;
  }

  /* Ensure date picker pop-up is responsive */
  .rs-picker-popup {
    max-width: 100% !important;
  }
}
