/* ==========================================================================
   Main Container
   ========================================================================== */
.photo-selection-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.photo-source-buttons {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

/* ==========================================================================
     Hero Camera Button
     ========================================================================== */
.camera-button-featured {
  background-color: #0066ff;
  color: white;
  border: none;
  border-radius: 12px;
  padding: 24px;
  width: 100%;
  height: 180px;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 4px 20px rgba(0, 102, 255, 0.15);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.camera-button-featured:hover:not(:disabled) {
  background-color: #0052cc;
  transform: translateY(-2px);
  box-shadow: 0 6px 24px rgba(0, 102, 255, 0.2);
}

.camera-button-featured:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.camera-button-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.camera-icon {
  color: white;
  padding: 12px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
}

.camera-button-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  text-align: center;
}

.primary-text {
  font-size: 26px;
  font-weight: 600;
}

.secondary-text {
  font-size: 16px;
  opacity: 0.9;
  margin-top: 4px;
}

/* ==========================================================================
     Section Headers
     ========================================================================== */
.options-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.options-section-title {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin: 0;
}

.manage-tags-link {
  font-size: 14px;
  color: #64748b;
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px 8px;
  text-decoration: none;
  transition: color 0.2s ease;
}

.manage-tags-link:hover {
  color: #0066ff;
}

/* ==========================================================================
     Layout & Containers
     ========================================================================== */
.secondary-options {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

.small-buttons-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.google-services-group {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 16px;
  width: 100%;
}

/* ==========================================================================
     Button Styles - Google Photos
     ========================================================================== */
.google-photos-button {
  background-color: white;
  border: 2px solid #0066ff;
  border-radius: 8px;
  padding: 16px 20px;
  display: flex;
  align-items: center;
  gap: 16px;
  transition: all 0.2s ease;
  height: auto;
  min-height: 80px;
}

.google-photos-button:hover:not(:disabled) {
  background-color: #f8f9fa;
  box-shadow: 0 2px 8px rgba(0, 102, 255, 0.1);
}

.google-photos-button:disabled {
  border-color: #e0e0e0;
  color: #a0aec0;
  cursor: not-allowed;
}

/* ==========================================================================
     Button Styles - Gmail
     ========================================================================== */
.google-service-button {
  background-color: white;
  border: 1px solid #4285f4; /* Google blue color */
  border-radius: 8px;
  padding: 16px 20px;
  display: flex;
  align-items: center;
  gap: 16px;
  transition: all 0.2s ease;
  height: auto;
  min-height: 72px;
}

.google-service-button:hover:not(:disabled) {
  background-color: #f8f9fa;
  box-shadow: 0 2px 8px rgba(66, 133, 244, 0.1);
}

.google-service-button:disabled {
  border-color: #e0e0e0;
  color: #a0aec0;
  cursor: not-allowed;
}

.google-service-button .button-icon-container {
  background-color: #e8f0fe; /* Light Google blue background */
  color: #4285f4;
}

/* ==========================================================================
     Button Styles - Secondary (Upload, PDF, etc.)
     ========================================================================== */
.secondary-button {
  background-color: white;
  color: #4285f4;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 16px 20px;
  display: flex;
  align-items: center;
  gap: 16px;
  transition: all 0.2s ease;
  height: auto;
  min-height: 72px;
}

.secondary-button:hover:not(:disabled) {
  background-color: #f8f9fa;
  border-color: #4285f4;
}

.secondary-button:disabled {
  background-color: #f5f5f5;
  color: #a0aec0;
  border-color: #e0e0e0;
  cursor: not-allowed;
}

/* ==========================================================================
     Button Styles - Mileage Selection (in PhotoSelectionContainer)
     ========================================================================== */
.mileage-selection-button {
  border-left: 4px solid #34d399 !important;
  background-color: #f0fdfa !important;
  transition: all 0.2s ease;
  color: #333 !important; /* Explicitly set color for readability */
}

.mileage-selection-button:hover:not(:disabled) {
  background-color: #e6fffa !important;
  border-color: #10b981 !important;
}

.mileage-selection-button .mileage-icon-container {
  background-color: #d1fae5 !important;
  color: #10b981 !important;
}

/* ==========================================================================
     Button Components
     ========================================================================== */
.button-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #f1f5f9;
  border-radius: 50%;
  color: #4285f4;
  flex-shrink: 0;
}

.button-text-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  text-align: left; /* Ensure all text is left-aligned */
}

.button-title {
  font-size: 16px;
  font-weight: 600;
  color: #333;
}

.button-description {
  font-size: 14px;
  color: #64748b;
}

.button-beta {
  display: inline-block;
  background-color: #f1f5f9;
  color: #64748b;
  font-size: 10px;
  padding: 2px 8px;
  border-radius: 10px;
  margin-left: 8px;
  vertical-align: middle;
}

/* Specific fix for the PDF button */
.pdf-upload-button .button-text-container {
  text-align: left;
}

/* ==========================================================================
     Email Forwarding Feature
     ========================================================================== */
.email-forward-feature {
  background-color: #f0f9ff; /* Light blue background */
  border: 1px solid #bae6fd;
  border-radius: 12px;
  padding: 16px;
  margin-top: 24px;
  transition: all 0.2s ease;
}

.email-forward-feature:hover {
  box-shadow: 0 4px 12px rgba(0, 102, 255, 0.1);
}

.email-forward-content {
  display: flex;
  align-items: center;
  gap: 16px;
}

.email-forward-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background-color: #0066ff;
  border-radius: 50%;
  color: white;
  flex-shrink: 0;
}

.email-forward-text {
  flex: 1;
}

.email-forward-text h4 {
  font-size: 16px;
  font-weight: 600;
  color: #0f172a;
  margin: 0 0 8px 0;
  display: flex;
  align-items: center;
  gap: 8px;
}

.new-feature-badge {
  display: inline-block;
  background-color: #0066ff;
  color: white;
  font-size: 10px;
  font-weight: 700;
  padding: 3px 6px;
  border-radius: 4px;
  letter-spacing: 0.5px;
  vertical-align: middle;
  margin-bottom: 8px;
}

.email-forward-text p {
  font-size: 14px;
  color: #334155;
  margin: 0;
  line-height: 1.5;
}

.email-highlight {
  font-weight: 600;
  background-color: white;
  padding: 2px 6px;
  border-radius: 4px;
  border: 1px solid #bae6fd;
}

/* ==========================================================================
     Utility & Helper Classes
     ========================================================================== */
.tertiary-link-container {
  margin: 10px 0;
  text-align: center;
}

.tertiary-link {
  background: none;
  border: none;
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
  font-size: 1rem;
}

.upload-options-group {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

/* ==========================================================================
     Notifications
     ========================================================================== */
.notification-box {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 16px;
}

.notification {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  padding: 12px 16px;
  border-radius: 8px;
}

.notification.info {
  background-color: #e7f3fe;
  border-left: 4px solid #2196f3;
  color: #0b3d91;
}

.notification.success {
  background-color: #e6ffed;
  border-left: 4px solid #4caf50;
  color: #256029;
}

.notification .icon {
  flex-shrink: 0;
  margin-top: 4px;
}

.notification .icon i {
  font-size: 20px;
}

.notification span:nth-child(2) {
  font-size: 14px;
  line-height: 1.5;
}

/* ==========================================================================
     Responsive Styles - Organized by breakpoint
     ========================================================================== */

/* Desktop (min-width: 768px) */
@media (min-width: 768px) {
  .small-buttons-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto auto;
    gap: 16px;
  }
}

/* Very narrow screens */
@media (max-width: 320px) {
  .camera-button-featured {
    min-height: 120px;
    padding: 10px;
  }

  .primary-text {
    font-size: 18px;
  }

  .secondary-text {
    font-size: 12px;
    max-width: 220px;
  }

  .camera-button-content {
    gap: 8px;
  }

  .camera-icon {
    padding: 6px;
  }

  .button-title {
    font-size: 13px;
  }

  .button-description {
    font-size: 11px;
  }

  .email-forward-text h4 {
    font-size: 13px;
  }

  .email-forward-text p {
    font-size: 11px;
  }
}

/* Tablets & smaller desktops (max-width: 768px) */
@media (max-width: 768px) {
  .email-forward-content {
    flex-direction: row;
    align-items: center;
  }

  .email-forward-text {
    width: 100%;
  }
}

/* Mobile devices (max-width: 600px) */
@media (max-width: 600px) {
  .camera-button-featured {
    height: 150px;
    padding: 16px;
  }

  .primary-text {
    font-size: 22px;
  }

  .secondary-text {
    font-size: 14px;
  }

  .google-photos-button,
  .secondary-button,
  .google-service-button {
    padding: 12px 16px;
    min-height: 64px;
  }

  .button-title {
    font-size: 14px;
  }

  .button-description {
    font-size: 12px;
  }

  .notification {
    align-items: center;
  }

  .notification .icon i {
    font-size: 24px;
  }

  .email-forward-feature {
    padding: 12px;
  }

  .email-forward-icon {
    width: 40px;
    height: 40px;
  }

  .email-forward-text h4 {
    font-size: 15px;
  }

  .email-forward-text p {
    font-size: 13px;
  }
}

/* Small mobile devices (max-width: 480px) */
@media (max-width: 480px) {
  .email-forward-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .email-forward-icon {
    margin: 0 auto 8px;
  }

  .email-forward-text {
    text-align: center;
    width: 100%;
  }
}

/* Extra small screens (iPhone SE, Galaxy S8+, etc.) */
@media (max-width: 375px) {
  /* Camera button adjustments */
  .camera-button-featured {
    height: auto;
    min-height: 130px;
    padding: 12px;
  }

  .primary-text {
    font-size: 20px;
    line-height: 1.2;
  }

  .secondary-text {
    font-size: 13px;
    line-height: 1.3;
    max-width: 240px;
    margin: 2px auto 0;
  }

  /* Adjust icon size */
  .camera-icon {
    padding: 8px;
  }

  .camera-button-content {
    gap: 12px;
  }

  /* Email forwarding adjustments */
  .email-forward-icon {
    width: 36px;
    height: 36px;
  }

  .email-forward-text h4 {
    font-size: 14px;
  }

  .email-forward-text p {
    font-size: 12px;
  }

  .email-highlight {
    display: inline-block;
    margin-top: 4px;
  }
}
