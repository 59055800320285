/* ComplianceCheck.css - Fixed for proper desktop display */

/* General Button Styles */
.compliance-button,
.compliance-check-button {
  background-color: #0033a0;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s ease-in-out;
}

.compliance-button:hover,
.compliance-check-button:hover {
  background-color: #002777;
  transform: translateY(-1px);
  box-shadow: 0 2px 8px rgba(0, 51, 160, 0.15);
}

.compliance-button:disabled,
.compliance-check-button:disabled {
  background: #6c757d;
  cursor: not-allowed;
}

.compliance-check-container {
  margin-top: 6px;
  width: 100%;
}

.compliance-controls {
  /* Control the width of the button container, not the entire component */
  display: inline-block;
  width: auto;
}

/* Add this media query to make it full width on mobile */
@media (max-width: 768px) {
  .compliance-controls {
    display: block;
    width: 100%;
  }

  /* Make the button inside take up full width too */
  .compliance-controls .compliance-button,
  .compliance-controls .compliance-button-placeholder {
    width: 100%;
    max-width: none;
  }
}

.compliance-results {
  margin-top: 8px;
  animation: fadeIn 0.3s ease-in;
}

/* Compliance Items */
.compliance-item {
  background: white;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  padding: 12px;
  margin-bottom: 10px;
  position: relative;
  z-index: 1;
}

.compliance-item.compliant {
  border-left: 4px solid #28a745;
}

.compliance-item.violation {
  border-left: 4px solid #dc3545;
}

/* Compliance Header */
.compliance-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.expense-description {
  font-weight: 500;
}

/* Compliance Status */
.compliance-status {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
}

.compliance-item.compliant .compliance-status {
  background: #d4edda;
  color: #155724;
}

.compliance-item.violation .compliance-status {
  background: #f8d7da;
  color: #721c24;
}

/* Compliance Status Icons */
.compliance-status::before {
  margin-right: 6px;
  font-family: "Font Awesome 5 Free";
}

.compliance-item.compliant .compliance-status::before {
  content: "\f058"; /* check-circle icon */
}

.compliance-item.violation .compliance-status::before {
  content: "\f057"; /* times-circle icon */
}

/* Error Messages */
.error-message {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px !important;
  margin-top: 12px;
  color: #721c24;
  background: #f8d7da;
  border-radius: 4px;
  font-size: 13px;
}

.error-message::before {
  content: "⚠️";
  font-size: 16px;
}

/* Loading spinner container */
.loading-button-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

/* Match button styles */
.compliance-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  background-color: #0033a0;
  color: white;
  border: none;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  font-size: 14px;
  height: 40px;
  transition: background-color 0.2s ease;
  min-width: 180px;
}

.compliance-button:hover {
  background-color: #002a80;
}

.compliance-button-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  background-color: #e9ecef;
  color: #6c757d;
  border: none;
  border-radius: 4px;
  font-weight: 500;
  height: 40px;
  font-size: 14px;
  cursor: default;
  min-width: 180px;
}

/* Compliance Summary - More Compact */
.compliance-summary {
  background: white;
  border-radius: 6px;
  padding: 12px 16px;
  margin-top: 8px;
  width: 100%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.summary-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.summary-header h4 {
  margin: 0;
  color: #333;
  font-size: 15px;
}

.status-badge {
  padding: 4px 10px;
  border-radius: 999px;
  font-size: 12px;
  font-weight: 500;
}

.status-badge.success {
  background-color: #d1fae5;
  color: #065f46;
}

.status-badge.warning {
  background-color: #fef3c7;
  color: #92400e;
}

/* More compact summary stats */
.summary-stats {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 4px;
}

.stat {
  flex: 1;
  min-width: 90px;
  text-align: center;
  padding: 8px 6px;
  border-radius: 4px;
  background: #f8f9fa;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.stat .label {
  font-size: 12px;
  color: #6b7280;
  margin-bottom: 2px;
}

.stat .value {
  font-size: 16px;
  font-weight: 600;
}

.stat .value.success {
  color: #059669;
}

.stat .value.warning {
  color: #d97706;
}

/* Desktop adjustments */
@media (min-width: 769px) {
  .compliance-summary {
    border-radius: 6px;
    padding: 14px 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .summary-stats {
    flex-direction: row;
  }

  .stat {
    flex: 0 0 auto;
    min-width: 140px;
    padding: 10px 8px;
  }

  /* Fixed layout for desktop */
  .compliance-check-container {
    width: 100%;
    display: block;
  }
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .summary-stats {
    gap: 6px;
  }

  .stat {
    flex: 1 0 calc(33.333% - 6px);
    padding: 8px 4px;
  }
}

/* Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
/* Integration container fix for compliance check */
.integration-container {
  width: 100%; /* Ensure the integration container takes full width */
  margin-bottom: 8px; /* Keep consistent with existing styles */
}

/* Modified layout for accounting controls */
.accounting-controls {
  display: flex;
  flex-direction: column;
  width: 100%;
}

/* Mobile-specific adjustments */
@media (max-width: 768px) {
  .integration-buttons {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

/* Desktop-specific adjustments */
@media (min-width: 769px) {
  .integration-buttons {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  /* Add this to make sure the button doesn't stretch too wide */
  .compliance-button,
  .compliance-button-placeholder {
    width: auto;
    max-width: 250px;
  }
}
@media (min-width: 769px) {
  .compliance-controls {
    display: block;
    width: 100%;
  }
}

.compliance-button.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.info-message {
  font-size: 0.85rem;
  color: #6c757d;
  margin-top: 0.5rem;
}

/* Add this to your ComplianceCheck.css file */

.violations-container {
  margin-top: 20px;
  border-top: 1px solid #e0e0e0;
  padding-top: 15px;
}

.violations-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.violations-header h5 {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
}

.toggle-button {
  background: none;
  border: none;
  color: #2196f3;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
}

.toggle-button:hover {
  text-decoration: underline;
}

.violation-categories {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.violation-category {
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  overflow: hidden;
}

.category-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 15px;
  background-color: #f5f5f5;
  cursor: pointer;
}

.category-header:hover {
  background-color: #eeeeee;
}

.category-title {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
}

.item-count {
  display: inline-block;
  background-color: #ff5252;
  color: white;
  border-radius: 12px;
  padding: 2px 8px;
  font-size: 12px;
  margin-left: 8px;
}

.category-actions {
  padding: 10px 15px;
  background-color: #f9f9f9;
  border-bottom: 1px solid #e0e0e0;
}

.batch-fix-button {
  background-color: #2196f3;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.batch-fix-button:hover {
  background-color: #1976d2;
}

.violation-items {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.violation-item {
  border-bottom: 1px solid #e0e0e0;
}

.violation-item:last-child {
  border-bottom: none;
}

.item-header {
  padding: 12px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.item-header:hover {
  background-color: #f5f5f5;
}

.item-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-right: 15px;
}

.merchant {
  font-weight: 500;
}

.amount {
  color: #757575;
}

.item-details {
  padding: 10px 15px 15px;
  background-color: #f9f9f9;
}

.violation {
  margin: 0 0 12px;
  color: #d32f2f;
  font-size: 14px;
}

.actions-heading {
  margin: 0 0 8px;
  font-size: 14px;
  font-weight: 500;
}

.action-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.action-button-c {
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  cursor: pointer;
  font-size: 13px;
  text-align: left;
  width: 100%;
}

.action-button-c:hover {
  background-color: #388e3c;
}

/* New action buttons when we make the button active to fix something */
/* Additional styles for action buttons */
.action-button-c {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  background-color: #0033a0;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  margin: 4px 0;
  width: 100%;
  text-align: center;
}

.action-button-c:hover {
  background-color: #00287e;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.action-button-c:active {
  transform: translateY(0);
  box-shadow: none;
}

.action-list {
  display: flex;
  flex-direction: column;
  gap: 6px;
  list-style: none;
  padding: 0;
  margin: 8px 0 0 0;
}

.action-item {
  width: 100%;
}

.batch-fix-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 12px;
  background-color: #f0f2f5;
  color: #0033a0;
  border: 1px solid #d0d5dd;
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.2s;
  margin-bottom: 10px;
}

.batch-fix-button:hover {
  background-color: #e0e4eb;
}

.batch-fix-button i {
  font-size: 14px;
  color: #0033a0;
}

/* Styles for different action types */
.action-button-c[data-action-type="purpose"] {
  background-color: #38598b;
}

.action-button-c[data-action-type="purpose"]:hover {
  background-color: #2c4870;
}

.action-button-c[data-action-type="mileage"] {
  background-color: #5b8c5a;
}

.action-button-c[data-action-type="mileage"]:hover {
  background-color: #49704a;
}

.action-button-c[data-action-type="approval"] {
  background-color: #b15e6c;
}

.action-button-c[data-action-type="approval"]:hover {
  background-color: #9a4f5c;
}

/* Mobile responsive styles */
@media (max-width: 576px) {
  .action-button-c {
    padding: 10px;
    font-size: 13px;
  }

  .batch-fix-button {
    width: 100%;
    justify-content: center;
  }
}

/* Add these styles to your ComplianceCheck.css file */

/* Violation grouping */
.violation-group {
  margin-bottom: 16px;
  padding: 10px 12px;
  border-radius: 6px;
  background-color: #f8f9fa;
  border-left: 3px solid #6c757d;
}

.violation-group:last-child {
  margin-bottom: 0;
}

.violation-group-title {
  font-weight: 600;
  color: #495057;
  margin: 0 0 8px 0;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
}

.violation-group-title i {
  color: #6c757d;
}

/* Specific colors for each type of violation group */
.violation-group.mileage {
  border-left-color: #5b8c5a;
  background-color: #f1f8e9;
}
.violation-group.mileage .violation-group-title i {
  color: #5b8c5a;
}

.violation-group.purpose {
  border-left-color: #38598b;
  background-color: #e8f0fe;
}
.violation-group.purpose .violation-group-title i {
  color: #38598b;
}

.violation-group.receipt {
  border-left-color: #6b7280;
  background-color: #f3f4f6;
}
.violation-group.receipt .violation-group-title i {
  color: #6b7280;
}

.violation-group.approval {
  border-left-color: #b15e6c;
  background-color: #fef2f2;
}
.violation-group.approval .violation-group-title i {
  color: #b15e6c;
}

.violation-group.other {
  border-left-color: #f59e0b;
  background-color: #fffbeb;
}
.violation-group.other .violation-group-title i {
  color: #f59e0b;
}

/* Make violations within groups more compact */
.violation-group .violation {
  margin-bottom: 4px;
  font-size: 13px;
  padding-left: 20px;
  position: relative;
  color: #4b5563; /* Less harsh than bright red */
}

.violation-group .violation:last-child {
  margin-bottom: 0;
}

.violation-group .violation:before {
  content: "•";
  position: absolute;
  left: 8px;
}

/* Action button improvements */
.action-button-d {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  background-color: #0033a0;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  margin: 4px 0;
  width: 100%;
  text-align: center;
}

.action-button-d:hover {
  background-color: #00287e;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.action-button-d:active {
  transform: translateY(0);
  box-shadow: none;
}

/* Specific button styles for different action types */
.action-button-d[data-action-type="purpose"] {
  background-color: #38598b;
}
.action-button-d[data-action-type="purpose"]:hover {
  background-color: #2c4870;
}

.action-button-d[data-action-type="mileage"] {
  background-color: #5b8c5a;
}
.action-button-d[data-action-type="mileage"]:hover {
  background-color: #49704a;
}

.action-button-d[data-action-type="receipt"] {
  background-color: #6b7280;
}
.action-button[data-action-type="receipt"]:hover {
  background-color: #4b5563;
}

.action-button-d[data-action-type="approval"] {
  background-color: #b15e6c;
}
.action-button-d[data-action-type="approval"]:hover {
  background-color: #9a4f5c;
}

.action-button-d[data-action-type="override"] {
  background-color: #fd7e14;
}
.action-button-d[data-action-type="override"]:hover {
  background-color: #e8700f;
}

/* Action button loading state */
.action-button-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

/* Manual override button style */
.manual-override-button {
  margin-top: 12px;
  border-top: 1px dashed #e0e0e0;
  padding-top: 12px;
}

.manual-override-info {
  margin-top: 8px;
  padding-top: 8px;
  border-top: 1px dashed #e0e0e0;
}

.override-status {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  background-color: #f0f8ff;
  border-radius: 4px;
  color: #0033a0;
  font-size: 14px;
}

.override-status i {
  color: #4caf50;
}

.violation.informational {
  color: #0066cc;
  background-color: #e6f2ff;
  border-left: 3px solid #0066cc;
  padding-left: 8px;
}

.info-note {
  font-style: italic;
  font-size: 0.9em;
  color: #666;
}

/* Violation styling */
.violation {
  position: relative;
  padding: 8px 12px;
  margin-bottom: 8px;
  border-radius: 4px;
  border-left: 3px solid #d9534f;
  background-color: #f9f2f2;
}

.violation.informational {
  border-left: 3px solid #0066cc;
  background-color: #e6f2ff;
  color: #0066cc;
  padding-left: 8px;
}

.violation.warning {
  border-left: 3px solid #f0ad4e;
  background-color: #fcf8e3;
  color: #8a6d3b;
}

.info-note {
  font-style: italic;
  font-size: 0.9em;
  color: #666;
  display: block;
  margin-top: 4px;
}

/* Action button styling */
.single-action-container {
  margin-top: 12px;
  display: flex;
  justify-content: flex-end;
}

.override-action-button {
  background-color: #0033a0;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
}

.override-action-button:hover {
  background-color: #002780;
}

.override-action-button i {
  font-size: 14px;
}
