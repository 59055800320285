/* TagSelector.css - Final Working Version */
.tag-selector {
  position: relative;
  display: inline-block;
  z-index: 5;
}

.tag-selector-button {
  display: flex;
  align-items: center;
  gap: 6px;
  background-color: #f2f2f2;
  border: none;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 12px;
  color: #555;
  cursor: pointer;
  transition: background-color 0.2s;
}

.tag-selector-button:hover {
  background-color: #e6e6e6;
}

.tag-selector-button.has-tag {
  background-color: #e8f0fe;
  color: #1a73e8;
}

.tag-selector-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 220px;
  max-height: 300px;
  overflow-y: auto;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  margin-top: 4px;
  border: 1px solid #eee;
}

/* Critical Modal Context Styles */
.file-list .tag-selector-dropdown,
.file-tag-selector-component .tag-selector-dropdown {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: 280px !important;
  max-width: 90% !important;
  max-height: 70vh !important;
  z-index: 2147483647 !important;
  background-color: white !important;
  border: 1px solid #ccc !important;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.3) !important;
  border-radius: 8px !important;
}

.tag-dropdown-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4) !important;
  z-index: 2147483646 !important;
  pointer-events: all !important;
}

/* Portal Container */
#tag-selector-portal {
  position: fixed;
  inset: 0;
  z-index: 2147483646;
  pointer-events: none;
}

#tag-selector-portal > * {
  pointer-events: all;
}

/* Content Styles */
.tag-option {
  padding: 8px 12px;
  font-size: 13px;
  cursor: pointer;
  transition: background-color 0.2s;
  background-color: white !important;
}

.tag-option:hover {
  background-color: #f5f5f5 !important;
}

.tag-option.selected {
  background-color: #e8f0fe !important;
  color: #1a73e8;
}

.tag-option.no-tag {
  border-bottom: 1px solid #eee;
  color: #888;
  background-color: white !important;
}

.tag-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  color: #888;
  font-size: 13px;
  gap: 8px;
  background-color: white !important;
}

.spin-icon {
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.no-tags-available {
  padding: 12px;
  text-align: center;
  background-color: white !important;
}

.no-tags-available p {
  font-size: 13px;
  color: #888;
  margin-bottom: 8px;
}

.create-tag-container {
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: white !important;
}

.new-tag-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 13px;
}

.tag-actions {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.tag-cancel-btn,
.tag-save-btn {
  flex: 1;
  padding: 6px 0;
  border: none;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
}

.tag-cancel-btn {
  background-color: #f0f0f0;
  color: #333;
}

.tag-save-btn {
  background-color: #4285f4;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 60px;
}

.tag-cancel-btn:disabled,
.tag-save-btn:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.tag-error {
  color: #d32f2f;
  font-size: 12px;
}

.tag-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 14px;
  border-bottom: 1px solid #eee;
  font-size: 14px;
  font-weight: 500;
  color: #333;
  background-color: #f9f9f9 !important;
}

.create-new-tag-btn {
  background: none;
  border: none;
  color: #4285f4;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.create-new-tag-btn:hover {
  background-color: rgba(66, 133, 244, 0.1);
}

.create-first-tag-btn {
  background-color: #4285f4;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 12px;
  cursor: pointer;
  width: 100%;
}

/* Compact Mode */
.tag-selector.compact .tag-selector-button {
  padding: 2px 6px;
  font-size: 11px;
}

/* Mobile */
@media (max-width: 480px) {
  .tag-option {
    padding: 10px;
    font-size: 12px;
  }

  .create-tag-container {
    padding: 10px;
    gap: 8px;
  }

  .new-tag-input,
  .tag-cancel-btn,
  .tag-save-btn {
    font-size: 11px;
    padding: 5px;
  }

  .tag-header {
    padding: 8px 10px;
    font-size: 12px;
  }

  .tag-loading,
  .no-tags-available p {
    font-size: 12px;
  }
}

/* Add to the end of TagSelector.css */

/* Ultimate z-index fix for TagSelector */
#tag-selector-portal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2147483647 !important; /* Maximum z-index */
  pointer-events: none; /* Let clicks pass through by default */
}

#tag-selector-portal > * {
  pointer-events: auto; /* Re-enable pointer events for children */
}

.tag-dropdown-overlay {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  background-color: rgba(0, 0, 0, 0.4) !important;
  z-index: 2147483646 !important;
}

.tag-selector-dropdown {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: 280px !important;
  max-width: 90% !important;
  max-height: 70vh !important;
  z-index: 2147483647 !important;
  background-color: white !important;
  border: 1px solid #ccc !important;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.3) !important;
  border-radius: 8px !important;
  overflow-y: auto !important;
}

/* Ensure modal content doesn't create a new stacking context that breaks our z-index */
.modal-content {
  isolation: auto !important;
}

/* Fix for Bootstrap modal conflicts */
.modal-backdrop {
  z-index: 1040 !important; /* Ensure our portal is above this */
}

/* Style for tag header buttons container */
.tag-header-buttons {
  display: flex;
  align-items: center;
  gap: 6px;
}

/* Style for close button */
.tag-close-btn {
  background: none;
  border: none;
  color: #555;
  font-size: 20px;
  line-height: 1;
  cursor: pointer;
  padding: 0 6px;
  margin-left: 4px;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.tag-close-btn:hover {
  background-color: rgba(0, 0, 0, 0.05);
  color: #333;
}
