/* ReportWizardModal.css - Cleaned up version */

/************************************
 * MODAL BACKDROP & WRAPPER
 ************************************/
.report-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.report-modal-wrapper {
  position: relative;
  background: #fff;
  border-radius: 12px;
  width: 95%;
  max-width: 750px;
  max-height: 95vh;
  overflow-y: auto;
  padding: 30px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  font-family: "Arial", sans-serif;
  display: flex;
  flex-direction: column;
}

/************************************
 * CLOSE BUTTON
 ************************************/
.report-modal-close {
  background: none;
  border: none;
  font-size: 1.8rem;
  font-weight: bold;
  cursor: pointer;
  color: #333;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 10;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.report-modal-close:hover {
  background-color: #002c91;
  color: #fff;
}

/************************************
 * HEADER SECTION
 ************************************/
.report-modal-header {
  text-align: center;
  margin-bottom: 20px;
  position: relative;
}

.report-modal-title {
  margin: 0;
  font-size: 1.6rem;
  font-weight: 600;
  color: #333;
  padding-bottom: 10px;
}

/************************************
 * IMPROVED STEP NAVIGATION
 ************************************/
/* Horizontal stepper CSS */

.report-steps-progress {
  margin-bottom: 30px;
}

.steps-container {
  display: flex;
  flex-direction: row; /* Change to row for horizontal layout */
  align-items: center;
  justify-content: space-between;
  max-width: 500px;
  margin: 0 auto;
}

.step-item {
  display: flex;
  flex-direction: column; /* Keep steps as column internally */
  align-items: center;
  position: relative;
  flex: 1;
}

.step-circle {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #e9ecef;
  color: #6c757d;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-bottom: 8px;
  z-index: 1;
  transition: all 0.3s ease;
}

.step-item.active .step-circle {
  background-color: #0033a0;
  color: white;
}

.step-item.completed .step-circle {
  background-color: #28a745;
  color: white;
}

.step-check {
  font-size: 1rem;
}

.step-label {
  font-size: 0.85rem;
  color: #6c757d;
  text-align: center;
  white-space: nowrap; /* Prevent wrapping */
}

.step-item.active .step-label {
  color: #0033a0;
  font-weight: 600;
}

.step-item.completed .step-label {
  color: #28a745;
}

/* Make connector horizontal */
.step-connector {
  flex: 1;
  height: 2px;
  background-color: #e9ecef;
  margin: 0 5px;
  position: relative;
  top: -18px; /* Position it at the middle of circles */
  z-index: 0;
}

.step-item.active + .step-connector,
.step-item.completed + .step-connector {
  background-color: #0033a0;
}

/* Hide old step indicator */
.report-modal-step-indicator {
  display: none;
}
/************************************
 * MODAL CONTENT SCROLLABLE AREA
 ************************************/
.report-modal-content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 0 10px;
}

.report-modal-content h3 {
  color: #0033a0;
  font-size: 1.3rem;
  margin-bottom: 15px;
  font-weight: 600;
}

.report-modal-content h4 {
  font-size: 1.1rem;
  color: #444;
  margin-bottom: 10px;
  font-weight: 500;
}

/************************************
 * TAG SELECTION (STEP 1)
 ************************************/
.tag-selection-container {
  margin-top: 10px;
  margin-bottom: 20px;
}

/* "What are Tags?" Info Box */
.tags-info-box {
  background-color: #f0f7ff;
  border-left: 4px solid #0033a0;
  border-radius: 6px;
  padding: 15px;
  margin: 15px 0;
  position: relative;
}

.tags-info-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #0033a0;
  color: white;
  font-weight: bold;
  font-size: 14px;
  margin-right: 10px;
  float: left;
}

.tags-info-content {
  color: #333;
  font-size: 14px;
  line-height: 1.5;
  margin-left: 35px;
}

.tags-info-title {
  font-weight: 600;
  margin-bottom: 8px;
  color: #0033a0;
}

/* Loading State for tags */
.loading-state {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  color: #0033a0;
}

.tags-list {
  max-height: 250px;
  overflow-y: auto;
  margin-top: 10px;
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 10px;
  background-color: #fafafa;
}

.tag-row {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;
  padding: 6px 10px;
  border-radius: 4px;
}

.tag-row input[type="checkbox"] {
  margin-right: 8px;
  transform: scale(1.2);
}

.tag-row:hover {
  background-color: #f2f8ff;
}

/************************************
 * PREVIEW SECTION (STEP 2)
 ************************************/
.preview-container {
  margin-top: 10px;
  margin-bottom: 20px;
}

/* Report Summary Card */
.report-summary-card {
  background-color: white;
  border-radius: 10px;
  padding: 5px;
  margin: 15px 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  border: 1px solid #eaeaea;
}

.report-summary-content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
}

.report-summary-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 15px;
}

.summary-value {
  font-size: 1.8rem;
  font-weight: 700;
  color: #0033a0;
  line-height: 1;
}

.summary-label {
  font-size: 0.9rem;
  color: #555;
  margin-top: 5px;
}

.summary-divider {
  width: 1px;
  height: 40px;
  background-color: #e0e0e0;
}

/* Report Title Input */
.report-title-input-container {
  margin-bottom: 20px;
  width: 100%;
}

.report-title-input-container label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
  color: #333;
}

.report-title-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
}

.report-title-input:focus {
  border-color: #0033a0;
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 51, 160, 0.1);
}

/* Report Type Banner */
.report-type-banner {
  display: flex;
  align-items: center;
  padding: 12px 15px;
  border-radius: 8px;
  margin: 15px 0;
  font-weight: 600;
}

.report-type-banner.receipt {
  background-color: #e6f7ee;
  color: #28a745;
  border-left: 4px solid #28a745;
}

.report-type-banner.invoice {
  background-color: #e6f0ff;
  color: #0033a0;
  border-left: 4px solid #0033a0;
}

.report-type-icon {
  font-size: 1.2rem;
  margin-right: 8px;
}

/* Document Count Summary */
.document-count-summary {
  display: flex;
  gap: 15px;
  margin: 15px 0;
}

.document-type-count {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 60px;
}

.count-badge {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  margin-bottom: 5px;
  color: white;
}

.document-type-count.receipt .count-badge {
  background-color: #28a745;
}

.document-type-count.bill .count-badge {
  background-color: #fd7e14;
}

.document-type-count.invoice .count-badge {
  background-color: #0033a0;
}

.count-label {
  font-size: 12px;
  color: #555;
}

/* Collapsible Section Styles */
.collapsible-section {
  margin-bottom: 15px;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  overflow: hidden;
}

.collapsible-header {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px 15px;
  background-color: #f8f9fa;
  border: none;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.collapsible-header:hover {
  background-color: #e9ecef;
}

.collapsible-icon-prefix {
  margin-right: 10px;
  font-size: 1.1rem;
}

.collapsible-title {
  flex-grow: 1;
  font-weight: 600;
  font-size: 1rem;
  color: #333;
}

.collapsible-arrow {
  font-size: 0.8rem;
  transition: transform 0.3s ease;
  color: #777;
}

.collapsible-arrow.open {
  transform: rotate(180deg);
}

.collapsible-content {
  padding: 15px;
  background-color: white;
}

/* Currency breakdown */
.report-currency-group {
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
}

.report-currency-title {
  color: #0033a0;
  font-size: 1.1rem;
  margin-bottom: 15px;
  font-weight: 600;
}

.report-total-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
}

.report-total-label {
  color: #555;
}

.report-total-value {
  min-width: 120px;
  text-align: right;
  font-weight: 500;
  color: #0033a0;
  margin-left: 10px;
}

/* Enhanced report functional total */
.report-functional-total {
  background-color: #f0f7ff;
  border-radius: 8px;
  padding: 12px 15px;
  margin: 15px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.report-functional-total .report-total-label {
  font-weight: 600;
  color: #0033a0;
}

.report-functional-total .report-total-value {
  font-size: 1.2rem;
  font-weight: 700;
  color: #0033a0;
}

/* Mixed Document Alert */
.mixed-document-alert {
  background-color: #fff8e1;
  border: 1px solid #ffe082;
  border-radius: 8px;
  padding: 12px 16px;
  margin: 15px 0;
}

.mixed-document-alert p {
  margin-top: 0;
  color: #856404;
  display: flex;
  align-items: center;
}

.mixed-document-alert i {
  margin-right: 8px;
  color: #856404;
}

.alert-action {
  margin-top: 8px;
}

.alert-action label {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #555;
  cursor: pointer;
}

.alert-action input[type="checkbox"] {
  margin-right: 8px;
}

/* Report Alert */
.report-alert {
  display: flex;
  align-items: center;
  background-color: #fff4e5;
  color: #856404;
  border: 1px solid #ffeeba;
  padding: 12px 16px;
  border-radius: 8px;
  font-size: 14px;
  margin-top: 16px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.report-alert i {
  margin-right: 8px;
  color: #d39e00;
  font-size: 18px;
}

/************************************
 * EMAIL RECIPIENTS SECTION
 ************************************/
.email-recipients-section {
  margin-top: 0;
  padding-top: 0;
}

.email-section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.email-section-header h4 {
  color: #0033a0;
  margin: 0;
}

.email-description {
  font-size: 14px;
  color: #555;
  margin-bottom: 15px;
  line-height: 1.4;
}

.email-input-container {
  display: flex;
  gap: 8px;
  margin-bottom: 10px;
}

.email-input {
  flex-grow: 1;
  padding: 10px 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 14px;
  transition: border-color 0.3s ease;
}

.email-input:focus {
  outline: none;
  border-color: #0033a0;
}

.email-input:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.add-email-button {
  background-color: #0033a0;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.add-email-button:hover {
  background-color: #002580;
}

.add-email-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.email-error-message {
  color: #d32f2f;
  font-size: 13px;
  margin-top: 5px;
  margin-bottom: 10px;
}

.email-recipients-list {
  margin-top: 15px;
  max-height: 150px;
  overflow-y: auto;
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 10px;
  background-color: #fafafa;
}

.email-recipient {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px;
  background-color: #f2f8ff;
  border-radius: 4px;
  margin-bottom: 8px;
}

.email-text {
  font-size: 14px;
  color: #333;
}

.remove-email-button {
  background: none;
  border: none;
  color: #888;
  font-size: 18px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.remove-email-button:hover {
  color: #d32f2f;
}

.clear-emails-button {
  background: none;
  border: none;
  color: #0033a0;
  font-size: 14px;
  margin-top: 10px;
  padding: 5px;
  cursor: pointer;
  text-decoration: underline;
  transition: color 0.3s ease;
}

.clear-emails-button:hover {
  color: #d32f2f;
}

.no-emails-message {
  color: #888;
  font-size: 14px;
  font-style: italic;
  text-align: center;
  padding: 15px 0;
}

.no-recipients-message {
  color: #888;
  font-size: 14px;
  font-style: italic;
  text-align: center;
  padding: 15px 0;
}

/************************************
 * BUTTONS & CONTROLS
 ************************************/
/* The main button used for Next or Create Report */
.report-submit-button {
  background-color: #0033a0;
  color: #fff;
  border: none;
  padding: 12px 30px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 6px;
  transition: background-color 0.3s ease;
  min-width: 180px;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.report-submit-button:hover {
  background-color: #002080;
}

.report-submit-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.report-submit-button .button-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.report-submit-button .spinner {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.report-secondary-button {
  background-color: #fff;
  color: #0033a0;
  border: 2px solid #0033a0;
  padding: 12px 30px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 6px;
  transition: all 0.3s ease;
  min-width: 180px;
  text-align: center;
}

.report-secondary-button:hover {
  background-color: #0033a0;
  color: #fff;
}

/************************************
 * SUCCESS / CONFIRMATION (STEP 3)
 ************************************/
.report-success-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  text-align: center;
  padding: 20px 0;
}

.report-success-section h3 {
  color: #28a745;
  margin-bottom: 10px;
}

.report-success-section p {
  color: #444;
  max-width: 600px;
  line-height: 1.4;
}

/************************************
 * ERROR & ALERT MESSAGES
 ************************************/
.error-message {
  color: #f44336;
  background-color: #fdecea;
  padding: 10px 15px;
  border-radius: 6px;
  margin-top: 10px;
  font-size: 0.9rem;
  border-left: 4px solid #f44336;
}

.success-message {
  color: #28a745;
  background-color: #e4f9ea;
  padding: 10px 15px;
  border-radius: 6px;
  margin-top: 10px;
  font-size: 0.9rem;
  border-left: 4px solid #28a745;
}

/************************************
 * NO EXPENSES MESSAGE
 ************************************/
.no-expenses-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  text-align: center;
}

.no-expenses-message h3 {
  color: #555;
  margin-bottom: 20px;
}

.message-box {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  max-width: 500px;
  display: flex;
  align-items: flex-start;
  text-align: left;
}

.message-box i {
  color: #0033a0;
  font-size: 24px;
  margin-right: 15px;
  margin-top: 2px;
}

.message-box p {
  color: #555;
  margin: 0;
  line-height: 1.5;
  font-size: 16px;
}

.suggestion-text {
  color: #666;
  font-style: italic;
  margin-bottom: 20px;
  font-size: 14px;
}

/************************************
 * FOOTER
 ************************************/
.wizard-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
  padding-top: 15px;
  border-top: 1px solid #eee;
}

/************************************
 * RESPONSIVE ADJUSTMENTS
 ************************************/
@media (max-width: 600px) {
  .report-modal-wrapper {
    padding: 20px;
    width: 90%;
  }

  .report-modal-title {
    font-size: 1.3rem;
  }

  .tags-list {
    max-height: 200px;
  }

  .report-submit-button,
  .report-secondary-button {
    min-width: 120px;
    padding: 10px 20px;
    font-size: 14px;
  }

  .email-section-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .email-recipient {
    padding: 6px 8px;
  }

  .steps-container {
    max-width: 100%;
  }

  .step-label {
    font-size: 0.7rem;
  }
}

/* Added enhancements */

/* Add/update these styles in your CSS file */

/* Hide the "Preview Report" heading */
.preview-container > h3 {
  display: none;
}

/* Update the report banner to accommodate more info */
.report-type-banner {
  display: flex;
  align-items: center;
  padding: 15px;
  border-radius: 8px;
  margin: 15px 0;
  background-color: #e6f7ee;
  border-left: 4px solid #28a745;
}

.report-type-banner.receipt {
  background-color: #e6f7ee;
  border-left-color: #28a745;
}

.report-type-banner.invoice {
  background-color: #e6f0ff;
  border-left-color: #0033a0;
}

.report-type-icon {
  font-size: 1.4rem;
  margin-right: 10px;
}

.report-banner-content {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: flex-start; /* Changed from center to flex-start to handle wrapping */
  flex-wrap: wrap; /* Allow wrapping */
}

/* Add this new style for inline metrics */
.inline-metrics {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping */
  align-items: center;
  margin-left: 0; /* Changed from 15px */
  margin-top: 5px; /* Add some spacing when it wraps */
  color: #555;
  font-size: 0.9rem; /* Slightly smaller font size */
  font-weight: normal;
  line-height: 1.5; /* Better line height for readability */
}

.metric-separator {
  margin: 0 6px;
  display: inline-flex;
  align-items: center;
}

.inline-metrics-value {
  font-weight: 600;
  margin-right: 3px;
  color: #333;
}

.report-type-label {
  display: flex;
  align-items: center;
  flex-wrap: wrap; /* Allow wrapping */
  font-size: 1.1rem;
  font-weight: 600;
  color: #28a745;
  margin-right: 10px;
}

.report-type-banner.invoice .report-type-label {
  color: #0033a0;
}

/* Hide the metrics in the original spot (they'll be inline now) */
.report-metrics {
  display: none;
}

.report-metric-item {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: #555;
}

.report-metric-item .metric-value {
  font-weight: 600;
  margin-right: 5px;
  color: #333;
}

/* Hide the now-redundant document count summary */
.document-count-summary {
  display: none;
}

/* Since we're removing elements, give more breathing room to the title */
.report-title-input-container {
  margin-top: 10px;
}

/* end of enhancements */

/* Add responsive adjustments */
@media (max-width: 480px) {
  .report-type-banner {
    padding: 12px;
  }

  .report-type-label {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .inline-metrics {
    margin-top: 8px;
    margin-left: 0;
    width: 100%;
    justify-content: flex-start;
    gap: 8px;
  }

  .metric-separator {
    display: none; /* Hide separators on small screens */
  }

  /* Add spacing between metrics on small screens */
  .metric-item {
    margin-right: 10px;
  }
}
