/* TagManagementModal.css */
.tag-mgmt-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(3px);
}

.tag-mgmt-modal {
  background: #fff;
  width: 500px;
  max-width: 90%;
  padding: 24px;
  border-radius: 12px;
  position: relative;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  max-height: 85vh;
  overflow-y: auto;
}

.tag-mgmt-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.tag-mgmt-header h3 {
  font-size: 20px;
  margin: 0;
  color: #333;
}

.tag-mgmt-close-button {
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 0;
}

.tag-mgmt-close-button:hover {
  background-color: #f1f5f9;
  color: #333;
}

.tag-mgmt-body {
  margin-bottom: 20px;
}

.tag-mgmt-group {
  margin-bottom: 24px;
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 16px;
}

.tag-mgmt-group h4 {
  margin: 0 0 12px 0;
  font-size: 16px;
  color: #4285f4;
  display: flex;
  align-items: center;
}

.tag-mgmt-count {
  font-size: 14px;
  color: #64748b;
  font-weight: normal;
  margin-left: 6px;
}

.tag-mgmt-group ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.tag-mgmt-group ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  margin-bottom: 6px;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease;
}

.tag-mgmt-group ul li:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tag-mgmt-toggle-button {
  background: none;
  border: 1px solid #4285f4;
  color: #4285f4;
  cursor: pointer;
  font-size: 13px;
  padding: 4px 10px;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.tag-mgmt-toggle-button:hover {
  background-color: #4285f4;
  color: white;
}

.tag-mgmt-add-section {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.tag-mgmt-add-section h4 {
  margin: 0 0 8px 0;
  font-size: 16px;
  color: #333;
}

.tag-mgmt-input-group {
  display: flex;
  gap: 8px;
}

.tag-mgmt-add-section input[type="text"] {
  flex: 1;
  padding: 10px 12px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  font-size: 14px;
  transition: border-color 0.2s ease;
}

.tag-mgmt-add-section input[type="text"]:focus {
  border-color: #4285f4;
  outline: none;
  box-shadow: 0 0 0 2px rgba(66, 133, 244, 0.2);
}

.tag-mgmt-add-button {
  padding: 10px 16px;
  border: none;
  background-color: #4285f4;
  color: white;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
}

.tag-mgmt-add-button:hover {
  background-color: #3367d6;
  transform: translateY(-1px);
}

.tag-mgmt-footer {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  border-top: 1px solid #f1f5f9;
  padding-top: 20px;
}

.tag-mgmt-save-button,
.tag-mgmt-cancel-button {
  padding: 10px 20px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
}

.tag-mgmt-save-button {
  background-color: #0066ff;
  color: white;
}

.tag-mgmt-save-button:hover {
  background-color: #0052cc;
}

.tag-mgmt-save-button:disabled {
  background-color: #a0aec0;
  cursor: not-allowed;
}

.tag-mgmt-cancel-button {
  background-color: #f1f5f9;
  color: #64748b;
}

.tag-mgmt-cancel-button:hover {
  background-color: #e2e8f0;
}

.tag-mgmt-cancel-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.tag-mgmt-loading-indicator {
  text-align: center;
  padding: 20px;
  color: #64748b;
}

.tag-mgmt-empty-message {
  color: #64748b;
  text-align: center;
  padding: 10px;
  font-style: italic;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .tag-mgmt-modal {
    padding: 16px;
  }

  .tag-mgmt-footer {
    flex-direction: column;
  }

  .tag-mgmt-save-button,
  .tag-mgmt-cancel-button {
    width: 100%;
  }
}
