/* GoogleMapsDistance.css */
/* 
   All rules are nested under .google-maps-distance to prevent 
   conflicts with global rules like input[type=text] { ... } 
*/

/* BACKDROP */
.google-maps-distance.map-modal-backdrop {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease-out;
  visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.google-maps-distance.map-modal-backdrop.visible {
  opacity: 1;
  visibility: visible;
}

/* SLIDE-UP CONTAINER */
.google-maps-distance__slideup.map-modal-slide-up {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ffffff;
  border-radius: 16px 16px 0 0;
  box-shadow: 0 -6px 16px rgba(0, 0, 0, 0.1);
  transform: translateY(100%);
  transition: transform 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
  z-index: 1100;
  max-height: 90vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.google-maps-distance__slideup.map-modal-slide-up.visible {
  transform: translateY(0);
}

/* DESKTOP MEDIA QUERY */
@media (min-width: 641px) {
  .google-maps-distance.map-modal-backdrop {
    align-items: center;
    justify-content: center;
  }

  .google-maps-distance__slideup.map-modal-slide-up {
    width: 900px;
    max-width: 95vw;
    position: relative;
    margin: auto;
    transform: translateY(50px);
    border-radius: 16px;
    bottom: initial;
    left: initial;
    right: initial;
  }

  .google-maps-distance__slideup.map-modal-slide-up.visible {
    transform: translateY(0);
  }
}

@media (min-width: 1200px) {
  .google-maps-distance__slideup.map-modal-slide-up {
    width: 1100px;
  }
}

/* DRAG HANDLE */
.google-maps-distance .map-modal-handle {
  width: 40px;
  height: 4px;
  background: #e2e8f0;
  border-radius: 2px;
  margin: 12px auto;
  position: relative;
  padding: 12px 0;
  cursor: grab;
}

/* Remove bounding box from handle if undesired */
.google-maps-distance .map-modal-handle::before {
  content: "";
  display: none;
}

/* HEADER */
.google-maps-distance .map-modal-header {
  padding: 1.5rem;
  background-color: #f8fafc;
  border-bottom: 1px solid #e2e8f0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.google-maps-distance .map-modal-title {
  font-size: 1.25rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: #1e293b;
}

.google-maps-distance .map-modal-close {
  background: transparent;
  border: none;
  color: #64748b;
  font-size: 1.25rem;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 9999px;
  transition: all 0.2s;
}

.google-maps-distance .map-modal-close:hover {
  background: #f1f5f9;
  color: #0f172a;
}

/* CONTENT */
.google-maps-distance .map-modal-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  scroll-behavior: smooth;
  padding: 2rem;
}

/* SEARCH CONTAINER (CARD STYLE) */
.google-maps-distance .map-search-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 2rem;
  background: #f8fafc;
  border: 1px solid #e2e8f0;
  border-radius: 12px;
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .google-maps-distance .map-search-container {
    flex-direction: row;
  }
}

/* INPUT GROUP */
.google-maps-distance .map-input-group {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  min-width: 300px; /* Make them appear longer on desktop */
}

.google-maps-distance .mileage-label {
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 0.25rem;
  color: #1e293b;
}

/* INPUT WRAPPER & ICON */
.google-maps-distance .map-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.google-maps-distance .map-input-wrapper i {
  position: absolute;
  left: 1rem;
  color: #64748b;
  pointer-events: none;
}

/* ACTUAL INPUT */
.google-maps-distance .map-input {
  width: 100%;
  padding: 0.875rem 1rem 0.875rem 3rem;
  border: 1px solid #cbd5e1;
  border-radius: 10px;
  font-size: 1rem;
  transition: border-color 0.2s, box-shadow 0.2s;
}

.google-maps-distance .map-input::placeholder {
  color: #9ca3af;
}

.google-maps-distance .map-input:focus {
  outline: none;
  border-color: #2563eb;
  box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
}

/* MAP CONTAINER */
.google-maps-distance .map-container {
  position: relative;
  border: 1px solid #e2e8f0;
  border-radius: 12px;
  overflow: hidden;
  margin-top: 1rem;
  height: 400px;
}

@media (min-width: 641px) {
  .google-maps-distance .map-container {
    height: 600px;
  }
}

@media (min-width: 1200px) {
  .google-maps-distance .map-container {
    height: 700px;
  }
}

/* LOADING OVERLAY */
.google-maps-distance .map-loading-overlay {
  position: absolute;
  inset: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  z-index: 10;
}

.google-maps-distance .map-loading-spinner {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 1rem 1.5rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

/* ROUTE INFO */
.google-maps-distance .route-info {
  background: #e0f2fe;
  border: 1px solid #bae6fd;
  border-radius: 8px;
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}

.google-maps-distance .route-info-detail {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #0369a1;
}

.google-maps-distance .route-info-detail i {
  font-size: 1.2rem;
}

.google-maps-distance .route-distance {
  font-size: 1.15rem;
  font-weight: 600;
}

/* ALERT MESSAGE */
.google-maps-distance .mileage-alert {
  background: #fee2e2;
  border: 1px solid #fecaca;
  color: #991b1b;
  padding: 1rem;
  border-radius: 8px;
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
  margin-top: 0.5rem;
}

/* ACTION BUTTONS */
.google-maps-distance .map-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 1.5rem;
  margin-top: 1rem;
  border-top: 1px solid #e2e8f0;
  background: #f8fafc;
}

.google-maps-distance .mileage-button {
  min-width: 120px;
  padding: 0.75rem 1rem;
  font-size: 0.95rem;
  border-radius: 6px;
  transition: background 0.2s;
  cursor: pointer;
}

.google-maps-distance .mileage-button-primary {
  background: #0033a0;
  color: #ffffff;
  border: none;
}

.google-maps-distance .mileage-button-primary:hover {
  background: #002c8c;
}

.google-maps-distance .mileage-button-secondary {
  background: #f8fafc;
  color: #475569;
  border: 1px solid #e2e8f0;
}

.google-maps-distance .mileage-button-secondary:hover {
  background: #f1f5f9;
}

/* MOBILE TWEAKS */
@media (max-width: 640px) {
  .google-maps-distance .map-container {
    height: 300px;
  }

  .google-maps-distance .map-actions {
    position: sticky;
    bottom: 0;
    background: #f8fafc;
    padding: 1rem;
    border-top: 1px solid #e2e8f0;
    margin: 0 -1rem -1rem;
  }

  .google-maps-distance .route-info {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .google-maps-distance__slideup.map-modal-slide-up {
    height: 90vh;
    touch-action: none;
    -webkit-overflow-scrolling: touch;
  }
}

/* SCROLLBAR FOR MODAL CONTENT */
.google-maps-distance .map-modal-content::-webkit-scrollbar {
  width: 8px;
}

.google-maps-distance .map-modal-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.google-maps-distance .map-modal-content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.google-maps-distance .map-modal-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.google-maps-distance .map-modal-handle::before {
  /* Forcefully remove any background or dimensions */
  content: none !important;
  display: none !important;
  width: 0 !important;
  height: 0 !important;
  background: none !important;
  border: none !important;
}

/* Add these styles to your GoogleMapsDistance.css file */

.round-trip-option {
  margin-top: 10px;
  padding-top: 5px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.round-trip-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.round-trip-checkbox input[type="checkbox"] {
  margin-right: 8px;
  width: 16px;
  height: 16px;
}

.checkbox-label {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333;
}

.checkbox-label i {
  margin-right: 8px;
  color: #0033a0;
}

/* Adjust the route info appearance for round trips */
.route-info {
  display: flex;
  justify-content: space-around;
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 6px;
  margin: 10px 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.route-info-detail {
  display: flex;
  align-items: center;
}

.route-info-detail i {
  margin-right: 8px;
  color: #0033a0;
}

.route-distance {
  font-weight: 500;
}

/* Update these CSS rules in your GoogleMapsDistance.css file */

/* Updated route info to include round trip checkbox */
.google-maps-distance .route-info {
  background: #e0f2fe;
  border: 1px solid #bae6fd;
  border-radius: 8px;
  padding: 1rem 1.5rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}

.google-maps-distance .route-info-detail {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #0369a1;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
}

.google-maps-distance .round-trip-option {
  margin-left: auto;
  margin-right: 0;
  padding: 0;
  border-top: none;
}

.google-maps-distance .round-trip-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.google-maps-distance .round-trip-checkbox input[type="checkbox"] {
  margin-right: 8px;
  width: 16px;
  height: 16px;
}

.google-maps-distance .checkbox-label {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #0369a1;
  white-space: nowrap;
}

.google-maps-distance .checkbox-label i {
  margin-right: 8px;
  color: #0369a1;
}

/* Responsive adjustments */
@media (max-width: 640px) {
  .google-maps-distance .route-info {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    padding: 0.75rem 1rem;
  }

  .google-maps-distance .round-trip-option {
    margin-top: 0.5rem;
    margin-left: 0;
    width: 100%;
  }
}
