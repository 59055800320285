.checkout-button {
  background-color: #ffd700; /* Yellow background */
  color: #0033a0; /* Blue text color */
  border: none;
  padding: 16px 32px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 8px;
  line-height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 100%;
  max-width: 400px;
  box-sizing: border-box;
  font-weight: 600;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.checkout-button:hover {
  background-color: #b8860b; /* Darker yellow on hover */
  transform: translateY(-2px); /* Slight lift effect */
}

.pricing-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5); /* Dim the background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.3s ease forwards;
  overflow-y: auto; /* Allow scrolling for the entire backdrop */
}

.pricing-modal-backdrop.closing {
  animation: fadeOut 0.3s ease forwards;
}

.pricing-modal.closing {
  animation: slideDown 0.3s ease forwards;
}

.pricing-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  border-bottom: 1px solid #eef0f2;
  background: white;
  position: sticky;
  top: 0;
  z-index: 1;
}

.pricing-modal-header h2 {
  margin: 0;
  color: #0033a0;
  font-size: 1.5rem;
  font-weight: 600;
  flex-grow: 1; /* Ensures the title takes available space */
  text-align: center;
}

.modal-close {
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  color: #666;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 20px;
  transition: all 0.2s ease;
}

.modal-close:hover {
  background: #f5f5f5;
  color: #333;
}

.pricing-modal {
  background: white;
  border-radius: 12px;
  width: 95%;
  max-width: 800px;
  max-height: 90vh; /* Ensures it doesn’t overflow vertically */
  overflow-y: auto; /* Enable scrolling for the modal content */
  position: relative;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  transform: translateY(0);
  animation: slideUp 0.3s ease forwards;
}

.pricing-modal-content {
  display: block; /* Ensure block layout for the pricing table */
  padding: 20px;
  width: 100%; /* Full modal width */
  max-width: 600px; /* Optional: constrain the width */
  height: 100%; /* Allow content to fill height */
  overflow-y: auto; /* Ensure scrolling works */
}

stripe-pricing-table {
  width: 100%; /* Expand to match the modal */
}

.pricing-modal-content stripe-pricing-table {
  width: 100%; /* Take up full width */
  max-width: 100%; /* Remove column restriction */
  border: 1px solid #eef0f2;
  border-radius: 8px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(20px);
    opacity: 0;
  }
}

@media (max-width: 768px) {
  .pricing-modal {
    width: 100%;
    height: 100%;
    max-height: 100vh;
    margin: 0;
    border-radius: 0;
    overflow-y: auto; /* Enable scrolling for the entire modal on mobile */
  }

  .modal-close {
    top: 12px; /* Fine-tune positioning for mobile */
    right: 12px; /* Fine-tune positioning for mobile */
  }

  .pricing-modal-header {
    padding: 16px 20px;
  }

  .pricing-modal-content {
    padding: 20px;
  }
}
