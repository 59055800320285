.qb-modal-wrapper {
  position: relative;
}

.qb-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 50;
}

.qb-modal-content-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 51;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.qb-modal-content {
  background-color: white;
  border-radius: 0.75rem;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  padding: 1.75rem;
  margin: 1rem;
  max-width: 425px;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.qb-modal-title {
  color: #111827;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.qb-modal-description {
  color: #4b5563;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 1.5rem;
}

.qb-modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid #f3f4f6;
}
.integration-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-start; /* Ensures buttons remain aligned */
}

@media (max-width: 640px) {
  .integration-container {
    flex-direction: column;
    align-items: stretch;
  }
}

.disconnect-btn {
  margin-left: 6px; /* Maintain horizontal spacing */
  font-size: 0.875rem; /* Consistent text size */
  color: #4b5563; /* Neutral grey for secondary actions */
  background: none; /* No background for a clean, minimal look */
  border: none; /* No border for simplicity */
  cursor: pointer; /* Pointer cursor for interactivity */
  text-decoration: none; /* Remove underline */
  padding: 0.25rem 0.5rem; /* Add subtle padding for a larger click area */
  border-radius: 0.25rem; /* Optional: Slight rounding for a polished look */
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out; /* Smooth hover transition */
}

.disconnect-btn:hover {
  color: #d14343; /* Red color on hover for emphasis */
  background-color: #fef2f2; /* Add light red background on hover */
}

/* Button Styles */
.share-to-qb-btn {
  display: inline-flex; /* Keep inline-flex for better icon and text alignment */
  align-items: center; /* Center icon and text vertically */
  justify-content: flex-start; /* Align content to the left */
  padding: 0.5rem 1rem; /* Slightly increase padding for better click area */
  border-radius: 0.375rem; /* Keep the rounded look consistent */
  font-size: 0.875rem; /* Retain small font size for readability */
  font-weight: 500; /* Maintain boldness for emphasis */
  color: #4b5563; /* Neutral text color */
  cursor: pointer; /* Pointer cursor for interactivity */
  max-width: 100%; /* Allow the button to adapt to the container */
  transition: all 0.2s ease-in-out; /* Keep the smooth transition */
  background-color: #ffffff; /* Change to white for better contrast */
  border: 1px solid #e5e7eb; /* Keep border for structure */
  text-align: left; /* Ensure left alignment of text */
  text-decoration: none; /* Remove underline */
}

.share-to-qb-btn:hover {
  background-color: #f3f4f6; /* Subtle hover effect */
  color: #1f2937; /* Slightly darker text on hover */
}

.share-to-qb-btn i {
  margin-right: 0.5rem; /* Space between icon and text */
  font-size: 1rem; /* Ensure icon size is proportional to text */
}

/* Share state styles */
.share-to-qb-btn.share-state {
  display: inline-flex; /* Aligns icon and text consistently */
  align-items: center; /* Centers icon and text vertically */
  justify-content: flex-start; /* Ensures everything is left-aligned */
  padding: 0.25rem 0.75rem; /* Keep smaller padding for compactness */
  font-size: 0.875rem; /* Maintain consistent text size */
  font-weight: 500; /* Bold text for emphasis */
  color: #0066cc; /* Stronger blue to match active state styling */
  background-color: transparent; /* Transparent background */
  border: 1px solid #0066cc; /* Blue border for active state visibility */
  white-space: nowrap; /* Prevents wrapping */
  max-width: 100%; /* Allows flexibility in container */
  cursor: pointer; /* Pointer cursor for interactivity */
  transition: all 0.2s ease-in-out; /* Smooth transitions */
}

.share-to-qb-btn.share-state:hover {
  background-color: #e6f4ff; /* Subtle blue background on hover */
  border-color: #005bb5; /* Darker blue border on hover */
  color: #005bb5; /* Darker blue text on hover */
}

.share-to-qb-btn.share-state i {
  margin-right: 0.5rem; /* Consistent spacing between icon and text */
  font-size: 1rem; /* Proportional icon size */
}

.share-to-qb-btn.disabled {
  background-color: #e5e7eb;
  color: #9ca3af;
  border-color: transparent;
  cursor: not-allowed;
  transform: none;
}

.share-to-qb-btn:active {
  transform: scale(0.98);
}

.submitted-status {
  font-size: 0.875rem; /* Smaller font size */
  padding: 0.25rem 0.5rem; /* Reduce padding for a label-like feel */
  background-color: #d4edda; /* Light green background for subtle effect */
  color: #155724; /* Dark green text for contrast */
  border: 1px solid #c3e6cb; /* Add a border for clarity */
  border-radius: 0.25rem;
  display: inline-block; /* Change from button-like to a tag/label */
}

.processing-icon {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
