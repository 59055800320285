/* ExpenseCategoryManagementModal.css */
.expense-cat-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(3px);
}

.expense-cat-modal {
  background: #fff;
  width: 600px;
  max-width: 90%;
  padding: 24px;
  border-radius: 12px;
  position: relative;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  max-height: 85vh;
  overflow-y: auto;
}

.expense-cat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.expense-cat-header h3 {
  font-size: 20px;
  margin: 0;
  color: #333;
}

.expense-cat-close-button {
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 0;
}

.expense-cat-close-button:hover {
  background-color: #f1f5f9;
  color: #333;
}

.expense-cat-info-banner {
  background-color: #f0f9ff;
  border-left: 4px solid #0ea5e9;
  padding: 12px;
  margin-bottom: 20px;
  border-radius: 4px;
}

.expense-cat-info-banner p {
  margin: 0;
  font-size: 14px;
  color: #0c4a6e;
}

.expense-cat-body {
  margin-bottom: 20px;
}

.expense-cat-group {
  margin-bottom: 24px;
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 16px;
}

.expense-cat-group h4 {
  margin: 0 0 12px 0;
  font-size: 16px;
  color: #4285f4;
  display: flex;
  align-items: center;
}

.expense-cat-count {
  font-size: 14px;
  color: #64748b;
  font-weight: normal;
  margin-left: 6px;
}

.expense-cat-group ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.expense-cat-group ul li {
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease;
}

.expense-cat-group ul li:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.expense-cat-item {
  display: flex;
  justify-content: space-between;
  padding: 12px;
}

.expense-cat-details {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.expense-cat-name {
  font-weight: 500;
  color: #333;
  margin-bottom: 4px;
}

.expense-cat-instructions {
  font-size: 13px;
  color: #666;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.expense-cat-actions {
  display: flex;
  gap: 8px;
  align-items: center;
}

.expense-cat-edit-button {
  background: none;
  border: 1px solid #4285f4;
  color: #4285f4;
  cursor: pointer;
  font-size: 13px;
  padding: 4px 10px;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.expense-cat-edit-button:hover {
  background-color: #4285f4;
  color: white;
}

.expense-cat-delete-button {
  background: none;
  border: 1px solid #e53e3e;
  color: #e53e3e;
  cursor: pointer;
  font-size: 13px;
  padding: 4px 10px;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.expense-cat-delete-button:hover {
  background-color: #e53e3e;
  color: white;
}

.expense-cat-edit-form {
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.expense-cat-edit-row {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.expense-cat-edit-row label {
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

.expense-cat-edit-row input,
.expense-cat-edit-row textarea {
  padding: 8px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 14px;
  transition: border-color 0.2s ease;
}

.expense-cat-edit-row textarea {
  min-height: 60px;
  resize: vertical;
}

.expense-cat-edit-row input:focus,
.expense-cat-edit-row textarea:focus {
  border-color: #4285f4;
  outline: none;
  box-shadow: 0 0 0 2px rgba(66, 133, 244, 0.2);
}

.expense-cat-char-count {
  text-align: right;
  font-size: 12px;
  color: #64748b;
  margin-top: 4px;
}

.expense-cat-required-hint {
  font-size: 12px;
  color: #64748b;
  font-style: italic;
}

.expense-cat-edit-actions {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.expense-cat-save-edit-button,
.expense-cat-cancel-edit-button {
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.expense-cat-save-edit-button {
  background-color: #4285f4;
  color: white;
  border: none;
}

.expense-cat-save-edit-button:hover {
  background-color: #3367d6;
}

.expense-cat-cancel-edit-button {
  background-color: #f1f5f9;
  color: #64748b;
  border: none;
}

.expense-cat-cancel-edit-button:hover {
  background-color: #e2e8f0;
}

.expense-cat-add-section {
  margin-top: 24px;
}

.expense-cat-add-section h4 {
  margin: 0 0 12px 0;
  font-size: 16px;
  color: #333;
}

.expense-cat-add-form {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.expense-cat-input-group {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.expense-cat-input-group label {
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

.expense-cat-input-group input,
.expense-cat-input-group textarea {
  padding: 10px 12px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  font-size: 14px;
  transition: border-color 0.2s ease;
}

.expense-cat-input-group textarea {
  min-height: 80px;
  resize: vertical;
}

.expense-cat-input-group input:focus,
.expense-cat-input-group textarea:focus {
  border-color: #4285f4;
  outline: none;
  box-shadow: 0 0 0 2px rgba(66, 133, 244, 0.2);
}

.expense-cat-add-button {
  padding: 10px 16px;
  border: none;
  background-color: #4285f4;
  color: white;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
  align-self: flex-start;
}

.expense-cat-add-button:hover {
  background-color: #3367d6;
  transform: translateY(-1px);
}

.expense-cat-add-button:disabled {
  background-color: #a0aec0;
  cursor: not-allowed;
  transform: none;
}

.expense-cat-footer {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  border-top: 1px solid #f1f5f9;
  padding-top: 20px;
}

.expense-cat-save-button,
.expense-cat-cancel-button {
  padding: 10px 20px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
}

.expense-cat-save-button {
  background-color: #0066ff;
  color: white;
}

.expense-cat-save-button:hover {
  background-color: #0052cc;
}

.expense-cat-save-button:disabled {
  background-color: #a0aec0;
  cursor: not-allowed;
}

.expense-cat-cancel-button {
  background-color: #f1f5f9;
  color: #64748b;
}

.expense-cat-cancel-button:hover {
  background-color: #e2e8f0;
}

.expense-cat-cancel-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.expense-cat-loading-indicator {
  text-align: center;
  padding: 20px;
  color: #64748b;
}

.expense-cat-empty-message {
  color: #64748b;
  text-align: center;
  padding: 10px;
  font-style: italic;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .expense-cat-modal {
    padding: 16px;
  }

  .expense-cat-footer {
    flex-direction: column;
  }

  .expense-cat-save-button,
  .expense-cat-cancel-button {
    width: 100%;
  }
}
