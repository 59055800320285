.business-purpose-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  overflow-y: auto;
  padding: 15px;
}

.business-purpose-modal-wrapper {
  width: 100%;
  max-width: 600px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.business-purpose-modal-header {
  padding: 16px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  background-color: #f8f9fa;
}

.business-purpose-modal-title {
  margin: 0;
  font-size: 18px;
  color: #333;
  display: flex;
  align-items: center;
  gap: 8px;
}

.business-purpose-modal-title i {
  color: #0033a0;
}

.business-purpose-modal-close {
  background: none;
  border: none;
  font-size: 16px;
  color: #666;
  cursor: pointer;
}

.business-purpose-modal-close:hover {
  color: #333;
}

.business-purpose-modal-content {
  padding: 20px;
  overflow-y: auto;
}

.business-purpose-alert {
  background-color: #fee;
  color: #c00;
  padding: 10px 15px;
  border-radius: 4px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.business-purpose-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.business-purpose-form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.business-purpose-label {
  font-weight: 600;
  color: #333;
  font-size: 14px;
}

.business-purpose-textarea {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px 12px;
  font-size: 14px;
  resize: vertical;
  min-height: 100px;
  font-family: inherit;
}

.business-purpose-textarea:focus {
  outline: none;
  border-color: #0033a0;
  box-shadow: 0 0 0 2px rgba(0, 51, 160, 0.1);
}

.business-purpose-hint {
  font-size: 12px;
  color: #666;
  margin-top: 4px;
}

.business-purpose-actions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 10px;
}

.business-purpose-button {
  padding: 10px 16px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  border: none;
  transition: all 0.2s;
}

.business-purpose-button-secondary {
  background-color: #f1f3f5;
  color: #495057;
}

.business-purpose-button-secondary:hover {
  background-color: #e9ecef;
}

.business-purpose-button-primary {
  background-color: #0033a0;
  color: white;
}

.business-purpose-button-primary:hover {
  background-color: #00287e;
}

.business-purpose-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.business-purpose-button-loading {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .business-purpose-modal-wrapper {
    width: 100%;
    max-height: 80vh;
  }

  .business-purpose-actions {
    flex-direction: column;
  }

  .business-purpose-button {
    width: 100%;
  }
}

/* Add these to your BusinessPurposeModal.css since we're reusing that styling */

.expense-details {
  background-color: #f8f9fa;
  border-radius: 6px;
  padding: 12px;
  margin-bottom: 16px;
}

.expense-detail-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.expense-detail-item:last-child {
  margin-bottom: 0;
}

.detail-label {
  font-weight: 600;
  color: #495057;
}

.detail-value {
  color: #212529;
}

/* Style for buttons that trigger overrides */
.action-button[data-action-type="override"] {
  background-color: #fd7e14;
  border-color: #e8700f;
}

.action-button[data-action-type="override"]:hover {
  background-color: #e8700f;
  border-color: #d56308;
}
