.pricing-page-container {
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center content vertically */
  min-height: 100vh; /* Full viewport height */
  padding: 20px;
  background-color: #f9f9f9; /* Light background */
}

.pricing-page-title {
  margin-bottom: 20px;
  color: #0033a0;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
}

stripe-pricing-table {
  width: 100%; /* Take full width of the container */
  max-width: 800px; /* Restrict max width */
  margin: 0 auto; /* Center the table horizontally */
  padding: 16px; /* Add some padding around the table */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}

.back-button {
  background-color: #0033a0; /* Blue background */
  color: #ffffff; /* White text */
  border: none;
  border-radius: 8px;
  padding: 10px 16px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 20px;
  align-self: flex-start; /* Align button to the left */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: #002080; /* Darker blue on hover */
}

.back-button:focus {
  outline: 2px solid #ffd700; /* Focus outline for accessibility */
}

@media (max-width: 768px) {
  .pricing-page-container {
    padding: 10px; /* Reduce padding on smaller screens */
    justify-content: flex-start; /* Start content at the top */
  }

  .pricing-page-title {
    font-size: 1.5rem; /* Slightly smaller font size */
    margin-bottom: 16px; /* Reduce spacing */
  }

  stripe-pricing-table {
    padding: 12px; /* Adjust padding */
    box-shadow: none; /* Remove shadow for simpler design */
  }
}
.currency-switcher {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  gap: 10px;
}

.currency-switcher button {
  padding: 8px 16px;
  border: 1px solid #ccc;
  background: #f5f5f5;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.currency-switcher button.active {
  background: #0033a0;
  color: white;
  border-color: #0033a0;
}
