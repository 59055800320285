/* Container Styles */
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 15px;
  border-radius: 10px;
  text-align: left;
  min-height: fit-content; /* Instead of fixed height */
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
}

.profile {
  display: flex;
  align-items: flex-start; /* Align items at the top */
  justify-content: space-between; /* Pushes the .profile-info to the right */
  margin-bottom: 30px;
}

.profile-info {
  display: flex;
  justify-content: flex-end; /* Right-align the buttons */
  align-items: flex-start; /* Align items at the top */
  gap: 10px; /* Add some space between buttons */
  position: relative; /* Allows the text to be positioned independently */
}

.subscription-status {
  display: flex;
  flex-direction: column;
  align-items: flex-end; /* Right-align the text */
}

.subscription-status .checkout-container {
  display: flex;
  flex-direction: column; /* Stack button and text vertically */
  align-items: flex-start; /* Align to the left */
  margin-top: 8px;
}

.profile img {
  border-radius: 50%;
  width: 48px;
  height: 48px;
  margin-right: 16px;
}

.checkoutbutton {
  background-color: #ffd700 !important;
  color: #0033a0 !important;
  margin-bottom: 0;
}

.important-info {
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 15px;
  background-color: #f0f8ff;
  color: #0033a0;
  border-left: 4px solid #ffd700;
  border-radius: 8px;
  font-size: 16px;
}

.important-info i {
  margin-right: 10px;
}

.checkout-text {
  margin-top: 8px;
  color: #0033a0;
  text-align: left;
  padding-left: 10px;
}

/* Logout Button Styles */
.logout-link {
  cursor: pointer;
  color: white;
  background-color: #0033a0;
  border: none;
  padding: 0.4rem 1rem;
  font-size: 0.9rem;
  font-weight: bold;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  display: inline-flex;
  align-items: center;
}

.logout-link:hover {
  background-color: #002080;
  transform: scale(1.05);
}

.faq-link-container {
  margin-top: 15px;
}

.faq-link {
  color: #0033a0; /* Professional blue for text */
  font-size: 1rem; /* Slightly larger for readability */
  text-decoration: underline; /* Standard hyperlink look */
  font-family: "Poppins", sans-serif; /* Modern and clean font */
  padding: 0; /* Remove background padding for a simple look */
  background-color: transparent; /* No background color */
  border-radius: 0; /* No rounded corners */
  transition: color 0.3s ease, text-decoration-color 0.3s ease; /* Smooth hover effect */
}

.faq-link:hover {
  color: #002280; /* Darker blue on hover */
  text-decoration-color: #002280; /* Subtle underline color change */
}

/* Info Container Styles */
.info-container {
  max-width: 800px;
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: fit-content;
  height: auto;
}

.info {
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 15px;
  padding-top: 10px;
  display: flex;
  /*  justify-content: center;  Centers the info-box horizontally */
  width: 100%;
}

.info p {
  flex: 1 1 100%;
  margin: 0;
  padding: 0 8px;
  word-wrap: break-word;
}

.message {
  margin-top: 8px;
  color: #0033a0;
}

/* Button Styles */
.buttons {
  display: flex;
  justify-content: center; /* Centers the button horizontally */
  margin-top: 15px;
  gap: 8px;
}

.button,
.main-button {
  width: 70%; /* Adjusts the container's width */
  max-width: 600px; /* Sets a maximum width for larger screens */
  min-width: 300px; /* Sets a minimum width for smaller screens */
  text-align: center;
  color: #0033a0;
  border: none;
  transition: background-color 0.3s ease, transform 0.3s ease;
  padding: 18px 36px; /* Increase padding for better visibility */
  font-size: 18px;
  border-radius: 8px;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
}

.main-button {
  background-color: #ffd700;
}

.main-button:hover {
  background-color: #b8860b;
  transform: translateY(-2px);
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border-left-color: #0033a0;
  animation: spin 1s linear infinite;
  margin-left: 8px;
}

@keyframes fadeInOut {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.message-text {
  display: inline-block;
  margin-left: 10px;
}

.processing-message {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Change to left-justify the text */
  gap: 6px;
  color: #0033a0;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  height: 100px; /* Fixed height based on the longest message */
  text-align: left; /* Ensures text aligns to the left */
  overflow: hidden; /* Prevents overflow if the text is too long */
  padding-left: 10px; /* Optional: Adds some padding for spacing */
}

.summary-message {
  font-size: 16px;
  color: #0033a0;
  font-weight: 500;
  line-height: 1.5;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.button {
  background-color: #0033a0;
  color: white;
}

.button:hover {
  background-color: #001f5b;
  transform: translateY(-2px);
}

/* Section Styles */
.tuning-section,
.actions-section {
  margin-top: 8px;
  padding: 10px 15px;
  background-color: #fff8e1; /* Soft, pale yellow to match secondary design */
  border-radius: 8px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1); /* Light shadow for subtle depth */
}

.tuning-section h2,
.actions-section h2 {
  font-size: 1rem;
  margin-bottom: 5px;
  color: #0033a0; /* Blue text for consistency */
  font-weight: 600;
  cursor: pointer; /* Indicate interactivity */
  transition: color 0.2s ease;
}

.tuning-section h2:hover,
.actions-section h2:hover {
  color: #001f5b; /* Darker blue on hover */
}

.tuning-item {
  margin: 12px 0;
  color: #333; /* Neutral text color for descriptions */
}

.tuning-item h3 {
  font-size: 0.95rem; /* Slightly smaller font for subtlety */
  color: #0033a0;
  font-weight: 500;
}

.tuning-item p {
  font-size: 0.9rem;
  color: #666; /* Slightly muted text */
}

.link-button {
  background-color: #f0f0f0; /* Softer, neutral background color */
  color: #0033a0; /* Darker, subtle text color */
  border: 1px solid #ccc; /* Light border to define the button's edges */
  padding: 8px 12px; /* Ensure adequate padding */
  cursor: pointer;
  border-radius: 6px; /* Round the button corners */
  transition: background-color 0.3s ease;
}

/* Ensure no underline inside the button */
.link-button span {
  text-decoration: none !important;
  color: inherit;
}

.link-button:hover {
  background-color: #e0e0e0;
}

.info-box {
  background-color: #f5f7fa;
  border: 1px solid #d0d7de;
  border-radius: 8px;
  padding: 20px;
  margin: 20px 0;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 75%;
  max-width: 600px;
  min-width: 300px;
  animation: fadeInOut 1s ease-in-out;
}

.processing-message,
.summary-message {
  font-size: 16px;
  color: #0033a0;
  font-weight: 500;
  line-height: 1.5;
}

.subscription-status p {
  margin: 8px 0 0;
  font-size: 0.9rem;
  color: #0033a0;
  position: absolute; /* Removes it from the normal flow */
  top: 100%; /* Places it directly below the subscription button */
  right: 0; /* Aligns it to the right */
  white-space: nowrap; /* Prevents text from wrapping */
}

.sync-info-secondary {
  padding: 15px;
  background-color: #fff8e1;
  border-left: 4px solid #0033a0;
  margin: 15px 0px 15px 0px;
  border-radius: 8px;
}

.sync-info {
  /*  background-color: #f0f8ff; /* Light, soft background */
  padding: 5px;
  border-left: 2px solid #0033a0; /* Accent border */
  margin: 15px 0px 15px 0px;
  border-radius: 8px;
  color: #0033a0; /* Consistent text color */
  font-size: 0.8rem; /* Adjust font size for prominence */
  font-family: "Poppins", sans-serif; /* Match font family */
}

.summary-message2 {
  font-size: 1.2rem; /* Slightly larger for clarity */
  margin-bottom: 10px; /* Add space between title and list */
}

.summary-list {
  list-style-type: disc; /* Adds bullet points */
  padding-left: 20px;
  margin-top: 8px;
  color: #0033a0; /* Matching the primary text color */
}

.summary-list li {
  margin-bottom: 8px; /* Space between each list item */
  font-size: 1.1rem; /* Slightly smaller font for better readability */
}

.summary-list li::marker {
  color: #ffd700; /* Golden marker color for emphasis */
}

.sync-info p strong {
  font-size: 1.1rem; /* Highlight the 'You're all set!' */
  color: #0033a0; /* Maintain the same consistent text color */
}

.collapsible-content {
  padding: 0px 0;
}
.separator {
  background-color: #d0d7de;
  height: 1px;
  margin: 24px 0 24px 0;
  width: 100%;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Content Styles */
.modal-content {
  background-color: #fff;
  padding: 15px; /* Reduce padding */
  border-radius: 10px;
  max-width: 90%;
  width: 350px; /* Slightly smaller width */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  text-align: center;
  font-size: 0.9rem; /* Reduce font size */
}

/* Modal Actions Styles */
.modal-actions {
  display: flex;
  justify-content: center; /* Center buttons on desktop */
  gap: 16px; /* Add padding between buttons */
  margin-top: 15px;
}

.modal-actions button {
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  background-color: #0033a0;
  color: white;
  cursor: pointer;
  font-size: 0.9rem;
  width: 45%;
}

.modal-actions button:hover {
  background-color: #002080;
}

/* Mobile Adjustments */
@media (max-width: 768px) {
  .subscription-status button {
  }

  .subscription-status p {
    font-size: 0.8rem;
  }
}

.notification {
  background-color: #e7f3fe;
  border-left: 6px solid #0033a0;
  margin: 8px 0;
  padding: 8px 12px;
  font-size: 13px;
}

.notification h4 {
  margin: 0;
  font-size: 14px;
}

/* Completion Info Section */

.info-section {
  text-align: left;
}

.completion-info {
  padding: 20px;
  background-color: #f0f8ff;
  border-radius: 8px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  text-align: center;
}

.completion-title {
  font-size: 1.6rem;
  font-weight: bold;
  color: #0033a0;
  margin-bottom: 10px;
}

.completion-subtitle {
  font-size: 1.1rem;
  color: #0033a0;
  margin-bottom: 20px;
}

.steps-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.step-list {
  list-style: none;
  padding: 0;
  margin-bottom: 30px;
}
.step {
  display: flex;
  align-items: flex-start;
  gap: 15px;
}

.step-icon {
  font-size: 2rem;
}
.step-item {
  font-size: 16px;
  margin-bottom: 15px;
  display: flex;
  align-items: flex-start;
}

.step-item i {
  margin-right: 10px;
  color: #0033a0;
}

.step-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.step-description {
  font-size: 18px;
  margin-bottom: 20px;
}

.callout-box {
  background-color: #fff8e1;
  border-left: 4px solid #ffd700;
  padding: 15px;
  margin-top: 20px;
  font-size: 1rem;
  color: #333;
  border-radius: 6px;
  text-align: center;
}

/* Footer */

footerpi {
  margin-top: 30px;
  padding: 20px;
  text-align: center;
  background-color: #f0f0f0;
  color: #888;
  font-size: 14px;
  flex-shrink: 0;
}

.footer-contentpi {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-textpi {
  padding-right: 20px;
}

.footer-logopi {
  height: 30px;
  margin-right: 10px;
}

/* Submenu styles */
.submenu {
  display: flex;
  justify-content: space-between; /* This will push the right menu to the far right */
  align-items: center;
  margin: 20px 0 20px 5px;
  gap: 10px;
}

.left-menu {
  display: flex;
  gap: 10px;
}

.right-menu {
  margin-left: auto; /* Ensures it stays on the far right */
}

/* Style specifically for the settings icon button */
.right-menu .menu-link {
  color: #0033a0;
  font-size: 18px;
  padding: 8px;
  border-radius: 4px;
  transition: background-color 0.2s ease;
}

.right-menu .menu-link:hover {
  background-color: rgba(0, 51, 160, 0.1);
}

.right-menu .menu-link.active {
  background-color: rgba(0, 51, 160, 0.15);
}

/* Ensure icon is properly sized */
.right-menu .menu-link i {
  font-size: 20px;
}

.grid-item {
  display: flex;
  align-items: center; /* Align items vertically */
  gap: 12px; /* Add space between label and toggle */
}

.grid-item:last-child {
  margin-bottom: 0;
}

.grid-item span {
  font-size: 0.95rem;
  color: #0033a0;
  font-weight: 500;
}

/* Reset Button */
.link-button.reset-button {
  padding: 8px 16px;
  color: #dc2626;
  background-color: #fef2f2;
  border: 1px solid #fca5a5;
  border-radius: 6px;
  font-size: 0.875rem;
  transition: all 0.2s ease;
  width: 100%;
  text-align: center;
}

.link-button.reset-button:hover:not(:disabled) {
  background-color: #fee2e2;
}

.menu-link {
  background: none;
  border: none;
  color: #007bff;
  font-size: 18px;
  padding: 0;
  cursor: pointer;
  text-decoration: underline;
  transition: color 0.3s ease;
  white-space: nowrap; /* Prevent wrapping */
}

.menu-link.active {
  font-weight: bold;
  color: #0056b3;
}

.menu-link:hover {
  color: #0056b3;
}

.menu-button {
  background-color: #f0f0f0;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin: 0 5px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.menu-button:hover {
  background-color: #e0e0e0;
}

.menu-button.active {
  background-color: #0033a0;
  color: white;
}

/* Main Section */
.summary-section {
  display: grid;
  grid-template-columns: 1fr 1fr auto; /* 1fr for first column, auto for second and third */
  gap: 10px; /* Adjusted gap between items */
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

/* Individual Items */
.summary-item {
  display: contents; /* Let grid control the layout */
}

/* Label (e.g., "Total Receipts:") */
.label {
  grid-column: 1; /* First column */
  font-size: 16px;
  font-weight: 400;
  padding-right: 10px; /* Remove excessive right padding */
  margin: 0;
  display: flex;
  align-items: center;
}

/* Value (e.g., numbers like 7, 10, etc.) */
.value {
  grid-column: 2; /* Second column */
  font-size: 16px;
  text-align: left; /* Align value closer to the label */
  margin: 0;
  display: flex;
  align-items: center;
  padding-left: 5px; /* Add slight padding to group it visually with the label */
}
@media (max-width: 768px) {
  .value {
    min-width: 50px;
  }
}

/* Buttons */
.text-button {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  padding: 5px;
  font-size: 14px;
  text-decoration: underline;
}

.text-button:hover {
  color: #0056b3;
}

.reset-button {
  color: red;
}

.reset-button:hover {
  color: darkred;
}
.left-aligned {
  text-align: left;
}

/* Consolidated Styles */
.summary-section,
.summary-item {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.6;
}

.text-button,
.link-button {
  background: none;
  border: none;
  color: #007bff;
  padding: 0;
  text-decoration: underline;
  cursor: pointer;
  font-size: 14px;
}

.text-button:hover,
.link-button:hover {
  color: #0056b3;
}
/* Button Group Styles */
.button-group,
.text-button {
  grid-column: 3; /* Third column */
  display: flex;
  gap: 10px; /* Space between buttons */
}

/* Button Group */
.button-group {
  grid-column: 3; /* Third column */
  display: flex;
  gap: 10px; /* Space between buttons */
  justify-content: flex-end; /* Ensure buttons are right-aligned */
  flex-shrink: 0; /* Prevent buttons from shrinking */
}

.button-group .link-button {
  padding: 8px 12px;
  background-color: #ffd700;
  color: #0033a0;
  border-radius: 5px;
}

.button-group .link-button:hover {
  background-color: #b8860b;
}

/* Wrapping the menu contents */
.menu-content-container {
  background-color: #f8fafc;
  text-align: left;
  padding: 24px; /* Increased padding */
  max-width: 1200px; /* Increased max-width for better content display */
  margin: 0 auto; /* Center the container */
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media (max-width: 600px) {
  .submenu {
    flex-direction: row;
    flex-wrap: nowrap; /* Prevents wrapping */
    align-items: center;
  }

  .menu-link {
    margin-bottom: 0;
    font-size: 14px;
  }

  .modal-content {
    width: 85%;
    padding: 12px;
  }

  .processing-message {
    font-size: 14px;
    height: 100px;
  }

  .modal-actions {
    flex-direction: column;
    gap: 10px;
  }

  .modal-actions button {
    width: 100%;
    margin: 0 auto;
  }

  .container {
    padding: 10px;
  }

  .tuning-item h3 {
    font-size: 15px;
  }

  .tuning-item p {
    font-size: 13px;
  }

  .button,
  .main-button {
    font-size: 14px;
    padding: 10px;
    width: 85%;
  }

  .link-button {
    font-size: 0.85em;
    padding: 8px;
  }

  .menu-content-container {
    padding: 10px;
  }

  .grid-container {
    grid-template-columns: 1fr; /* Make label and button stack vertically on small screens */
  }

  .grid-item {
    flex-direction: column;
    align-items: flex-start;
  }
}
/* Add new styles for loading state */
.loading-row {
  grid-column: 1 / -1; /* Span full width of grid */
  padding: 8px 0;
}

.loading-row {
  grid-column: 1 / -1; /* Span both columns */
  padding: 8px 0;
}

.loading-text {
  display: grid;
  grid-template-columns: 24px 1fr; /* Fixed width for icon, rest for text */
  align-items: center; /* <--- changed this from 'start' */
  gap: 10px;
  font-size: 0.9rem;
  color: #0033a0;
}

.info-icon {
  justify-self: center;
  font-size: 16px;
}

.text-content {
  line-height: 1.4;
}

/* @media (max-width: 768px) {
  .loading-text {
    display: inline-flex;
    align-items: center;
    gap: 10px;
  }
} */

@media (max-width: 768px) {
  .info-container {
    max-width: 100%;
    padding: 10px;
    margin: 0;
    border-radius: 0;
    background-color: transparent; /* Make it transparent since parent has the grey background */
  }

  .step-title {
    font-size: 22px;
  }

  .step-description {
    font-size: 16px;
  }

  .step-item {
    font-size: 14px;
  }

  .sync-info-secondary {
    font-size: 14px;
  }

  .important-info {
    font-size: 14px;
  }

  /* Ensure the button group is right-aligned on mobile */
  .button-group {
    justify-content: flex-end; /* Ensure buttons stay on the same row */
    flex-wrap: nowrap; /* Prevent buttons from wrapping to the next line */
  }

  .link-button,
  .text-button {
    font-size: 0.85em;
  }

  .summary-item p {
    font-size: 1rem;
  }
  .summary-section {
    display: grid;
    grid-template-columns: 1fr auto auto; /* Match the three-column layout from Expense Accounts */
    gap: 10px;
    width: 100%;
  }

  .summary-item {
    display: contents; /* This allows child elements to participate in grid layout */
  }

  .label {
    grid-column: 1;
    font-size: 14px;
  }

  .value {
    grid-column: 2;
    font-size: 14px;
    text-align: right; /* Align numbers consistently */
    min-width: 50px; /* Ensure consistent space for numbers */
  }

  .text-button {
    grid-column: 3;
    white-space: nowrap;
  }
}
.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr; /* First column for labels, second for buttons */
  gap: 15px; /* Gap between the label and the button */
  align-items: center; /* Vertically align items */
}

.grid-item {
  display: contents; /* Ensures each label and button are direct grid items */
}

.grid-item h3 {
  text-align: left; /* Left-align label */
  margin: 0; /* Remove any default margin from h3 */
}

.grid-item button {
  text-align: left; /* Left-align button text */
  justify-self: start; /* Ensure the button aligns to the left */
}

.grid-item span {
  font-size: 1rem; /* Adjust font size */
  color: #0033a0; /* Ensure text color consistency */
}

/* Container for the report section */
.summary-report-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f7f9fc;
  border-radius: 12px;
  text-align: left;
  box-sizing: border-box;
  border: 1px solid #dfe4ea; /* Light border for visual separation */
}

/* Grid layout for summary report sections */
.summary-report-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  align-items: center;
  margin-bottom: 30px;
}

/* Grid item styling */
.summary-report-item {
  padding: 15px;
  background: #ffffff;
  border: 1px solid #d1d5da;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* Styling for headers */
.summary-report-header {
  font-size: 1.2rem;
  font-weight: bold;
  color: #0033a0;
  margin-bottom: 8px;
}

/* Subheader styling */
.summary-report-subheader {
  font-size: 1rem;
  font-weight: 500;
  color: #666666;
  margin-bottom: 12px;
}

/* Section titles for grouping sections */
.summary-section-title {
  font-size: 1.3rem;
  font-weight: bold;
  color: #0033a0;
  margin-bottom: 10px;
  text-align: center;
  grid-column: span 2; /* Span two columns for full width on desktop */
}

/* Total value styling */
.summary-total-value {
  font-size: 1.1rem;
  font-weight: bold;
  color: #2c3e50;
  margin: 0;
}

/* Date picker container styling */
.date-picker-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: #f0f4f8;
  border: 1px solid #cfd8dc;
  border-radius: 8px;
  margin-bottom: 20px;
}

/* Date picker styling */
.date-picker-input {
  width: 150px;
  padding: 8px 10px;
  font-size: 0.9rem;
  border: 1px solid #cfd8dc;
  border-radius: 6px;
  background: #ffffff;
}

/* Mobile layout adjustments */
@media (max-width: 768px) {
  .summary-report-grid {
    grid-template-columns: 1fr; /* Single column layout */
  }
  .summary-section-title {
    font-size: 1.1rem;
    text-align: left;
    margin-bottom: 20px;
  }
  .date-picker-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px; /* Spacing between date pickers */
  }
  .date-picker-input {
    width: 100%; /* Full width input fields on mobile */
  }
}

/* Button styling */
.summary-report-button {
  background-color: #0033a0;
  color: #ffffff;
  padding: 12px 20px;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.summary-report-button:hover {
  background-color: #002080;
}

.summary-report-button:disabled {
  background-color: #cfd8dc;
  cursor: not-allowed;
}

/* Previously submitted reports section */
.submitted-reports-container {
  margin-top: 30px;
  border: 1px solid #dfe4ea;
  padding: 20px;
  border-radius: 12px;
  background-color: #ffffff;
}

.submitted-report-item {
  display: grid;
  grid-template-columns: 1fr auto; /* Description and value */
  gap: 10px;
  padding: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin-bottom: 10px;
  background-color: #f9f9f9;
}

.submitted-report-item p {
  margin: 0;
}

.submitted-report-value {
  font-size: 1.1rem;
  font-weight: bold;
  color: #2c3e50;
}

.submitted-report-buttons {
  display: flex;
  gap: 10px;
}

.submitted-report-buttons button {
  border: none;
  border-radius: 5px;
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submitted-report-buttons button:hover {
  background: #b8860b;
}

/* Ensuring visual consistency */
.submitted-report-buttons button:disabled {
  background: #d3d3d3;
  color: #ffffff;
  cursor: not-allowed;
}

/* Styling for "Total by Accounts" and "Total by Currency" sections */
.total-section {
  margin-top: 20px;
  padding: 15px;
  background-color: #ffffff;
  border: 1px solid #d1d5da;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.total-header {
  font-size: 1.2rem;
  font-weight: bold;
  color: #0033a0;
  margin-bottom: 12px;
}

.total-item {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 10px;
  padding: 10px 0;
  border-bottom: 1px solid #e0e0e0;
}

.total-item:last-child {
  border-bottom: none; /* Remove border for last item */
}

.total-label {
  font-size: 1rem;
  color: #666666;
  margin: 0;
}

.total-value {
  font-size: 1.1rem;
  font-weight: bold;
  color: #2c3e50;
}

/* Responsive layout adjustments */
@media (max-width: 600px) {
  .submitted-report-item {
    grid-template-columns: 1fr; /* Single column layout for mobile */
  }

  .submitted-report-buttons {
    flex-direction: column; /* Stack buttons vertically */
    gap: 5px;
  }

  .total-item {
    grid-template-columns: 1fr; /* Single column layout */
  }

  .total-value {
    text-align: right;
  }
}
.expense-reports-container {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 24px; /* Add space between containers */
  border: 1px solid #e2e8f0; /* Add subtle border */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05); /* Add subtle shadow */
}

/* Create visual separator between sections */
.expense-reports-container + .expense-reports-container {
  margin-top: 32px; /* Add extra space between consecutive containers */
}

/* Optional: Add different background color for Reports section */
.expense-reports-container:last-child {
  background-color: #eaf2f9; /* Slightly different background */
}

/* Header Section */
.expense-reports-header {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two equal columns */
  gap: 15px;
  align-items: center;
  margin-bottom: 20px;
}

.header-item {
  display: flex;
  flex-direction: column;
}

.header-label {
  font-size: 16px;
  color: #333;
  margin-bottom: 5px;
}

.header-value {
  font-size: 14px;
  color: #666;
}

/* Date Range Styling */
.date-range-inline {
  display: flex;
  align-items: center;
  gap: 10px;
}

.date-input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.date-separator {
  padding: 0 5px;
  font-size: 14px;
  color: #333;
}

/* Total Section */
.total-section {
  margin-bottom: 20px;
}

.total-header {
  font-size: 18px;
  color: #0033a0;
  margin-bottom: 10px;
}

.total-item {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal columns */
  gap: 15px;
  align-items: center;
}

.total-label,
.total-value {
  font-size: 16px;
}
/* Submit Review Section */
.submit-review-section {
  display: flex;
  align-items: center; /* Vertically center-align items */
  justify-content: space-between;
  margin-bottom: 30px;
}

.total-submission {
  display: flex;
  align-items: center; /* Ensure all child elements are aligned */
}

.total-submission-label,
.total-submission-value {
  font-size: 18px;
  line-height: 1.2; /* Control line height for better alignment */
  margin: 0; /* Remove margin */
  padding: 0; /* Remove padding */
}

/* Specific Adjustment for Submission Value */
.total-submission-value {
  padding-left: 10px; /* Add space between the label and the value */
}

/* Buttons */
.primary-button,
.secondary-button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.primary-button {
  background-color: #ffd700;
  color: #0033a0;
}

.primary-button:hover {
  background-color: #b8860b;
}

.secondary-button {
  background-color: #f0f0f0;
  color: #0033a0;
}

.secondary-button:hover {
  background-color: #e0e0e0;
}

/* Previously Submitted Reports */
.submitted-report-item {
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  gap: 15px;
  align-items: center;
  margin-bottom: 10px;
}

.submitted-report-label {
  font-size: 16px;
  color: #0033a0;
}

.submitted-report-value {
  font-size: 14px;
  color: #666;
}

.submitted-report-total {
  font-size: 16px;
  color: #333;
}

.submitted-report-buttons {
  display: flex;
  gap: 10px;
}

.loading-container {
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: #f0f7ff; /* Light blue background */
  padding: 16px;
  margin: 20px 0;
  border-radius: 8px;
  border-left: 4px solid #0033a0;
}

.loading-container p {
  color: #0033a0;
  font-size: 1rem;
  margin: 0;
}
@media (max-width: 768px) {
  .loading-container,
  .status-message {
    margin: 16px 0;
    padding: 12px;
  }
}
.status-message1 {
  background-color: #f0f7ff;
  color: #0033a0;
  padding: 16px;
  margin: 0px 20px 20px 20px;
  border-radius: 8px;
  border-left: 4px solid #0033a0;
  font-size: 0.9rem;
}

.status-message-link {
  color: #007bff; /* Noticeable blue color */
  text-decoration: underline; /* Underline to indicate it's clickable */
  font-weight: bold; /* Bold text */
  cursor: pointer; /* Pointer on hover */
  font-size: 0.85rem; /* Slightly smaller than the surrounding text */
  display: inline; /* Ensure the link stays inline */
  transition: color 0.2s ease, text-decoration 0.2s ease; /* Smooth transition for hover effects */
}

.status-message-link:hover {
  color: #0056b3; /* Darker blue on hover */
  text-decoration: none; /* Remove underline on hover */
}

.enhanced-link {
  color: #007bff; /* Define a noticeable blue color */
  text-decoration: underline; /* Adds underline to indicate it's clickable */
  font-weight: bold; /* Makes the link text bold */
  cursor: pointer; /* Changes the cursor to a pointer */
}

.enhanced-link:hover {
  color: #0056b3; /* Darker blue on hover */
  text-decoration: none; /* Removes underline on hover */
}

/* Date Range Selectors Styling */
.date-range-selectors {
  display: flex;
  align-items: center;
  gap: 16px;
}
.date-range-inline {
  display: flex;
  align-items: center;
  gap: 8px; /* Space between individual date elements */
}

/* Input Styling for Date */
.date-input {
  padding: 8px 12px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 0.9rem;
  color: #1a202c;
  background-color: #ffffff;
  transition: border-color 0.2s ease;
}
.date-input:focus {
  outline: none;
  border-color: #0033a0;
  box-shadow: 0 0 0 3px rgba(0, 51, 160, 0.1);
}

.button-width {
  width: 200px; /* Set a fixed width for consistency */
  max-width: 100%; /* Ensure it doesn't exceed container width */
  min-width: 150px; /* Optional: Set a minimum width */
}
/* Add these styles to your ProcessInfo.css */

.message-box {
  padding: 10px;
  margin-top: 20px; /* Add margin-top to create space from the submit button */
  border-radius: 5px;
  width: 100%; /* Make the message box take full width */
  box-sizing: border-box; /* Include padding in the element's total width */
  display: block; /* Ensure the message box takes a full line */
  clear: both; /* Prevent floating elements from affecting layout */
}

/* Styles for success messages */
.message-box.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

/* Styles for error messages */
.message-box.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

/* Add this for extra padding between button and message */
.submit-review-section {
  display: flex;
  flex-direction: column; /* Stack child elements vertically */
  align-items: flex-start; /* Align elements to the left */
}

.submit-review-section .message-box {
  margin-top: 15px; /* Add top margin specific to the message box */
}

/* Styles for the Previously Submitted Reports section */
.previously-submitted-container {
  margin-top: 30px;
}

.submitted-reports-list {
  list-style-type: none;
  padding: 0;
}

.submitted-report-item {
  margin-bottom: 10px;
}

.submitted-report-item a {
  color: #0033a0;
  text-decoration: none;
  font-weight: bold;
}

.submitted-report-item a:hover {
  text-decoration: underline;
}

.report-grid {
  display: grid;
  grid-template-columns: 1fr 3fr; /* Two columns with different widths */
  gap: 10px; /* Spacing between rows */
}

.report-link {
  text-align: left; /* Align link text to the left */
}

.report-link a {
  color: #0033a0; /* Optional: Color for the link */
  text-decoration: none; /* Remove underline */
}

.report-link a:hover {
  text-decoration: underline; /* Show underline on hover */
}

/* Base styles for both buttons */
.primary-button,
.secondary-button {
  display: flex;
  align-items: center; /* Align icon and text vertically centered */
  justify-content: flex-start; /* Align icon and text to the left */
  width: 100%; /* Full width for both buttons */
  max-width: 300px; /* Maximum button width */
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-sizing: border-box;
}

/* iCloud button styles */
.icloud-button {
  background-color: #f8f9fa; /* Brighter grey for better contrast */
  color: #007aff; /* iCloud blue */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Slight shadow for definition */
}

.icloud-button:hover {
  background-color: #dfe4ea; /* Light grey on hover */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15); /* Enhanced shadow for hover */
}
/* Add specific disabled states */
.primary-button:disabled,
.secondary-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
  background-color: #f5f5f5;
  box-shadow: none;
}

/* Icon styles */
.button-icon {
  display: inline-block;
  margin-right: 10px; /* Space between the icon and the text */
  width: 24px; /* Set icon width */
  height: 24px; /* Set icon height */
  background-size: contain;
  background-repeat: no-repeat;
}

/* Specific Google icon styling */
.google-icon {
  background-image: url("./google-icon.svg"); /* Replace with actual path to Google-like icon */
}

/* Specific iCloud icon styling */
.icloud-icon {
  background-image: url("./ICloud_logo.svg"); /* Replace with actual path to iCloud-like icon */
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .primary-button,
  .secondary-button {
    width: 100%; /* Ensure the buttons take up most of the width on smaller screens */
    /* margin: 5px auto;  Add space between buttons on smaller screens */
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 24px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #0033a0;
}

input:checked + .slider:before {
  transform: translateX(20px);
}

/* Report Grid Styles */
.report-grid {
  display: grid;
  gap: 16px;
  margin-top: 16px;
}

.report-details {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.report-link a {
  color: #0033a0;
  text-decoration: none;
  font-weight: 500;
}

.report-stats {
  display: flex;
  gap: 1.5rem;
  color: #4a5568;
  font-size: 0.875rem;
}

.receipt-count,
.functional-total {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

/* Notes Section Styles */
.report-notes {
  margin-top: 0.5rem;
}

.notes-editor {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.notes-textarea {
  width: 90%;
  min-height: 80px;
  padding: 0.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  resize: vertical;
  font-size: 0.875rem;
}

.notes-actions {
  display: flex;
  gap: 0.5rem;
}

.save-note,
.cancel-note,
.edit-note {
  padding: 0.375rem 0.75rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  cursor: pointer;
  border: none;
}

.save-note {
  background-color: #0033a0;
  color: white;
}

.cancel-note {
  background-color: #e2e8f0;
  color: #4a5568;
}

.edit-note {
  background-color: #f7fafc;
  border: 1px solid #e2e8f0;
  color: #4a5568;
}

/* Recipients Section Styles */
.report-recipients {
  font-size: 0.875rem;
  margin-top: 0.5rem;
}

.report-recipients summary {
  cursor: pointer;
  color: #4a5568;
  user-select: none;
}

.email-list {
  margin-top: 0.5rem;
  padding-left: 1rem;
}

.email-item {
  color: #718096;
  margin-bottom: 0.25rem;
}

.report-main {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  flex: 1;
}

.report-info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.report-actions {
  display: flex;
  gap: 0.5rem;
}

.recipients-tooltip {
  position: relative;
}

.recipients-tooltip:hover .tooltip-content {
  display: block;
}

.recipient {
  padding: 0.25rem 0;
  font-size: 0.875rem;
  color: #64748b;
}

.report-notes {
  padding: 1rem;
  border-top: 1px solid #e2e8f0;
  background-color: #f8fafc;
}

.notes-actions {
  display: flex;
  gap: 8px;
  margin-top: 12px;
}

.notes-actions button {
  padding: 0.375rem 0.75rem;
  border-radius: 4px;
  font-size: 0.875rem;
  cursor: pointer;
}

.save-button {
  background-color: #0033a0;
  color: white;
  border: none;
}

.cancel-button {
  background-color: white;
  border: 1px solid #e2e8f0;
  color: #64748b;
}

/* Mobile Responsive */
@media (max-width: 768px) {
  .report-main {
    flex-direction: column;
    align-items: flex-start;
  }

  .report-actions {
    width: 100%;
  }
}

/* Rest of your existing styles remain the same until action-wrapper */

.action-wrapper {
  position: relative;
  display: inline-block;
}

.recipient-count {
  background-color: #e2e8f0;
  padding: 0.125rem 0.375rem;
  border-radius: 9999px;
  font-size: 0.75rem;
}

/* Updated tooltip styles */
.tooltip-content {
  display: none;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: calc(100% + 5px);
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  padding: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
  min-width: 200px;
  white-space: nowrap;
}

.tooltip-header {
  font-weight: 500;
  padding-bottom: 0.25rem;
  margin-bottom: 0.25rem;
  border-bottom: 1px solid #e2e8f0;
}

.action-wrapper:hover .tooltip-content {
  display: block;
}

/* Notes modal */
.notes-modal {
  position: fixed; /* Change from absolute to fixed */
  top: 50%;
  left: 50%;
  transform: translate(
    -50%,
    -50%
  ); /* Center both vertically and horizontally */
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Ensure it's above other content */
  width: 90%; /* Make it responsive */
  max-width: 400px; /* Maximum width on larger screens */
  margin: 0 auto;
}

.notes-content {
  padding: 1rem;
}

/* Rest of your existing styles remain the same */

/* Mobile Responsive */
@media (max-width: 768px) {
  .notes-modal {
    width: 95%; /* Slightly wider on very small screens */
    max-width: none; /* Remove max-width constraint on mobile */
  }

  .notes-content {
    padding: 16px;
  }

  .notes-textarea {
    min-height: 120px; /* Ensure enough space to type on mobile */
  }

  .tooltip-content {
    left: auto;
    right: 0;
    transform: none;
  }
}

.recipient-count {
  background-color: #e2e8f0;
  padding: 0.125rem 0.375rem;
  border-radius: 9999px;
  font-size: 0.75rem;
}

/* Tooltip styles */
.action-wrapper {
  position: relative;
}

.tooltip-content {
  display: none;
  position: absolute;
  right: 0;
  top: calc(100% + 5px);
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  padding: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
  min-width: 200px;
}

.tooltip-header {
  font-weight: 500;
  padding-bottom: 0.25rem;
  margin-bottom: 0.25rem;
  border-bottom: 1px solid #e2e8f0;
}

.action-wrapper:hover .tooltip-content {
  display: block;
}

/* Notes modal */
.notes-modal {
  position: absolute;
  top: 0;
  right: -320px;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 20;
  width: 300px;
}

.notes-modal::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.notes-content {
  padding: 1rem;
  max-height: 80vh; /* Prevent modal from being taller than viewport */
  overflow-y: auto;
}

/* Mobile Responsive */
@media (max-width: 768px) {
  .notes-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    right: auto;
    width: 90%;
    max-width: 320px;
  }

  .tooltip-content {
    right: auto;
    left: 0;
  }
}

.report-row {
  grid-column: 1 / -1;
  background-color: #f9f9f9;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  padding: 20px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}
.report-row:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}
.report-primary-row {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.report-date {
  font-size: 0.875rem;
  color: #64748b;
  white-space: nowrap;
  min-width: 85px;
}

.report-title {
  flex: 1;
  min-width: 0;
}

.report-title a {
  color: #0033a0;
  text-decoration: none;
  font-weight: 500;
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.report-controls {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  white-space: nowrap;
}

.status-select {
  padding: 0.25rem 0.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  background-color: white;
  color: #1a202c;
  font-size: 0.75rem;
  cursor: pointer;
  max-width: 120px;
}

.action-buttons {
  display: flex;
  gap: 0.5rem;
}

.action-button {
  padding: 0.25rem 0.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  background-color: white;
  color: #64748b;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.15s ease;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.report-metrics {
  display: flex;
  gap: 2rem;
  margin-top: 0.5rem;
  margin-bottom: 0.75rem;
}

.metric {
  display: flex;
  align-items: baseline;
  gap: 0.5rem;
  min-width: 150px; /* Ensures consistent width for each metric */
}

.metric-value {
  font-weight: 600;
  color: #1a202c;
  font-size: 0.875rem;
}

.metric-label {
  font-size: 0.875rem;
  color: #64748b;
  min-width: 70px; /* Ensures labels align */
}

.quickbooks-section {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap on smaller screens */
  gap: 1rem;
  padding-top: 0.75rem;
  padding-bottom: 0.25rem;
  border-top: 1px solid #e2e8f0;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start; /* Handle error states better */
}

.quickbooks-section > div {
  flex: 1; /* Allow items to grow equally */
  max-width: 100%; /* Take full width on smaller screens */
}

.submitted-status {
  max-width: 100%; /* Take full width for mobile */
  text-align: center; /* Center-align for better balance */
}

.space-y-4 {
  width: 100%; /* Ensure full width for buttons on mobile */
}

.connect-to-quickbooks-button img {
  max-width: 100%; /* Ensure buttons are responsive */
  height: auto;
}

.share-to-qb-btn {
  width: 100%; /* Full-width buttons on mobile */
}

.quickbooks-section .submitted-status {
  max-width: 250px; /* Consistent width when in submitted state */
}

/* Handle validation error states */
.quickbooks-section .space-y-4 {
  width: 100%;
  max-width: 250px;
}

@media (min-width: 768px) {
  .quickbooks-section {
    flex-direction: row; /* Horizontal layout for tablets and above */
  }
}

@media (max-width: 767px) {
  .quickbooks-section {
    flex-direction: column; /* Stack items vertically on small screens */
    gap: 0.5rem; /* Reduce gap on mobile for better spacing */
  }
}

/* Mobile Responsive */
@media (max-width: 768px) {
  .report-primary-row {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .report-controls {
    width: 100%;
    margin-top: 0.5rem;
  }
  .report-metrics {
    margin-left: 0;
    flex-direction: column;
    gap: 0.5rem;
  }

  .metric {
    min-width: auto;
  }
}

.reports-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid #e2e8f0;
  justify-content: space-between;

  width: 100%;
}

.expense-report-title {
  color: #0033a0;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 15px;
}

.reports-title-section {
  display: flex;

  flex: 1;
}

.reports-title-section,
.reports-header {
  display: flex;
  align-items: center;
  gap: 8px;
}

.info-icon:hover {
  color: #002280;
}

.info-tooltip-container:hover .info-tooltip {
  display: block;
}

.info-tooltip::after {
  content: "";
  position: absolute;
  top: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #f9f9f9;
  box-shadow: 0 -3px 3px rgba(0, 0, 0, 0.1); /* Subtle shadow for the arrow */
}

.filter-selector select {
  padding: 8px 12px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  background-color: #ffffff;
  color: #1a202c;
  font-size: 0.9rem;
  cursor: pointer;
  min-width: 150px;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .menu-content-container {
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
    max-width: 100%;
    border-radius: 0;
  }

  .expense-reports-container {
    padding: 16px;
  }

  .reports-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  .date-range-selectors {
    flex-direction: column;
    align-items: stretch;
  }

  .filter-selector select {
    width: 100%;
  }
}

.client-email {
  font-size: 0.9em;
  color: #666;
  font-weight: normal;
}
.back-button {
  background-color: #0033a0;
  color: #ffffff;
  border: none;
  padding: 8px 16px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.back-button:hover {
  background-color: #002080;
}

/* Unique classes from accountantview.css */
.accountant-view-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 24px;
  background: #ffffff;
  min-height: calc(100vh - 48px);
}

.client-header {
  display: flex;
  align-items: center;
  flex-wrap: wrap; /* Allow wrapping for smaller screens (mobile) */
  gap: 10px; /* Add some space between elements */
  margin-bottom: 32px;
  padding-bottom: 16px;
  border-bottom: 1px solid #e9ecef;
}

.client-name {
  margin: 0;
  color: #212529;
  font-size: 24px;
  font-weight: 600;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 48px 0;
}

/* Mobile Responsive additions */
@media (max-width: 768px) {
  .accountant-view-container {
    padding: 16px;
  }

  .client-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .client-name {
    font-size: 20px;
  }

  .save-button,
  .cancel-button {
    width: 100%;
    margin-top: 8px;
  }

  .filter-selector {
    width: 100%;
  }
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  padding: 20px; /* Add padding to prevent touching screen edges */
}

.notes-modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 400px; /* Reduced from 500px */
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin: auto; /* Center in overlay */
}

.notes-textarea {
  width: 100%;
  min-height: 120px; /* Reduced from 150px */
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: vertical;
  font-family: inherit;
  font-size: 14px;
  box-sizing: border-box; /* Ensure padding doesn't affect width */
}

.notes-content {
  width: 100%;
}

.notes-content h4 {
  margin: 0 0 15px 0;
  color: #333;
}

.notes-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 15px;
}

.notes-actions button {
  min-width: 80px;
  height: 32px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.notes-actions .save-button {
  background: #0033a0;
  color: white;
  padding: 0 16px;
}

.notes-actions .save-button:hover {
  background: #002c8a;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.notes-actions .cancel-button {
  background: #f0f0f0;
  color: #333;
  padding: 0 16px;
}

.notes-actions .cancel-button:hover {
  background: #e4e4e4;
}
.notes-actions .save-button,
.notes-actions .cancel-button {
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s ease;
}

/* Ensure modal appears above other content */
.modal-overlay {
  z-index: 9999;
}

/* Optional: Prevent body scrolling when modal is open */
body.modal-open {
  overflow: hidden;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .modal-overlay {
    padding: 15px;
  }

  .notes-modal {
    max-width: 320px;
    padding: 15px;
  }

  .notes-textarea {
    min-height: 100px;
  }
}

/* Notes Modal Specific Styles */
.eb-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  padding: 20px;
}

.eb-notes-modal {
  background: white;
  padding: 24px;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.eb-notes-content {
  width: 100%;
}

.eb-notes-content h4 {
  margin: 0 0 16px 0;
  color: #333;
  font-size: 16px;
}

.eb-notes-textarea {
  width: 100%;
  min-height: 120px;
  margin: 0 0 16px 0;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: vertical;
  font-family: inherit;
  font-size: 14px;
  box-sizing: border-box;
}

.eb-modal-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
}

.eb-modal-actions button {
  height: 36px;
  min-width: 80px;
  padding: 0 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.eb-modal-save {
  background: #0033a0;
  color: white;
}

.eb-modal-cancel {
  background: #f0f0f0;
  color: #333;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .eb-notes-modal {
    width: 95%;
    max-width: 350px;
    padding: 20px;
  }

  .eb-notes-textarea {
    min-height: 100px;
  }
}

.summary-header-container {
  padding-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.summary-title {
  color: #0033a0;
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0;
}

.spreadsheet-view-button {
  background-color: white;
  color: #06f;
  border: 1.5px solid #06f;
  border-radius: 6px;
  padding: 15px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
  transition: all 0.2s ease;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  white-space: nowrap;
}

.spreadsheet-view-button:hover {
  background-color: #f8f9fa;
  border-color: #002c91;
}

.spreadsheet-view-button i {
  font-size: 0.9rem;
}

@media (min-width: 481px) {
  .summary-header-container {
    margin-top: 25px; /* Top margin only for desktop */
  }
}

@media (max-width: 480px) {
  .summary-header-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  .spreadsheet-view-button {
    width: 100%;
    justify-content: center;
  }
}

.date-range-selectors {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.configure-approver-link {
  margin-top: 8px;
}

.configure-approver-link a {
  color: #007bff;
  text-decoration: none;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.configure-approver-link a i {
  margin-right: 8px;
  font-size: 16px;
}

.configure-approver-link a:hover {
  text-decoration: underline;
}
.date-range-and-button {
  display: flex;
  flex-direction: column;
  gap: 24px; /* Increased gap between items */
  margin-top: 24px;
  width: 100%;
  max-width: 800px; /* Optional: limit maximum width */
}

.date-range-selectors {
  width: 100%;
}

.last-submitted {
  color: #666;
  margin-bottom: 12px;
}

.links-container {
  display: flex;
  gap: 40px;
  margin-bottom: 8px;
  padding: 6px 12px;
}

.link-item {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  color: #0033a0;
  text-decoration: none;
  font-size: 0.8rem;
}

.link-item:hover {
  text-decoration: underline;
}

.link-item i {
  font-size: 0.85rem;
}

.preview-button {
  align-self: flex-start;
  min-width: 200px;
  max-width: 200px;
  flex-shrink: 0;
}

@media (max-width: 768px) {
  .links-container {
    gap: 30px;
  }
  .preview-button {
    width: 100%;
  }
}

.button-info-container {
  display: flex;
  gap: 12px;
  position: relative;
}

.info-tooltip-container {
  display: inline-flex;
  align-items: center;
  margin-left: 8px;
  position: relative; /* Added back - needed for absolute positioning */
}

.info-icon {
  color: #0033a0;
  font-size: 1.1rem;
  cursor: pointer;
  transition: color 0.2s ease;
}
.info-icon:hover {
  color: #002280;
}

.info-tooltip {
  display: none;
  position: absolute;
  top: calc(100% + 10px);
  right: -350px;
  width: 700px;
  background: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.25);
  border: 1px solid #ddd;
  z-index: 1000;
  line-height: 1.6;
  text-align: left;
}

/* Added back - this makes the tooltip appear on hover */
.info-tooltip-container:hover .info-tooltip {
  display: block;
}
/* Added back - arrow pointer for tooltip */
.info-tooltip::after {
  content: "";
  position: absolute;
  top: -8px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid #f9f9f9;
  box-shadow: 0 -3px 3px rgba(0, 0, 0, 0.1);
}

.info-steps {
  display: flex;
  flex-direction: column; /* Stack steps vertically */
}

.info-steps p {
  display: flex;
  align-items: baseline; /* Align text and number properly */
  gap: 12px; /* Spacing between step number and text */
  margin: 0 0 16px; /* Add spacing between bullets */
}

.info-steps i {
  color: #0033a0;
  font-size: 1rem;
  margin-top: 3px; /* Align icon with text */
}

.step-number {
  font-weight: bold;
  min-width: 20px; /* Ensure consistent width for step numbers */
  text-align: center;
}

.info-note {
  margin-top: 12px;
  font-style: italic;
  color: #666;
  font-size: 0.9em;
}

.info-note i {
  margin-right: 6px;
  color: #0033a0;
}

@media (max-width: 768px) {
  .info-tooltip {
    width: 350px;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    top: 40%;
    right: auto;
    z-index: 1000;
  }

  .info-tooltip::after {
    bottom: auto;
    top: -8px;
    left: 90%;
    transform: translateX(-50%);
    border-bottom: 8px solid white;
    border-top: none;
  }

  .button-info-container {
    width: 100%;
  }
}
.emphasized {
  font-weight: bold; /* Emphasize inline text */
}

.title-info-container {
  gap: 8px;
}

.info-icon {
  color: #0033a0;
  font-size: 1rem;
  cursor: pointer;
}

.photo-selection-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #0033a0;
}

.pricing-table-container {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.spreadsheet-link {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: #0033a0;
  text-decoration: none;
}

.spreadsheet-link:hover {
  text-decoration: underline;
}

.spreadsheet-icon {
  margin-right: 5px;
  color: #ffcc00;
}

.filter-controls {
  display: flex;
  align-items: center;
  gap: 20px;
}

.filter-controls .link-item {
  text-decoration: none;
  color: #0033a0;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 12px;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.filter-controls .link-item:hover {
  background-color: rgba(0, 51, 160, 0.1);
}

.filter-controls .link-item i {
  font-size: 14px;
}

.subscription-buttons {
  display: flex;
  gap: 1rem; /* or whatever spacing you prefer */
  align-items: center;
}

.custom-pagination-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px; /* Adds spacing between elements */
  margin-top: 20px; /* Adds space above the controls */
}

.custom-pagination-button {
  padding: 5px 10px;
  border: none;
  background-color: gold; /* Bootstrap blue color */
  color: white;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px; /* Adds spacing between icon and text */
  transition: background-color 0.3s;
}

.custom-pagination-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.custom-pagination-button:disabled {
  background-color: #d6d6d6; /* Light gray for disabled buttons */
  cursor: not-allowed;
}

.custom-pagination-text {
  font-size: 14px;
  color: #333; /* Slightly darker text for contrast */
}
/* Button container styles 
.photo-source-buttons {
  display: flex;
  gap: 10px;  
  margin: 15px 0;  
}

 .photo-source-buttons {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  max-width: 400px;  
}

 .google-button {
  background-color: #ffffff;
  color: #4285f4;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 100%;  
  padding: 0.875rem 1.5rem;
}

.google-button:hover:not(:disabled) {
  background-color: #e8f0fe;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.google-button:disabled {
  background-color: #f5f5f5;
  color: #a0aec0;
  box-shadow: none;
}

/* Camera button styles (matching Google button style) 
.camera-button {
  background-color: #ffffff;
  color: #4285f4;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 100%;  
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.875rem 1.5rem;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.camera-button:hover:not(:disabled) {
  background-color: #e8f0fe;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.camera-button:disabled {
  background-color: #f5f5f5;
  color: #a0aec0;
  box-shadow: none;
}

.camera-button i {
  font-size: 1.25rem;
}
 .upload-button {
  background-color: #ffffff;
  color: #4285f4;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 100%;  
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.875rem 1.5rem;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.upload-button:hover:not(:disabled) {
  background-color: #e8f0fe;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.upload-button:disabled {
  background-color: #f5f5f5;
  color: #a0aec0;
  box-shadow: none;
}

.upload-button i {
  font-size: 1.25rem;
}

.google-button,
.camera-button,
.upload-button {
  margin: 0;  
}

.mileage-button {
  background-color: #ffffff;
  color: #4285f4;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.875rem 1.5rem;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.mileage-button:hover:not(:disabled) {
  background-color: #e8f0fe;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.mileage-button:disabled {
  background-color: #f5f5f5;
  color: #a0aec0;
  box-shadow: none;
}

.mileage-button i {
  font-size: 1.25rem;
}

*/

/* 

Add this new style for the mileage notification 
.notification-box div {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px;
  background-color: #ecfdf5;
  border: 1px solid #6ee7b7;
  border-radius: 6px;
  color: #065f46;
  font-weight: 500;
}

.notification-box div:before {
  content: "\f058";  
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: #059669;
}
*/

/* Base styles for all notifications */
.notification-box {
  max-width: 95%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 16px 0;
  text-align: left; /* Force left alignment for all child elements */
}

.notification {
  display: flex;
  align-items: center; /* Vertically align items */
  text-align: left;
  justify-content: flex-start; /* Align content to the left */
  gap: 8px;
  padding: 12px;
  border-radius: 6px;
  font-weight: 500;
  color: #1f2937;
  flex-wrap: wrap; /* Prevent wrapping of flex items */
  word-break: break-word; /* Ensures long text wraps properly */
  overflow: hidden; /* Prevent content overflow */
}

.notification .icon {
  flex-shrink: 0; /* Prevent the icon from shrinking */
  font-size: 18px; /* Adjust icon size if needed */
  line-height: 1; /* Ensure icon aligns vertically */
}

/* Success Notification */
.notification.success {
  background-color: #ecfdf5; /* Light green */
  border: 1px solid #6ee7b7; /* Green border */
}

.notification.success .icon {
  color: #059669; /* Green icon */
}

/* Info Notification */
.notification.info {
  background-color: #e0f2fe; /* Light blue */
  border: 1px solid #3b82f6; /* Blue border */
}

.notification.info .icon {
  color: #3b82f6; /* Blue icon */
}

/* Optional: Link styling */
.notification a {
  color: #1d4ed8; /* Blue link */
  text-decoration: underline;
}

.integration-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-start; /* Ensures buttons remain aligned */
}

@media (max-width: 640px) {
  .integration-container {
    flex-direction: column;
    align-items: stretch;
  }
}

.notification a:hover {
  text-decoration: none;
}

/* Error Notification */
.notification.error {
  background-color: #fef2f2; /* Light red */
  border: 1px solid #f87171; /* Red border */
  color: #b91c1c; /* Dark red text */
}

.notification.error .icon {
  color: #dc2626; /* Red icon */
}
/* Bold email text */
.bold-email {
  font-weight: 600; /* Semi-bold or bold */
  color: #1f2937; /* Slightly darker color for emphasis */
}

.link-button {
  padding: 8px 12px;
  color: rgb(30, 58, 138);
  background-color: rgb(243, 244, 246);
  border: 1px solid rgb(209, 213, 219);
  border-radius: 6px;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
  display: flex; /* Use flexbox for centering */
  justify-content: center; /* Horizontally center the content */
  align-items: center; /* Vertically center the content */
  text-align: center; /* Ensure text alignment for multi-line text */
}

.link-button:hover {
  background-color: rgb(229, 231, 235);
}
.link-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.5);
}

.link-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.reset-button {
  color: rgb(220, 38, 38);
  border-color: rgb(252, 165, 165);
  background-color: rgb(254, 242, 242);
}

.reset-button:hover {
  background-color: rgb(254, 226, 226);
}

/* Error Message */
.error-message {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  width: 100%;
  color: #dc2626;
  font-size: 0.875rem;
  padding: 8px 12px;
  background-color: #fef2f2;
  border-radius: 4px;
  border: 1px solid #fca5a5;
  z-index: 1;
}
.section-separator {
  height: 3px;
  background: linear-gradient(to right, #e6ebf1, #d0d7de);
  margin: 24px 0;
  border-radius: 2px;
}

/* Add to your existing CSS */
.tab-container {
  margin-bottom: 20px;
  border-bottom: 1px solid #e5e7eb;
}

.tab-button {
  padding: 10px 20px;
  margin-right: 10px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 1rem;
  color: #6b7280;
}

.tab-button.active {
  color: #0033a0;
  border-bottom: 2px solid #0033a0;
}

.main-content-section {
  background: white;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .main-content-section {
    padding: 0; /* Remove padding */
    border-radius: 0;
    border: none;
    box-shadow: none;
    background-color: #f9f9f9; /* Use the grey background directly */
  }
}
.accounting-integrations {
  margin-top: 10px;
  padding: 10px 0;
  border-top: 1px solid #e0e0e0;
}

.integration-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-start; /* Keeps buttons aligned */
}

@media (max-width: 768px) {
  .integration-buttons {
    flex-direction: column;
    align-items: stretch;
  }
}

/* AccountantView.js - Compliance Check Section Styles */

.compliance-check-section {
  margin-top: 20px;
  padding: 15px;
  border-top: 1px solid #dee2e6;
  width: 100%;
  max-width: none; /* Prevent unnecessary width constraints */
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.compliance-button {
  /* Matches your existing button styles in AccountantView */
  background: #0033a0;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
}

.compliance-button:hover {
  background: #002777;
}

.compliance-button:disabled {
  background: #6c757d;
  cursor: not-allowed;
}

/* AccountantView.js - Compliance Results Styles */
.compliance-results {
  margin-top: 20px;
}

.compliance-item {
  /* Matches your existing report-row style in AccountantView */
  background: white;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 10px;
}

.expense-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.compliance-status {
  /* Matches your existing status-badge style */
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: 500;
}

.compliance-status.compliant {
  background: #d4edda;
  color: #155724;
}

.compliance-status.violation {
  background: #f8d7da;
  color: #721c24;
}

/* AccountantView.js - Citations Section Styles */
.citations {
  margin-top: 10px;
  padding: 10px;
  background: #f8f9fa;
  border-radius: 4px;
}

.citation {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  font-size: 0.9em;
}

.source {
  color: #0033a0;
  font-weight: 500;
}

.reference {
  color: #6c757d;
}

/* AccountantView.js - Required Actions Styles */
.required-actions {
  margin-top: 15px;
}

.required-actions h5 {
  color: #721c24;
  margin-bottom: 8px;
}

.required-actions ul {
  list-style-type: none;
  padding-left: 0;
}

.required-actions li {
  color: #721c24;
  padding: 4px 0;
  font-size: 0.9em;
}

/* Shared Loading State Styles (Used in both AdminView and AccountantView) */
.loading-button-content {
  display: flex;
  align-items: center;
  gap: 8px;
}

.accounting-controls {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}
/* Add these styles to your ProcessInfo.css file */

/* Common styles for integration sections in both views */
.integration-container {
  margin-bottom: 8px;
  width: 100%;
}

/* For AccountantView - add these to your AccountantView.css */
.accounting-controls {
  display: flex;
  flex-direction: column;
  gap: 8px; /* Small gap between elements */
}

.integration-buttons {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

/* For ProcessInfo.js - modify quickbooks-section if needed */
.quickbooks-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
/* Make sure the QuickBooks button container also has consistent spacing */
.quickbooks-section .integration-container:not(:last-child),
.accounting-controls .integration-container:not(:last-child) {
  margin-bottom: 8px !important;
}
/* Add these to your global CSS or the specific component CSS */

/* For any styled buttons similar to QuickBooks - make sure they share the same structure */
.integration-buttons button,
.integration-buttons a,
.integration-container button,
.integration-container a,
.compliance-button,
.quickbooks-button {
  /* Add classes of all similar buttons */
  height: 48px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  font-weight: 500;
  font-size: 1rem;
  width: 100%;
  padding: 12px 16px;
  margin-bottom: 0;
}

/* Ensure no unwanted margins between buttons */
.integration-buttons > *:not(:last-child),
.accounting-controls > *:not(:last-child) {
  margin-bottom: 8px !important;
}

/* Remove any dividers that might be added by CSS */
.accounting-controls .compliance-check-wrapper,
.quickbooks-section .compliance-check-container {
  border-top: none !important;
  margin-top: 8px !important;
}

/* Override any hr styles that might be used as dividers */
.report-row hr,
.accounting-controls hr,
.quickbooks-section hr {
  display: none !important;
}

/* Fix for any hidden dividers */
.report-row > *:not(:first-child) {
  border-top: none !important;
}
.create-report-button {
  /* Basic appearance */
  background-color: #0033a0;
  color: white;
  width: 100%;
  height: 60px;
  border: none;
  border-radius: 6px;

  /* Text styling */
  font-size: 1.1rem;
  font-weight: 600;

  /* Layout */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 20px;

  /* Interactive behavior */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.create-report-button:hover {
  background-color: #002580;
}

.create-report-button i {
  margin-left: 10px;
}
.ml-2 {
  margin-left: 8px;
}

/* Add these styles to your CSS file */

/* Redesigned header container */
.reports-header-redesigned {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  width: 100%;
}

/* Create report section with button and shortcuts */
.create-report-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 12px;
}

/* Spreadsheet shortcuts container */
.spreadsheet-shortcuts {
  display: flex;
  align-items: center;
  font-size: 0.85rem;
  color: #6b7280;
  flex-wrap: wrap;
  margin-left: 4px;
}
.shortcuts-container {
  width: 100%;
  margin-top: 12px;
}

.shortcuts-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
}

.shortcuts-label {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #666;
  font-size: 14px;
  white-space: nowrap;
}

.shortcuts-links {
  display: flex;
  align-items: center;
  gap: 20px;
}

.shortcut-link {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #0d6efd;
  text-decoration: none;
  white-space: nowrap;
}

@media (max-width: 600px) {
  .shortcuts-row {
    gap: 10px; /* Just reduce the gap a bit */
  }

  .shortcuts-links {
    gap: 12px; /* Less space between links */
  }

  /* Make font slightly smaller on mobile if needed */
  .shortcuts-label,
  .shortcut-link {
    font-size: 13px;
  }
}

.shortcut-link:hover {
  background-color: rgba(59, 130, 246, 0.1);
  text-decoration: underline;
}

.shortcut-link i {
  margin-right: 4px;
  font-size: 0.85rem;
}

/* Separator between links */
.separator {
  color: #d1d5db;
  margin: 0 2px;
}

/* For mobile responsiveness */
@media (max-width: 640px) {
  .spreadsheet-shortcuts {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  .shortcuts-links {
    margin-left: 4px;
  }
}

/* Add these styles to your CSS file */

/* Redesigned header container */
.reports-header-redesigned {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  width: 100%;
  text-align: left;
  font-size: 14px;
  color: #666;
  margin: 4px 0 20px 0;
}

/* Create report section with button and shortcuts */
.create-report-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 16px;
}

/* Container for button and description */
.report-action-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

/* Description text */
.report-description {
  font-size: 0.875rem;
  color: #6b7280;
  margin: 0;
  max-width: 500px;
  line-height: 1.4;
}

/* Spreadsheet shortcuts container */
.spreadsheet-shortcuts {
  display: flex;
  align-items: center;
  font-size: 0.85rem;
  color: #6b7280;
  flex-wrap: wrap;
  margin-left: 4px;
  margin-top: 4px;
  padding-top: 12px;
  border-top: 1px solid #f0f0f0;
  width: 100%;
}

/* Label for shortcuts */
.shortcuts-label {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  color: #6b7280;
  margin-right: 8px;
}

/* Links container */
.shortcuts-links {
  display: flex;
  align-items: center;
  gap: 4px;
}

/* Individual shortcut link */
.shortcut-link {
  display: inline-flex;
  align-items: center;
  color: #3b82f6;
  text-decoration: none;
  padding: 2px 4px;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.shortcut-link:hover {
  background-color: rgba(59, 130, 246, 0.1);
  text-decoration: underline;
}

.shortcut-link i {
  margin-right: 4px;
  font-size: 0.85rem;
}

/* Separator between links */
.separator {
  color: #d1d5db;
  margin: 0 2px;
}

/* For mobile responsiveness */
@media (max-width: 640px) {
  .spreadsheet-shortcuts {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  .shortcuts-links {
    margin-left: 4px;
  }

  .report-description {
    font-size: 0.8rem;
  }
}
.reports-header-row {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
}

/* First row with button and counter */
.reports-actions-row {
  display: flex;
  align-items: flex-start; /* This ensures top alignment */
  gap: 16px;
  width: 100%;
}

/* Button container */
.button-container {
  width: 48%;
}

/* Icon positioning */
.button-icon {
  margin-left: 12px;
  font-size: 20px;
}

.report-description {
  font-size: 14px;
  color: #666;
  margin-top: 8px;
}

/* Receipt counter styling - make it look less like a button */
.receipt-counter-box {
  background-color: #fffaf0; /* Lighter yellow background */
  border: 1px solid #ffe0b2; /* Lighter border color */
  border-radius: 6px;
  width: 48%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  box-shadow: none; /* Remove any shadow */
  cursor: default; /* Normal cursor instead of pointer */
}

.counter-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.counter-label {
  color: #666; /* Medium gray for the label */
  font-weight: normal; /* Not bold */
  margin: 0;
}

.counter-value {
  font-size: 1.5rem;
  font-weight: bold;
  color: #0d47a1; /* Keep the value prominent */
  margin: 0;
}

.shortcuts-header {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #666;
  font-size: 14px;
  margin-bottom: 8px;
}

/* Make shortcuts horizontal */
.shortcuts-links {
  display: flex;
  gap: 24px;
}

.shortcut-link {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #0d6efd;
  text-decoration: none;
  white-space: nowrap;
}

.shortcut-link:hover {
  text-decoration: underline;
}

/* Desktop responsive styles */
@media (min-width: 768px) {
  .reports-actions-row {
    flex-direction: row;
    align-items: flex-start; /* Change from 'center' to 'flex-start' for top alignment */
    gap: 16px;
  }

  .button-container {
    width: 48%;
  }

  .create-report-button {
    height: 60px;
    font-size: 1.1rem;
    padding: 0 20px;
  }

  .receipt-counter-box {
    width: 48%;
    height: 60px; /* Match button height */
    padding: 0 20px;
  }

  .counter-content {
    justify-content: space-between;
  }

  .counter-label {
    font-size: 18px;
  }

  .counter-value {
    font-size: 24px;
  }

  .shortcuts-links {
    gap: 24px;
  }
}
.view-expenses-row {
  width: 100%;
  margin-bottom: 20px;
}

.view-expenses-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  background-color: #f8f9fa;
  color: #0033a0;
  border: 2px solid #0033a0;
  border-radius: 6px;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 10px 20px;
  height: 60px;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.view-expenses-button:hover {
  background-color: #e9ecef;
}

.view-expenses-button i {
  margin-left: 10px;
  font-size: 20px;
  color: #0033a0;
}

.action-description {
  font-size: 14px;
  color: #666;
  margin-top: 8px;
}
.full-width-description {
  width: 100%;
  text-align: left;
  font-size: 14px;
  color: #666;
  margin: 4px 0 20px 0;
}

.view-expenses-button {
  /* Make this less prominent - outlined style */
  background-color: white;
  color: #0033a0;
  border: 1px solid #0033a0;
  /* Other properties remain the same */
}

.action-description {
  font-size: 14px;
  color: #666;
  margin-top: 6px;
  margin-bottom: 16px;
}

/* NEW REPORTS PAGE STYLES - START */

/* Main container styles */
.reports-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: #f8f9fa;
  border-radius: 8px;
}

/* Master data section - top part */
.master-data-section {
  background-color: white;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

/* View Spreadsheet Button - Secondary action */
.view-spreadsheet-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.2rem;
  padding: 0.75rem 1.5rem;
  background-color: white;
  border: 1.5px solid #0033a0;
  border-radius: 8px;
  color: #0033a0;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.2s ease;
  margin-bottom: 0.5rem;
  width: 100%;
  height: 60px;
  font-size: 1.2rem;
}

.view-spreadsheet-button:hover {
  background-color: #f0f4ff;
}

.button-icon {
  font-size: 1.2rem;
  margin-left: 10px;
}

.action-description {
  color: #6c757d;
  font-size: 0.9rem;
  margin: 0.25rem 0 1.5rem 0;
}

/* Full width description */
.full-width-description {
  width: 100%;
  text-align: left;
  font-size: 14px;
  color: #666;
  margin: 4px 0 20px 0;
}

/* Report actions row */
.report-actions-row {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
}

/* Create Report Button - Primary action */
.create-report-button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 60px;
  padding: 0 20px;
  background-color: #0033a0;
  border: none;
  border-radius: 8px;
  color: white;
  font-weight: 500;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.create-report-button:hover {
  background-color: #002277;
}

.button-container {
  width: 48%;
}

/* Unsubmitted receipts counter */
.receipt-counter-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 48%;
  height: 60px;
  padding: 0 20px;
  border-radius: 8px;
  background-color: #fff8e1;
  border: 1px solid #ffe0b2;
}

.counter-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.counter-label {
  font-size: 1rem;
  color: #666;
  font-weight: normal;
  margin: 0;
}

.counter-value {
  font-size: 1.5rem;
  font-weight: 700;
  color: #0033a0;
  margin: 0;
}

/* Reports display section - bottom part */
.reports-display-section {
  background-color: #f0f7ff;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

/* Empty state styling */
.empty-state {
  text-align: center;
  padding: 3rem 1rem;
  color: #6c757d;
}

.empty-state-message {
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
}

.empty-state-help {
  font-size: 0.9rem;
}

/* Mobile responsiveness for reports */
@media (max-width: 768px) {
  .report-actions-row {
    flex-direction: column;
  }

  .button-container,
  .receipt-counter-box {
    width: 100%;
    margin-bottom: 12px;
  }

  .view-spreadsheet-button {
    font-size: 1rem;
    height: 50px;
  }

  .create-report-button {
    font-size: 1rem;
    height: 60px;
  }
}

/* NEW REPORTS PAGE STYLES - END */

/*TEST NEW REPORT CSS */
/* Add to your existing CSS */
@media (max-width: 768px) {
  .report-primary-row {
    flex-wrap: wrap;
    gap: 8px;
  }

  .report-controls {
    width: 100%;
    justify-content: space-between;
    margin-top: 8px;
  }

  /* Keep status dropdown and notes icon on same line */
  .status-select {
    flex: 1;
  }

  .action-buttons {
    flex: 1;
    justify-content: flex-end;
  }
}

.action-button {
  background: none;
  border: none;
  padding: 4px;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.action-button:hover {
  background: rgba(0, 51, 160, 0.1);
}

.action-button:hover i {
  color: #002080;
}

/* Improved desktop layout */
.report-row {
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  margin-bottom: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease;
}

.report-row:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Better typography */
.report-title a {
  font-weight: 600;
  color: #1a365d;
}

.report-date {
  color: #4a5568;
  font-size: 0.9em;
}

/* Enhanced status dropdown */
.status-select {
  border: 1px solid #cbd5e0;
  padding: 6px 12px;
  border-radius: 6px;
  background: #f7fafc;
}

.reports-content {
  padding: 16px;
  background: #f8fafc;
  border-radius: 8px;
}

.report-metrics {
  padding: 12px 0;
  border-top: 1px solid #edf2f7;
}

.expense-report-title {
  font-size: 1.5rem;
  color: #1a365d;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #e2e8f0;
}

.primary-action-button {
  background: #0033a0;
  color: white;
  padding: 8px 16px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.primary-action-button:hover {
  background: #002277;
}

/*END TEST NEW REPORT CSS */

/* GPT RECC */

/* 1) Keep Receipts & Func. Total on the same line if space allows */
.report-metrics {
  display: flex;
  flex-wrap: nowrap;
  gap: 1rem;
}
.metric {
  white-space: nowrap;
}

/* 2) Make the note icon white on hover so it doesn't disappear 
.action-button:hover {
  background-color: #0033a0;  
}
.action-button:hover i {
  color: #fff;
}
*/

/* 3) Right-justify the dropdown + icon on desktop */
@media (min-width: 768px) {
  .report-primary-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media (max-width: 768px) {
  .report-metrics {
    flex-direction: row;
  }
}

/* End GPT TEST */

/* restyling report rows test */

/* --- New "report-card" layout styles --- */

.report-card {
  background-color: #fff;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px; /* spacing between rows */
}

/* Row 1: date/title left, status+notes right */
.row1,
.row2,
.row3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; /* let items wrap on narrow screens */
  gap: 8px;
}

/* row1-left: date + title stacked, row1-right: status + icons */
.row1-left {
  display: flex;
  flex-direction: column; /* or row if you prefer side-by-side */
  gap: 4px;
}
.row1-right {
  display: flex;
  gap: 8px;
}

/* row2: receipts on left, func total on right */
.row2 {
  border-top: 1px solid #f0f0f0;
  padding-top: 8px;
}
.metric-left,
.metric-right {
  display: flex;
  gap: 4px;
  white-space: nowrap;
}

/* row3: integration buttons (QB, Wave, compliance) */
.row3 {
  border-top: 1px solid #f0f0f0;
  padding-top: 8px;
  gap: 8px;
  flex-wrap: wrap;
}

/* Common text styling */
.report-date {
  font-size: 0.85rem;
  color: #64748b;
}
.report-title a {
  font-size: 1rem;
  font-weight: 500;
  color: #1a202c;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px; /* or whatever max width you like */
}

.label {
  font-size: 0.85rem;
  color: #64748b;
}
.value {
  font-size: 0.85rem;
  font-weight: 600;
  color: #1a202c;
}

/* Status dropdown & note icon */
.status-select {
  padding: 0.25rem 0.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  background-color: white;
  font-size: 0.8rem;
}

.action-buttons {
  display: flex;
  gap: 0.5rem;
}

.action-button {
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  background-color: white;
  color: #64748b;
  font-size: 0.85rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: 4px 8px;
  transition: background-color 0.2s ease;
}
.action-button:hover {
  background-color: rgba(0, 51, 160, 0.05);
}

/* QuickBooks / Wave / compliance container */
.integration-container {
  flex: 1; /* let them grow equally */
  max-width: 100%; /* full width on small screens */
}

/* Mobile: stack items vertically if needed */
@media (max-width: 600px) {
  .report-title a {
    max-width: 100%;
  }
}

.report-card {
  width: 100%;
  box-sizing: border-box; /* so padding doesn’t overflow */
}

/* end of restyling report row test */

/* trying to get it working on mobile */

/* Force row1 and row2 to stay horizontal on mobile */
@media (max-width: 600px) {
  .row1 {
    flex-direction: row !important;
    align-items: center !important;
    gap: 8px;
  }

  /* Keep date on the left, "title-bar" on the right, no forced wrapping */
  .date-block {
    flex: 0 0 auto; /* Don’t let it stretch too much */
  }
  .title-bar {
    display: flex;
    flex-wrap: nowrap; /* Title, dropdown, note icon on one line */
    gap: 8px;
    align-items: center;
    overflow: hidden; /* If text is too long, it might overflow or wrap */
  }

  /* Row2 also stays side-by-side for receipts & total */
  .row2 {
    flex-direction: row !important;
    gap: 1rem;
    flex-wrap: nowrap;
  }
}
/* trying to get it working on mobile end */

/*last try */
/* Basic card wrapper */
.report-card {
  background-color: #fff;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

/* Row containers */
.row1,
.row2,
.row3,
.row4 {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap; /* Let them wrap if truly needed */
}

/* Row1: Desktop => date alone, Mobile => date + mobile-title */
.date-block {
  font-size: 0.85rem;
  color: #64748b;
  flex: 0 0 auto; /* Don’t let it stretch */
}

/* Hidden by default on desktop, shown on mobile */
.mobile-title {
  display: none;
}

/* Row2: 
   Desktop => .title-left on the left, .controls-right on the right
   Mobile => .title-left is hidden; .controls-right goes on its own line */
.title-left a {
  font-size: 1rem;
  font-weight: 500;
  color: #1a202c;
  text-decoration: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 220px; /* Adjust as needed */
}

.controls-right {
  margin-left: auto; /* Push controls to the far right */
  display: flex;
  gap: 8px;
  align-items: center;
}

/* Row3: receipts + total side by side */
.row3 {
  border-top: 1px solid #f0f0f0;
  padding-top: 8px;
  justify-content: flex-start; /* Left-align the metrics */
  gap: 16px;
}

.metric-left,
.metric-right {
  display: flex;
  gap: 4px;
  white-space: nowrap;
}

.label {
  font-size: 0.85rem;
  color: #64748b;
}
.value {
  font-size: 0.85rem;
  font-weight: 600;
  color: #1a202c;
}

/* Row4: QuickBooks, Wave, Compliance */
.row4 {
  border-top: 1px solid #f0f0f0;
  padding-top: 8px;
  gap: 8px;
  flex-wrap: wrap;
}

/* Basic styling for status dropdown, note button, etc. */
.status-select {
  padding: 0.25rem 0.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  background-color: white;
  font-size: 0.8rem;
}

.action-button {
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  background-color: white;
  color: #64748b;
  font-size: 0.85rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: 4px 8px;
  transition: background-color 0.2s ease;
}
.action-button:hover {
  background-color: rgba(0, 51, 160, 0.05);
}

/* Tooltip container (unchanged from your existing code) */
.action-wrapper {
  position: relative;
  display: inline-block;
}
.tooltip-content {
  display: none;
  position: absolute;
  /* Put your existing tooltip styles here, e.g. top/left, background, etc. */
}
.action-wrapper:hover .tooltip-content {
  display: block;
}

/* Mobile (max-width: 600px):
   - Row1 shows date + mobile-title on the same line
   - Row2 hides the .title-left and just shows controls on the right
*/
@media (max-width: 600px) {
  /* Row1 => date + mobile-title side by side */
  .row1 {
    flex-wrap: nowrap !important; /* Keep them on one line if possible */
    justify-content: flex-start;
    gap: 8px;
  }
  .mobile-title {
    display: inline; /* Show the mobile title here */
    font-size: 1rem;
    font-weight: 500;
    color: #1a202c;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 140px; /* Narrower for mobile if needed */
  }

  /* Hide the desktop .title-left in row2 */
  .title-left {
    display: none;
  }

  /* Row2 => just the controls on the right, possibly full width */
  .row2 {
    justify-content: flex-end;
    gap: 8px;
    flex-wrap: nowrap;
  }
}

/* Make sure row3 is a flex container and let .metric-right push itself to the far right */
.row3 {
  display: flex; /* If it's not already */
  align-items: center;
  gap: 16px;
  /* You can keep justify-content: flex-start or remove it */
  justify-content: flex-start;
}

/* This is the key: margin-left: auto will push .metric-right to the far right */
.metric-right {
  margin-left: auto;
  text-align: right; /* optional, if you want the label/value themselves right-aligned */
}

.metric-right {
  /* Prevent text overflow */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* Add minimum width constraint */
  min-width: 120px; /* Adjust based on typical content */
}

/* For new unsubmitted receipts design */
/* Unified button approach with better responsive behavior */
.report-action-container {
  width: 100%;
  margin-bottom: 10px;
}

.create-report-button.combined {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
}

.button-left-content {
  display: flex;
  align-items: center;
}

.receipt-counter {
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 6px;
  padding: 4px 10px;
}

.receipt-label {
  margin-right: 8px;
  font-size: 0.9em;
  white-space: nowrap;
}

.counter-badge {
  background-color: #fffbeb; /* amber-50 */
  color: #1e40af; /* blue-800 */
  font-weight: bold;
  padding: 2px 8px;
  border-radius: 9999px;
  font-size: 1em;
  min-width: 1.5em; /* Ensure consistent width even with single digits */
  text-align: center;
}

/* Responsive adjustments */
@media (max-width: 499px) {
  .receipt-counter {
    background-color: transparent;
    padding: 0;
  }

  .counter-badge {
    padding: 4px 12px;
    font-size: 1.1em;
  }
}

/* Latest Claude improvement of report card */
/* ======= REPORT CARD IMPROVEMENTS ======= */

/* Enhanced Report Card Base Styling */
.report-card {
  background-color: #fff;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); /* Subtle shadow for depth */
  overflow: hidden; /* Contain all child elements */
  word-break: break-word; /* Handle long unbreakable strings */
}

/* Header layout styling */
.report-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 8px;
}

.report-meta {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

/* Date styling - more subtle */
.date-block {
  font-size: 0.85rem;
  color: #94a3b8; /* Lighter gray to de-emphasize */
  flex: 0 0 auto;
}

/* Enhanced title styling for desktop */
.title-left a {
  font-size: 1.15rem; /* Slightly larger */
  font-weight: 600; /* More prominent */
  color: #0033a0; /* Your brand blue color */
  text-decoration: none;
  /*  white-space: nowrap; */
  /*  overflow: hidden; */
  text-overflow: ellipsis;
  /*  max-width: 220px; */
  transition: color 0.2s;
}

.title-left a {
  /* Remove hard max-width constraint */
  max-width: none;
  /* Allow wrapping if space is available */
  white-space: normal;
  /* Prevent overflow */
  overflow: visible;
  /* Add line clamping for better control */
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Show max 2 lines */
  -webkit-box-orient: vertical;
}

.title-left a:hover {
  color: #004ce5; /* Lighter blue on hover */
}

/* Enhanced mobile title styling */
.mobile-title a {
  font-size: 1.1rem;
  font-weight: 600;
  color: #0033a0;
  text-decoration: none;
  white-space: nowrap;
  overflow: visible; /* Allow full display for short titles */
  text-overflow: clip; /* No ellipsis unless necessary */
  max-width: none; /* Remove the fixed max-width */
  margin-right: 12px; /* Optional: add breathing room */
  transition: color 0.2s;
}

.mobile-title a:hover {
  color: #004ce5;
}

/* Controls styling */
.controls-right {
  display: flex;
  gap: 8px;
  align-items: center;
}

/* Desktop/mobile title visibility */
@media (min-width: 601px) {
  .mobile-title {
    display: none;
  }
}

@media (max-width: 600px) {
  .title-left {
    display: none;
  }

  .report-meta {
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
}

/* Enhanced stats section */
.row3 {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e2e8f0;
  padding-top: 12px;
  margin-top: 4px;
  background-color: #f8fafc; /* Very light gray background */
  border-radius: 6px;
  padding: 10px 12px;
  min-width: 0; /* Crucial for flex item overflow */
}

.metric-left,
.metric-right {
  display: flex;
  align-items: baseline;
  gap: 6px;
}

.metric-right {
  margin-left: auto; /* Push to right */
}

.value.currency {
  /* Ensure currency values don't break layout */
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: monospace; /* Better number alignment */
  letter-spacing: -0.5px; /* Improve readability */
}

/* Labels and values with better contrast */
.label {
  font-size: 0.9rem;
  color: #64748b;
  font-weight: 400;
}

.value {
  font-size: 0.95rem;
  font-weight: 600;
  color: #0f172a; /* Darker for better contrast */
}

/* Special styling for monetary values */
.value.currency {
  color: #0033a0; /* Brand blue for monetary values */
}

/* Bottom section with clearer separation */
.row4 {
  border-top: 1px solid #e2e8f0;
  padding-top: 12px;
  margin-top: 4px;
}

/* end claude improvement */

/* Mobile layout fixes for report cards */
/* Mobile report card styles - FIXED VERSION */
@media (max-width: 767px) {
  /* Fix container width issues */
  .reports-content,
  .reports-display-section {
    width: 100% !important;
    max-width: 100% !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
  }

  .report-card {
    width: 100% !important;
    max-width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    box-sizing: border-box !important;
    overflow: hidden !important;
  }

  .button-text {
    font-size: 1.1rem;
    font-weight: 600;
  }

  /* HEADER STRUCTURE FOR DESKTOP PATTERN */
  .report-card .report-header {
    display: grid !important;
    grid-template-areas:
      "date controls"
      "title title" !important;
    grid-template-columns: auto auto !important;
    grid-gap: 10px !important;
    width: 100% !important;
  }

  .report-card .report-meta {
    grid-area: date !important;
    display: block !important;
  }

  .report-card .date-block {
    display: block !important;
    margin-top: 8px !important;
  }

  .report-card .controls-right {
    grid-area: controls !important;
    justify-content: flex-end !important;
  }

  .report-card .title-left {
    grid-area: title !important;
    display: block !important;
    width: 100% !important;
    margin-top: 8px !important;
  }

  .report-card .mobile-title {
    display: none !important;
  }

  .report-card .title-left a {
    display: inline-block !important;
    width: auto !important;
    max-width: 100% !important;
    white-space: normal !important;
    overflow: visible !important;
    text-overflow: clip !important;
    font-size: 1.1rem !important;
    font-weight: 600 !important;
    color: #0033a0 !important;
  }

  /* Control styling */
  .report-card .status-select {
    max-width: 120px !important;
    font-size: 0.85rem !important;
  }

  .report-card .action-button {
    padding: 6px !important;
  }

  /* Stats row styling */
  .report-card .row3 {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: nowrap !important;
    justify-content: space-between !important;
    width: 100% !important;
    padding: 10px !important;
    box-sizing: border-box !important;
  }

  /* Fix for Func. Total overflowing */
  .report-card .metric-left,
  .report-card .metric-right {
    white-space: nowrap !important;
    flex-shrink: 0 !important;
    max-width: 50% !important;
    box-sizing: border-box !important;
  }

  .report-card .value.currency {
    max-width: 120px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }

  .report-card .label {
    margin-right: 4px !important;
  }

  /* Integration buttons styling - FIX FOR QUICKBOOKS BUTTON */
  .report-card .row4 {
    width: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    gap: 12px !important;
  }

  .report-card .integration-container {
    width: 100% !important;
    margin-bottom: 12px !important;
    display: block !important;
  }

  /* Explicitly style QuickBooks button */
  .connect-to-quickbooks-button {
    background-color: #2ca01c !important;
    color: white !important;
    border: none !important;
    width: 100% !important;
    height: 44px !important;
    padding: 0 16px !important;
    border-radius: 6px !important;
    font-size: 1rem !important;
    font-weight: 500 !important;
    cursor: pointer !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    transition: background-color 0.3s !important;
    margin-bottom: 12px !important;
  }

  .share-to-wave-button,
  .disconnect-button {
    width: 100% !important;
    box-sizing: border-box !important;
    height: 44px !important;
    margin-bottom: 12px !important;
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .compliance-button {
    background-color: #0033a0 !important;
    color: white !important;
    border: none !important;
    width: 100% !important;
    height: 44px !important;
    padding: 0 16px !important;
    border-radius: 6px !important;
    font-size: 1rem !important;
    font-weight: 500 !important;
    cursor: pointer !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    transition: background-color 0.3s !important;
  }
}
/* Fix for the Func. Total truncation */
@media (max-width: 767px) {
  /* Make more room for the functional total value */
  .report-card .row3 {
    padding: 10px 8px !important; /* Reduce horizontal padding slightly */
  }

  .report-card .metric-right {
    max-width: 55% !important; /* Increase max-width for the right side */
  }

  .report-card .metric-left {
    max-width: 35% !important; /* Decrease max-width for the left side */
  }

  /* Ensure the currency value has enough space */
  .report-card .value.currency {
    max-width: none !important; /* Remove max-width constraint */
    font-size: 0.9rem !important; /* Slightly smaller font */
    padding-right: 4px !important; /* Add right padding */
  }

  /* Reduce font size of the labels slightly */
  .report-card .label {
    font-size: 0.85rem !important;
  }
  .row3 {
    flex-wrap: wrap;
    gap: 8px;
  }

  .metric-right {
    margin-left: 0;
    width: 100%;
    text-align: left;
    min-width: 0;
  }
}

/* Fix for missing controls in subsequent reports */
@media (max-width: 767px) {
  /* Reset the grid layout for ALL report cards */
  .report-card .report-header {
    display: flex !important; /* Use flex instead of grid */
    flex-direction: column !important;
    width: 100% !important;
    position: relative !important;
  }

  /* Create a wrapper for first row with date and controls */
  .report-card .report-header::before {
    content: "" !important;
    display: block !important;
    width: 100% !important;
    position: relative !important;
  }

  /* Move date to top-left */
  .report-card .date-block {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
  }

  /* Move controls to top-right */
  .report-card .controls-right {
    position: absolute !important;
    top: 0 !important;
    right: 0 !important;
    display: flex !important;
    visibility: visible !important;
    opacity: 1 !important;
  }

  /* Ensure title appears below date and controls */
  .report-card .title-left {
    margin-top: 30px !important; /* Push below the date and controls */
    width: 100% !important;
  }

  /* Add specific fix for subsequent report cards */
  .reports-content > div > div + .report-card .controls-right,
  .reports-content > div > div + .report-card + .report-card .controls-right {
    display: flex !important;
    position: absolute !important;
    top: 0 !important;
    right: 0 !important;
    visibility: visible !important;
    opacity: 1 !important;
    z-index: 10 !important;
  }
}

/* Everything works up until here , this is trying to fix the report title */
@media (max-width: 767px) {
  /* Fix container padding first */
  .report-card {
    padding: 16px !important;
    box-sizing: border-box !important;
    overflow: hidden !important; /* Prevent any overflow */
  }

  /* Fix title display */
  .report-card .title-left {
    width: 100% !important;
    box-sizing: border-box !important;
  }

  .report-card .title-left a {
    display: inline-block !important;
    max-width: calc(100% - 16px) !important;
    text-overflow: ellipsis !important;
    overflow: visible !important; /* Show full text for short titles */
    white-space: normal !important; /* Allow wrapping for long titles */
    box-sizing: border-box !important;
    padding-right: 8px !important;
    width: auto !important; /* Allow the element to size to content */
  }

  /* Fix functional total spacing - most important part */
  .report-card .row3 {
    width: 100% !important;
    box-sizing: border-box !important;
    padding: 10px 12px !important;
    display: flex !important;
    flex-wrap: nowrap !important;
    overflow: hidden !important; /* Critical - prevent overflowing */
  }

  /* Ensure metric areas fit within container */
  .report-card .metric-left {
    flex: 0 1 auto !important; /* Allow shrinking */
    min-width: auto !important;
    margin-right: 12px !important;
  }

  .report-card .metric-right {
    flex: 0 1 auto !important; /* Allow shrinking if needed */
    margin-left: auto !important; /* Push to right */
    text-align: right !important;
    max-width: 60% !important; /* More space for right side */
    overflow: hidden !important;
  }

  /* Force currency value to stay within bounds */
  .report-card .value.currency {
    display: inline-block !important;
    max-width: 100% !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
    vertical-align: bottom !important;
    padding-right: 4px !important;
  }

  /* Use a hard constraint on the container if needed */
  @supports (max-width: max-content) {
    .report-card .row3 {
      max-width: 100% !important; /* Never exceed container width */
    }
  }
}

/* new stuff from deep seek */

/* Modify the title styles */
.title-left a,
.mobile-title a {
  white-space: nowrap;
  overflow: visible; /* Change from hidden */
  text-overflow: clip; /* Instead of ellipsis */
  max-width: none; /* Remove fixed max-width */
  margin-right: 12px; /* Add breathing room */
}

/* Add media query for mobile */
@media (max-width: 768px) {
  .title-left a {
    max-width: fit-content; /* Adjusts width to content */
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

/* Adjust metric alignment */
.row3 {
  gap: 8px; /* Reduce spacing */
  justify-content: flex-start; /* Align to left */
}

.metric-right {
  margin-left: 0; /* Remove auto margin */
  flex: 0 1 auto; /* Allow natural width */
  display: flex;
  gap: 4px;
}

.value.currency {
  min-width: 80px; /* Ensure space for amounts */
  text-align: right;
  font-feature-settings: "tnum"; /* Use tabular numbers */
}

/* Fix for short titles not being truncated */
.report-card .title-left a {
  display: inline-block !important;
  width: auto !important;
  max-width: 100% !important;
  white-space: normal !important;
  overflow: visible !important;
  text-overflow: clip !important;
}

/* Only apply ellipsis truncation when title is very long */
@media (max-width: 767px) {
  .report-card .title-left a.long-title {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
}

/* Delete Modal for Reports */
/* Add these styles to your CSS file */

.eb-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.eb-modal-content {
  background-color: white;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.eb-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  border-bottom: 1px solid #eee;
}

.eb-modal-header h3 {
  margin: 0;
  color: #0033a0;
}

.eb-modal-close {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
}

.eb-modal-body {
  padding: 20px;
}

.eb-modal-info {
  background-color: #f8f9fa;
  border-left: 4px solid #0033a0;
  padding: 12px;
  margin-top: 10px;
}

.eb-modal-info p {
  margin: 0;
  color: #555;
  font-size: 14px;
}

.eb-modal-info i {
  color: #0033a0;
  margin-right: 8px;
}

.eb-modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 16px 20px;
  border-top: 1px solid #eee;
  gap: 12px;
}

.eb-modal-cancel {
  background-color: #f0f0f0;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.eb-modal-delete {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.eb-modal-delete-revert {
  background-color: #0033a0;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

/* Add these styles to your ProcessInfo.css file */

/* Main settings container */
.settings-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.03);
}

.settings-title {
  color: #0033a0;
  font-size: 1.75rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  padding-bottom: 0.75rem;
  border-bottom: 2px solid #f0f4ff;
}

/* Settings cards grid layout */
.settings-cards-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2.5rem;
}

/* Individual settings card */
.settings-card {
  background-color: #f8fafc;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  border: 1px solid #e2e8f0;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.settings-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.08);
}

/* Card header with icon and title */
.settings-card-header {
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
  gap: 0.75rem;
}

.settings-card-icon {
  color: #0033a0;
  font-size: 1.5rem;
  background-color: #e0f2fe;
  padding: 0.75rem;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
}

.settings-card-title {
  color: #1e293b;
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0;
}

/* Card description and link */
.settings-card-description {
  color: #64748b;
  font-size: 0.95rem;
  line-height: 1.5;
  margin: 0 0 1.25rem 0;
  flex-grow: 1;
}

.settings-card-link {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background-color: #0033a0;
  color: white;
  text-decoration: none;
  padding: 0.75rem 1rem;
  border-radius: 8px;
  font-weight: 500;
  transition: background-color 0.2s ease;
  margin-top: auto;
  text-align: center;
}

.settings-card-link:hover {
  background-color: #002580;
}

.settings-link-icon {
  font-size: 0.85rem;
}

/* Section headers */
.settings-section {
  background-color: #ffffff;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  border: 1px solid #e2e8f0;
  margin-bottom: 2rem;
}

.settings-section-title {
  color: #0f172a;
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0 0 1.25rem 0;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid #f1f5f9;
}

/* Option items grid */
.settings-options-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
  gap: 1.5rem;
}

.settings-option-item {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas:
    "label control"
    "description description";
  gap: 0.5rem 1.5rem;
  align-items: center;
}

.settings-option-label {
  grid-area: label;
  color: #334155;
  font-weight: 500;
  font-size: 1rem;
}

.settings-option-control {
  grid-area: control;
}

.settings-option-description {
  grid-area: description;
  color: #64748b;
  font-size: 0.9rem;
  margin: 0.25rem 0 0 0;
}

/* Danger zone styling */
.settings-section-danger {
  background-color: #fffbeb;
  border-color: #fde68a;
}

.settings-danger-grid {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.settings-danger-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}

.settings-danger-content {
  flex: 1;
}

.settings-danger-label {
  color: #b91c1c;
  font-weight: 500;
  font-size: 1rem;
  display: block;
  margin-bottom: 0.5rem;
}

.settings-danger-description {
  color: #64748b;
  font-size: 0.9rem;
  margin: 0;
}

.settings-danger-control {
  flex-shrink: 0;
}

.settings-danger-button {
  background-color: #dc2626;
  color: white;
  border: none;
  padding: 0.75rem 1.25rem;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 140px;
}

.settings-danger-button:hover:not(:disabled) {
  background-color: #b91c1c;
}

.settings-danger-button:disabled {
  background-color: #f87171;
  cursor: not-allowed;
}

/* Error message */
.settings-error-message {
  background-color: #fee2e2;
  border-left: 4px solid #dc2626;
  padding: 0.75rem 1rem;
  color: #b91c1c;
  font-size: 0.9rem;
  border-radius: 4px;
  margin-top: 0.5rem;
}

.settings-error-link {
  color: #0033a0;
  font-weight: 500;
  text-decoration: underline;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .settings-container {
    padding: 1.5rem;
  }

  .settings-cards-grid {
    grid-template-columns: 1fr;
  }

  .settings-options-grid {
    grid-template-columns: 1fr;
  }

  .settings-danger-item {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .settings-danger-control {
    width: 100%;
  }

  .settings-danger-button {
    width: 100%;
  }
}
/* Modal styles */
/* Enhance modal styles */
/* Enhanced modal styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  overscroll-behavior: contain; /* Prevent scroll chaining */
  touch-action: none; /* Disable touch actions */
}

.modal-content {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  max-width: 90%;
  width: 1000px;
  max-height: 90vh;
  overflow-y: auto;
  overscroll-behavior: contain; /* Prevent scroll chaining within the modal */
  position: relative; /* Ensure positioning context */
  margin: auto; /* Center the modal */
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid #eee;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #666;
}

.document-management-section {
  padding: 1rem;
}

@keyframes highlight-pulse {
  0% {
    background-color: rgba(255, 215, 0, 0.3);
    box-shadow: 0 0 5px rgba(255, 215, 0, 0.5);
  }
  50% {
    background-color: rgba(255, 215, 0, 0.5);
    box-shadow: 0 0 15px rgba(255, 215, 0, 0.7);
  }
  100% {
    background-color: rgba(255, 215, 0, 0.3);
    box-shadow: 0 0 5px rgba(255, 215, 0, 0.5);
  }
}

.highlighted-report {
  animation: highlight-pulse 1.5s ease-in-out 2;
  border-left: 4px solid #ffd700 !important;
  transition: all 0.3s ease-in-out;
}

/* Mobile screen Report Layout */
/* Apply this layout to all mobile screens, not just the smallest ones */
@media (max-width: 767px) {
  /* Force the report header into a vertical layout with proper spacing */
  .report-card .report-header {
    display: flex !important;
    flex-direction: column !important;
    width: 100% !important;
    position: relative !important; /* Create a stacking context */
  }

  /* Make date its own block element with full width */
  .report-card .date-block {
    display: block !important;
    width: 100% !important;
    padding-bottom: 6px !important;
    margin-bottom: 8px !important;
    font-size: 0.85rem !important;
    color: #94a3b8 !important;
    border-bottom: 1px dashed #e2e8f0 !important;
  }

  /* Fix report-meta container to be full width */
  .report-card .report-meta {
    width: 100% !important;
    margin-bottom: 10px !important;
  }

  /* Ensure controls don't overlap by giving them their own line */
  .report-card .controls-right {
    width: 100% !important;
    position: static !important; /* Remove any absolute positioning */
    display: flex !important;
    justify-content: flex-end !important;
    margin-top: 8px !important;
    margin-bottom: 4px !important;
  }

  /* Make sure title text doesn't overflow */
  .report-card .title-left a,
  .report-card .mobile-title a {
    max-width: 100% !important;
    overflow: visible !important;
    white-space: normal !important;
  }
}

/* End of mobile screen report layout */

/* Fix for second report card */
@media (max-width: 767px) {
  /* Target the specific rule that's causing the issue */
  .reports-content > div > div + .report-card .controls-right,
  .reports-content > div > div + .report-card + .report-card .controls-right {
    position: static !important;
    top: auto !important;
    right: auto !important;
    opacity: 1 !important;
    visibility: visible !important;
    display: flex !important;
    justify-content: flex-end !important;
    width: 100% !important;
    margin-top: 8px !important;
    margin-bottom: 4px !important;
    z-index: 2 !important;
  }
}

/* Download As Component */

/* Download dropdown menu styling */
.download-dropdown {
  position: relative;
  display: inline-block;
}

.download-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 8px 0;
  z-index: 100;
  min-width: 120px;
}

.download-menu button {
  display: block;
  width: 100%;
  text-align: left;
  background: none;
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
}

.download-menu button:hover {
  background-color: #f0f4ff;
  color: #0033a0;
}

@media (max-width: 767px) {
  .download-menu {
    right: 0;
  }
}

/* End of Download As Component */

/* Download component Error messages */
/* Download dropdown menu styling */
.download-dropdown {
  position: relative;
  display: inline-block;
}

.download-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 8px 0;
  z-index: 100;
  min-width: 120px;
}

.download-menu button {
  display: block;
  width: 100%;
  text-align: left;
  background: none;
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
}

.download-menu button:hover {
  background-color: #f0f4ff;
  color: #0033a0;
}

/* Error notification */
.error-notification {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #f44336;
  color: white;
  padding: 12px 20px;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  animation: fadeIn 0.3s, fadeOut 0.3s 2.7s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(20px);
  }
}

@media (max-width: 767px) {
  .download-menu {
    right: 0;
  }
}

/* End of error message for download component */

.compliance-check-wrapper {
  display: block;
  width: 100%;
  clear: both;
  margin-top: 20px; /* Adjust spacing as needed */
}

/* Thin line on desktop only for compliance button section */

@media (min-width: 768px) {
  .compliance-check-wrapper {
    border-top: 1px solid #e2e8f0; /* a thin light-gray line */
    padding-top: 16px; /* space between line and button */
    margin-top: 16px; /* space above the line */
  }
}

/* end of thin line fix */

/* accountant view fix for compliance comopnent separate line */
/* Compliance section styling */
.compliance-separator {
  width: 100%;
  height: 1px;
  background-color: #e0e0e0;
  margin: 10px 0;
}

.compliance-container {
  width: 100%;
  margin-top: 10px;
}

/* Responsive classes */
@media (max-width: 768px) {
  .desktop-only {
    display: none;
  }
}

@media (min-width: 769px) {
  .mobile-only {
    display: none;
  }
}

/* Compliance section styling */
.compliance-separator {
  width: 100%;
  height: 1px;
  background-color: #e0e0e0;
  margin: 10px 0;
}

.compliance-wrapper {
  position: relative;
}

/* Responsive styles */
@media (max-width: 768px) {
  .desktop-only {
    display: none;
  }

  /* For mobile, the compliance check stays in the normal flow */
  .compliance-wrapper {
    /* Normal integration container styles apply */
  }
}

@media (min-width: 769px) {
  .mobile-only {
    display: none;
  }

  /* For desktop, make the compliance check take the full row width */
  .compliance-wrapper {
    width: 100%;
    margin-top: 10px;
    /* Make it clearly a separate section */
    padding-top: 5px;
  }
}

/* end of accountant view separate compliancecheck */

/* Notification badge on notes */

.action-button {
  position: relative; /* Ensure relative positioning for absolute children */
}

.note-indicator {
  position: absolute;
  top: -3px;
  right: -3px;
  width: 8px;
  height: 8px;
  background-color: #f83e3e; /* Red notification dot */
  border-radius: 50%;
  border: 1px solid white;
}

/* end notification badge on notes */

/* For identifyer of invoices or expenses */

/* Report Type Badge */
.report-type-badge {
  display: inline-flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  margin-left: 10px;
  white-space: nowrap;
}

.report-type-badge i {
  margin-right: 4px;
  font-size: 11px;
}

.report-type-badge.receipt_report {
  background-color: #e6f7ee;
  color: #28a745;
  border: 1px solid #28a745;
}

.report-type-badge.invoice_report {
  background-color: #e6f0ff;
  color: #0033a0;
  border: 1px solid #0033a0;
}

/* Make it responsive */
@media (max-width: 600px) {
  .report-type-badge {
    margin-left: 0;
    margin-top: 5px;
    font-size: 11px;
    padding: 2px 6px;
  }
}

/* end invoices or expenses tag */
