/* General FAQ page container styling */
.faq-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.back-button {
  background: none;
  border: none;
  color: #007bff;
  font-size: 18px;
  cursor: pointer;
  margin-bottom: 10px;
}

.back-button:hover {
  text-decoration: underline;
}

.logo-container {
  flex-shrink: 0;
}

.faq-logo {
  width: 150px;
  height: auto;
  cursor: pointer;
}

/* FAQ page title styling */
.faq-title {
  font-size: 2rem;
  color: #0033a0;
  margin-bottom: 20px;
  text-align: center;
}

.faq-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff8e1;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  font-family: "Poppins", sans-serif;
}

/* Individual FAQ item styling */
.faq-item {
  margin-bottom: 20px;
}

/* Question and answer styling */
.faq-question {
  font-size: 1.2rem;
  color: #0033a0;
  margin-top: 15px;
  margin-bottom: 8px;
  font-weight: bold;
}

.faq-answer {
  font-size: 1rem;
  color: #333;
  margin-bottom: 20px;
  line-height: 1.6;
}

/* Navigation menu */
.faq-nav {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 20px 0;
  padding: 15px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.nav-item {
  padding: 8px 16px;
  background-color: #f0f7ff;
  border-radius: 20px;
  color: #0033a0;
  cursor: pointer;
  font-size: 0.9rem;
  transition: all 0.3s ease;
}

.nav-item:hover {
  background-color: #0033a0;
  color: white;
}

/* Section headers */
.section-header {
  font-size: 1.5rem;
  color: #0033a0;
  margin: 30px 0 20px;
  padding-bottom: 10px;
  border-bottom: 2px solid #ffd700;
}

/* Subsection styling */
.subsection {
  margin: 20px 0;
  padding: 15px;
  background-color: #fff;
  border-radius: 8px;
  border-left: 4px solid #0033a0;
}

.subsection-title {
  font-size: 1.1rem;
  color: #0033a0;
  margin-bottom: 15px;
  font-weight: 600;
}

/* Step by step instructions */
.step-list {
  list-style-type: none;
  padding: 0;
  margin: 15px 0;
}

.step-item {
  display: flex;
  margin-bottom: 12px;
  align-items: flex-start;
}

.step-number {
  min-width: 24px;
  height: 24px;
  background-color: #0033a0;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  font-size: 0.9rem;
}

.step-content {
  flex: 1;
}

/* Important notes */
.important-note {
  background-color: #fff3cd;
  border-left: 4px solid #ffd700;
  padding: 12px;
  margin: 15px 0;
  border-radius: 4px;
}

/* Tips section specific styles */
.tips-section {
  margin-top: 30px;
}

.tips-card {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-left: 4px solid #0033a0;
}

.tips-card-title {
  font-size: 1.2rem;
  color: #0033a0;
  margin-bottom: 15px;
  font-weight: 600;
}

.tips-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.tips-item {
  display: flex;
  align-items: flex-start;
  padding: 12px 0;
  border-bottom: 1px solid #e2e8f0;
}

.tips-item:last-child {
  border-bottom: none;
}

.tips-icon {
  color: #0033a0;
  margin-right: 12px;
  min-width: 20px;
  font-size: 1rem;
}

.tips-content {
  flex: 1;
  color: #333;
  line-height: 1.5;
}

.menu-path {
  display: block;
  font-size: 0.9rem;
  color: #666;
  margin-top: 4px;
  font-style: italic;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .faq-nav {
    flex-direction: column;
    align-items: stretch;
  }

  .nav-item {
    text-align: center;
  }

  .tips-card {
    margin: 10px 0;
  }
}

/* Styled paragraph for better readability */
.styled-paragraph {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #333;
  margin-bottom: 20px;
}

/* Styled list for key points yes */
.styled-list {
  list-style-type: disc;
  margin-left: 20px;
  padding-left: 20px;
  color: #333;
  font-size: 1rem;
  line-height: 1.6;
}

.styled-list li {
  margin-bottom: 10px;
}

/* CTA button styling */
.cta-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #0033a0;
  color: white;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #0056d6;
}
