/* Modal Base Styles */
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.modal-content {
  background: white;
  padding: 2rem;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-height: 90vh;
  overflow-y: auto;
}

/* Close Button */
.close-x {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #666;
  padding: 0.5rem;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.close-x:hover {
  color: #333;
}

/* Picker Container and Layout */
.picker-container {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.picker-content {
  width: 100%;
  max-width: 450px; /* Increased from 400px to accommodate wider button */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.web-picker {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

/* Title and Text Styles */
.picker-title {
  font-size: 1.5rem;
  color: #1a202c;
  margin: 0;
  text-align: center;
  font-weight: 600;
}

.picker-subtitle {
  color: #4a5568;
  margin: 0 0 1rem 0;
  text-align: center;
}

.photo-limit-info {
  font-size: 0.875rem;
  color: #718096;
  margin: 0;
  text-align: center;
}

/* Mode Selector */
.mode-selector {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  background: #f7fafc;
  padding: 0.5rem;
  border-radius: 8px;
}

.mode-option {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem 2rem;
  cursor: pointer;
  border-radius: 6px;
  transition: all 0.2s ease;
}

.mode-option.active {
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.mode-icon {
  font-size: 1.5rem;
  color: #4a5568;
}

.mode-option.active .mode-icon {
  color: #4285f4;
}

.mode-label {
  font-weight: 500;
  color: #4a5568;
}

/* Google Button Styles */
.google-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  padding: 12px 24px;
  background-color: #ffffff;
  color: #4285f4;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  margin: 1rem 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  white-space: nowrap;
}

.google-button:hover {
  background-color: #e8f0fe;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.button-icon {
  display: inline-block;
  margin-right: 10px;
  width: 24px;
  height: 24px;
  background-size: contain;
  background-repeat: no-repeat;
}

.google-icon {
  background-image: url("./google-icon.svg");
}

/* QR Code Styles */
.qr-code-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
}

/* Status Styles */
.status-container {
  text-align: center;
  padding: 1rem;
}

.status-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 8px;
}
/* Shared Styles for All Statuses */
.processing-status,
.waiting-status,
.error-status {
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem; /* Optional: Consistent font size */
}

/* Specific Styles for Processing Status */
.processing-status {
  background: white; /* White background for processing */
  color: #0033a0; /* Blue text */
}

/* Specific Styles for Waiting Status */
.waiting-status {
  background: #f8f9fa; /* Light gray background for waiting */
  color: #666; /* Neutral text color */
}

/* Specific Styles for Error Status */
.error-status {
  background: #fff8f8; /* Light red background for errors */
  border-left: 4px solid #dc3545; /* Bold red border for emphasis */
  color: #dc3545; /* Red text for errors */
}

/* Specific Styles for Status Icons */
.processing-status i {
  color: #0033a0; /* Blue icon for processing */
}

.waiting-status i {
  color: #0033a0; /* Blue icon for waiting */
}

.error-status i {
  color: #dc3545; /* Red icon for errors */
}

.error-status {
  background: #fff8f8;
  border-left: 4px solid #dc3545;
  text-align: left;
}

/* Refresh Button */
.refresh-button {
  margin-top: 12px;
  padding: 8px 16px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s ease;
}

.refresh-button:hover {
  background-color: #e0e0e0;
}

.refresh-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.refresh-timer {
  margin-top: 8px;
  color: #666;
  font-size: 0.9em;
}

/* Loading Indicator */
.loading-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: #0033a0;
}

.loading-indicator i {
  font-size: 1.25rem;
}

/* Retry Button */
.retry-button {
  background-color: #007bff;
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}

.retry-button:hover {
  background-color: #0056b3;
}

/* Cancel Button */
.cancel-button {
  color: #0033a0;
  background: none;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  padding: 0.5rem 1rem;
  margin-top: 1rem;
}

.cancel-button:hover {
  color: #002280;
}

/* Mobile Responsiveness */
@media (max-width: 600px) {
  .modal-content {
    width: calc(100% - 2rem);
    padding: 1.5rem;
    margin: 1rem;
  }

  .mode-selector {
    flex-direction: column;
  }

  .mode-option {
    padding: 0.75rem;
  }

  .qr-code-wrapper svg {
    width: 150px !important;
    height: 150px !important;
  }
  .google-button {
    max-width: 100%; /* Use full width on mobile */
    padding: 12px 20px;
    font-size: 15px; /* Slightly smaller font on mobile if needed */
  }

  .picker-content {
    max-width: 100%; /* Use full width on mobile */
    padding: 0 1rem; /* Add some padding on the sides */
  }
}

/* Very Small Screens */
@media screen and (max-height: 667px) {
  .modal-content {
    margin: 0.5rem;
    padding: 1.5rem 1rem;
  }

  .photo-limit-info {
    margin: 0.5rem 0;
    padding: 0.25rem;
  }
}

/* Mobile Responsiveness Enhancements */
@media (max-width: 480px) {
  .modal-content {
    width: calc(100% - 1.5rem);
    padding: 1rem;
    margin: 1rem;
  }

  .picker-title {
    font-size: 1.25rem; /* Slightly smaller for mobile */
  }

  .google-button,
  .retry-button {
    padding: 12px;
    font-size: 14px;
  }

  .mode-option {
    padding: 0.5rem;
    font-size: 0.875rem; /* Smaller font to fit content better */
  }

  .status-container {
    padding: 1rem;
  }

  .picker-content {
    padding: 0.5rem 1rem;
  }
}

@media (max-height: 667px) {
  .modal-content {
    padding: 1rem;
    margin: 0.5rem;
    max-height: 80vh;
  }

  .loading-indicator {
    gap: 0.25rem;
  }

  .status-message {
    gap: 6px;
  }
}
.mobile-instructions {
  margin-top: 16px;
  padding: 16px;
  background-color: #f8f9fa;
  border-left: 4px solid #0033a0;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.6;
  color: #495057;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.mobile-instructions strong {
  color: #0033a0;
  font-weight: 600;
}

/* Add icon and style for the tip */
.mobile-instructions .tip {
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px solid #e9ecef;
  color: #666;
  font-style: italic;
  display: flex;
  align-items: center;
  gap: 8px;
}

.mobile-instructions .tip i {
  color: #0033a0;
}

.photo-selector .processing-status {
  background: white; /* White background */
  color: #0033a0; /* Blue text */
  border: 1px solid #0033a0; /* Optional: Add a border */
  font-size: 1rem; /* Adjust font size if needed */
  text-align: center;
}

.photo-selector .processing-status i {
  color: #0033a0; /* Ensure the icon matches the text color */
}
